import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from 'semantic-ui-react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import CheckBox from '../../../../../../common/components/FormInputFields/CheckboxWrapper/CheckboxWrapper';
import MentionTextArea from '../../../../../../common/components/MentionableTextArea/MentionTextArea';
import * as constants from '../../../../activity.constants';
import GenericAction from '../../../../../GenericAction/views/genericAction';
import PushNotificationGallery
  from '../../../../../Images/views/PushNotificationGallery/PushNotificationGallery.container';
import * as imageSelectors from '../../../../../Images/images.selectors';
import injectSaga from '../../../../../../utils/injectSaga';
import * as reducerConstants from '../../../../../../constants/reducer.constants';
import imagesSaga from '../../../../../Images/images.saga';
import * as imagesConstants from '../../../../../Images/images.constants';
import * as actions from '../../../../activity.actions';
import { getImagesBySize } from '../../../../../Images/images.actions';
import * as appConstants from '../../../../../App/app.constants';
import withAuthorization from '../../../../../../common/helpers/authorization';
import DisabledItem from '../../../../../../common/components/DisabledItem/DisabledItem';
import { getBusinessDisplayName, getLocationId } from '../../../../../App/selectors';
import TestToMyself from '../testToMyself/TestToMyself.container';
import * as schemaConstants from '../../../../activitySchema/activitySchema.constants';
import AiPopover from '../../../../../Ai/AiPopover';
import ExternalAppData from './ExternalAppData';

class SendPush extends React.PureComponent {
  constructor() {
    super();

    this.state = {
      imageId: null,
    };

    this.onImageSelected = this.onImageSelected.bind(this);
    this.getImageById = this.getImageById.bind(this);
    this.renderPushImage = this.renderPushImage.bind(this);
  }

  static propTypes = {
    actions: PropTypes.object,
    action: PropTypes.object,
    assetContext: PropTypes.string,
    automationIdPrefix: PropTypes.string,
    index: PropTypes.number,
    intl: PropTypes.object.isRequired,
    mentionsList: PropTypes.object,
    onActionFieldUpdate: PropTypes.func.isRequired,
    validationErrors: PropTypes.object,
    images: PropTypes.object,
    defaultPreviewImage: PropTypes.object,
    getPermissionLevel: PropTypes.func.isRequired,
    locationId: PropTypes.number.isRequired,
    locationDisplayName: PropTypes.string.isRequired,
    actionsWrapperIndex: PropTypes.number.isRequired,
    submitActionToCustomer: PropTypes.func,
    getBusinessBundlePlan: PropTypes.func.isRequired,
    isWeb: PropTypes.bool.isRequired,
  };

  componentWillMount() {
    if (!this.props.images) {
      this.props.actions.getImagesBySize(imagesConstants.IMAGE_SIZE_THUMBNAIL);
    }
  }

  onImageSelected(selectedId, index) {
    if (this.props.images) {
      const selectedImage = this.getImageById(selectedId);
      const imageUrl = selectedImage.get(imagesConstants.URL);
      this.props.onActionFieldUpdate(constants.PUSH_NOTIFICATION_IMAGE, selectedId, index);
      this.props.onActionFieldUpdate(constants.PUSH_NOTIFICATION_IMAGE_URL, imageUrl, index);
    }
  }

  getImageById(selectedId) {
    return this.props.images.find((image) => image.get(imagesConstants.ID) === selectedId);
  }

  renderPushImage() {
    const requestToEnableEmailSubject = `Push Notification With Image - Enable For ${this.props.locationDisplayName} - ${this.props.locationId}`;
    const requestToEnableEmailBody = `Hello, please enable the Push Notification Image feature for ${this.props.locationDisplayName} - ${this.props.locationId}`;
    const requestToEnableHref = `mailto:ComoSupport@comosense.com?cc=ofir.shapira@comosense.com&subject=${requestToEnableEmailSubject}&body=${requestToEnableEmailBody}`;
    const { onActionFieldUpdate, defaultPreviewImage, action, index, getPermissionLevel, images, intl } = this.props;
    const { formatMessage } = intl;
    const hasFeature = getPermissionLevel(appConstants.FEATURES_ADVANCED_PUSH) !== constants.FEATURE_LEVEL.NONE;
    const pushImage = action && action.get(constants.PUSH_NOTIFICATION_IMAGE) && images ? this.getImageById(action.get(constants.PUSH_NOTIFICATION_IMAGE)).get('url') : null;
    const bundlePlan = this.props.getBusinessBundlePlan();

    return (
      <React.Fragment>
        {!hasFeature ? (
          <React.Fragment>
            <DisabledItem
              tooltipMessage={bundlePlan === appConstants.REVEL ? formatMessage({ id: 'revel.activity.triggers.add-on.is.not.available' }) : formatMessage({ id: 'activity.triggers.add-on.is.not.available' })}
              automationId="image-push-notification"
              icon="como-ic-add-on"
            >
              <p className="action-title">
                {formatMessage({ id: 'activity.push.action.icon.config.label' })}
              </p>
            </DisabledItem>
            <p className={'requestToEnable'}>
              <a
                onClick={this.requestFeatureActivation}
                href={requestToEnableHref}
              >{formatMessage({ id: 'activity.pushNotification.image.requestToEnable' })}</a>
            </p>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <p className="action-title">
              {formatMessage({ id: 'activity.push.action.icon.config.label' })}
            </p>
            <CheckBox
              disabled={!hasFeature}
              name={constants.SEND_PUSH_WITH_IMAGE}
              checked={action.get(constants.SEND_PUSH_WITH_IMAGE)}
              data-automation-id={`action.${index}.sendPushWithImage`}
              onChange={(e, data) => onActionFieldUpdate(constants.SEND_PUSH_WITH_IMAGE, data.checked, index)}
              label={formatMessage({ id: 'activity.push.action.include.image' })}
            />
          </React.Fragment>
        )}
        {action.get(constants.SEND_PUSH_WITH_IMAGE) && (
          <PushNotificationGallery
            selectedPreviewImage={defaultPreviewImage}
            onImageSelected={(id) => this.onImageSelected(id, index)}
            imageUrl={pushImage}
          />
        )}
      </React.Fragment>
    );
  }

  render() {
    const {
      action,
      assetContext,
      automationIdPrefix,
      index,
      mentionsList,
      onActionFieldUpdate,
      validationErrors,
      getPermissionLevel,
      isWeb
    } = this.props;

    const { formatMessage } = this.props.intl;

    return (
      <Grid className="activity-communication-action">
        <Grid.Row>
          <Grid.Column width={16}>
            {mentionsList ? (
              <div>
                <p className="action-title">
                  {formatMessage({ id: 'activity.generic.action.messageTitle' })}
                </p>
                <MentionTextArea
                  singleLine
                  automationIdPrefix={`${automationIdPrefix}.${constants.PUSH_TITLE}`}
                  mentionOptions={mentionsList}
                  message={action.get(constants.PUSH_TITLE)}
                  onTextUpdate={(newMessage) => onActionFieldUpdate(constants.PUSH_TITLE, newMessage, index)}
                  index={index}
                  placeholder={formatMessage({ id: 'activity.push.message.title.placeholder' })}
                  error={validationErrors ? validationErrors.get(constants.PUSH_TITLE) : null}
                />
                <p className="action-title">
                  {formatMessage({ id: 'activity.generic.action.messageContent' })}
                </p>
                <AiPopover
                  showLengthPrompt
                  getPermissionLevel={getPermissionLevel}
                  onTextUpdate={(newMessage) => onActionFieldUpdate(constants.PUSH_MESSAGE, newMessage, index)}
                  prePromptKey={'vertex.ai.prePrompt.notification'}
                  mainButtonTextKey={'vertex.ai.main-btn-txt'}
                  titleToolTipKey={'vertex.ai.btn-tooltip'}
                  justifyContent={'tw-justify-end'}
                  charLength={40}
                />
                <MentionTextArea
                  automationIdPrefix={`${automationIdPrefix}.${constants.PUSH_MESSAGE}`}
                  mentionOptions={mentionsList}
                  message={action.get(constants.PUSH_MESSAGE)}
                  onTextUpdate={(newMessage) => onActionFieldUpdate(constants.PUSH_MESSAGE, newMessage, index)}
                  index={index}
                  placeholder={formatMessage({ id: 'activity.push.message.body.placeholder' })}
                  error={validationErrors ? validationErrors.get(constants.PUSH_MESSAGE) : null}
                />
              </div>
              ) : null
            }
          </Grid.Column>
        </Grid.Row>
        {!isWeb && <Grid.Row>
          <Grid.Column width={16}>
            <p className="action-title">
              {formatMessage({ id: 'activity.push.action.button.config.label' })}
            </p>
            <GenericAction
              actionData={action.get(constants.GENERIC_ACTION_ARGS)}
              assetContext={assetContext}
              automationPrefix={`${automationIdPrefix}.${constants.GENERIC_ACTION_ARGS}`}
              doNothingLabelStringId="activity.generic.action.button.actions.open.the.app"
              index={index}
              onUpdate={(newValue) => onActionFieldUpdate(constants.GENERIC_ACTION_ARGS, newValue, index)}
              validationErrors={validationErrors ? validationErrors.get(constants.GENERIC_ACTION_ARGS) : null}
            >
            </GenericAction>
          </Grid.Column>
        </Grid.Row> }
        {!isWeb && <ExternalAppData {...this.props} /> }
        <Grid.Row>
          <Grid.Column width={16}>
            <p className="action-title">
              {formatMessage({ id: 'activity.generic.action.generalSettings' })}
            </p>
            <Grid>
              <Grid.Row>
                <Grid.Column width={10}>
                  <CheckBox
                    name={constants.SAVE_MESSAGE_TO_INBOX}
                    checked={action.get(constants.SAVE_MESSAGE_TO_INBOX)}
                    data-automation-id={`action.${index}.saveMessageToInbox`}
                    onChange={(e, data) => onActionFieldUpdate(constants.SAVE_MESSAGE_TO_INBOX, data.checked, index)}
                    label={formatMessage({ id: 'activity.save.message.to.inbox.checkbox' })}
                  />
                </Grid.Column>
                <Grid.Column width={10}>
                  {this.renderPushImage()}
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={16}>
            <TestToMyself
              actionType={isWeb ? schemaConstants.SEND_PERSONAL_WEB_PUSH_ACTION : schemaConstants.SEND_PERSONAL_PUSH_ACTION}
              actionIndex={this.props.index}
              actionsWrapperIndex={this.props.actionsWrapperIndex}
              submitActionToCustomer={this.props.submitActionToCustomer}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }

  requestFeatureActivation = () => {
    this.props.actions.requestToEnablePushNotificationWithImageFeature({
      locationId: this.props.locationId,
      locationDisplayName: this.props.locationDisplayName,
    });
  };
}

function mapDispatchToProps(dispatch) {
  const creators = {
    ...actions,
    getImagesBySize
  };
  return { actions: bindActionCreators(creators, dispatch) };
}

const mapStateToProps = (state, props) => ({
  images: imageSelectors.getAllActiveImageThumbnails(state),
  defaultPreviewImage: imageSelectors.getDefaultImageThumbnail(state),
  locationId: getLocationId(state),
  locationDisplayName: getBusinessDisplayName(state),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);
const withSaga = injectSaga({ key: reducerConstants.IMAGES_BRANCH, saga: imagesSaga });

export default withAuthorization(compose(withConnect, withSaga)(injectIntl(SendPush)));
