import {
  cloneDeep
} from 'lodash';
import * as constants from './activitySchema.constants';
import * as mentions from './mentions';
import * as appConstants from '../../App/app.constants';
import * as activityConstants from '../activity.constants';
import * as schemaConditions from './activitySchema.conditions';
import * as schemaTriggers from './activitySchema.triggers';

const appTriggers = [
  schemaTriggers.openedApp,
  schemaTriggers.joinedApp,
  schemaTriggers.formFilled,
  schemaTriggers.claim,
  schemaTriggers.oneTimeCode,
  schemaTriggers.shareBudget
];

const benefitsTriggers = [
  schemaTriggers.receivedAsset,
  schemaTriggers.redeem,
  schemaTriggers.purchasedAsset,
  schemaTriggers.punch,
  schemaTriggers.exceededPunch,
  schemaTriggers.receivedPoints,
  schemaTriggers.usedPoints,
  schemaTriggers.receivedCredit,
  schemaTriggers.usedCredit,
  schemaTriggers.userClaimSharedBudget,
  schemaTriggers.notifyUserSharedBudgetDone
];

const filterBenefitsTriggers = [
  schemaTriggers.receivedAssetTriggerUserAction,
  schemaTriggers.redeemTriggerUserAction,
  schemaTriggers.didntRedeem,
  schemaTriggers.didntReceiveAsset,
  schemaTriggers.punchFilter,
  schemaTriggers.receivedPoints,
  schemaTriggers.usedPoints,
  schemaTriggers.receivedCredit,
  schemaTriggers.usedCredit,
];

const membershipTriggers = [
  schemaTriggers.joinedClub,
  schemaTriggers.updateMembership,
  schemaTriggers.birthday,
  schemaTriggers.anniversary,
  schemaTriggers.clubMemberAnniversary,
  schemaTriggers.tagOperation,
  schemaTriggers.untagOperation,
  schemaTriggers.consentedToTermsAndCodnitions,
  schemaTriggers.askToBeForgotten,
];

const connectivityTriggers = [
  schemaTriggers.beaconSignal,
  schemaTriggers.externalEvent,
  schemaTriggers.smsUnsubscribe,
  schemaTriggers.emailUnsubscribe,
];

const filterCommunicationTriggers = [
  schemaTriggers.pushSent,
  schemaTriggers.emailReceivedSuccessfully,
  schemaTriggers.emailWasNotReceived,
  schemaTriggers.emailWasNotOpened,
  schemaTriggers.failedToSendSms,
];

const purchasesTriggers = [
  schemaTriggers.purchaseAnalyzed,
  schemaTriggers.topUp,
  schemaTriggers.paysWithPointsAtPos,
  schemaTriggers.paysWithCreditAtPos,
  schemaTriggers.paysWithApp,
  schemaTriggers.paysWithAppCreditCard,
  schemaTriggers.paysWithPayPal,
  schemaTriggers.appCreditCardPaymentIsDeclined,
  schemaTriggers.payPalPaymentIsDeclined,
  schemaTriggers.startPurchase,
];

const friendReferralTriggers = [
  schemaTriggers.joinedClubWithReferralCode,
  schemaTriggers.referredFriendMadeHisFirstPurchase,
  schemaTriggers.usedMyReferralCode,
];

const triggers = [
  {
    key: constants.PURCHASES_GROUP,
    values: purchasesTriggers
  },
  {
    key: constants.BENEFITS_GROUP,
    values: benefitsTriggers
  },
  {
    key: constants.FRIEND_REFERRAL,
    values: friendReferralTriggers
  },
  {
    key: constants.APP_GROUP,
    values: appTriggers
  },
  {
    key: constants.MEMBERSHIP_GROUP,
    values: membershipTriggers
  },
  {
    key: constants.CONNECTIVITY_GROUP,
    values: connectivityTriggers
  },
];

const filterUserActions = [
  {
    key: constants.PURCHASES_GROUP,
    values: [schemaTriggers.purchaseAnalyzedUserAction, schemaTriggers.didntPurchase]
  },
  {
    key: constants.BENEFITS_GROUP,
    values: filterBenefitsTriggers
  },
  {
    key: constants.APP_GROUP,
    values: [schemaTriggers.openedApp, schemaTriggers.didNotOpenApp, schemaTriggers.paidWithApp]
  },
  {
    key: constants.MEMBERSHIP_GROUP,
    values: [schemaTriggers.joinedClubUserAction, schemaTriggers.gaveConsentAction, schemaTriggers.referredFriendMadePurchase]
  },
  {
    key: constants.COMMUNICATION_GROUP,
    values: filterCommunicationTriggers
  },
];

const benefitsActions = {
  key: constants.BENEFITS_GROUP,
  actionFields: [
    {
      key: constants.ADD_POINTS_ACTION,
      basic: true
    },
    {
      key: constants.REDEEM_POINTS_ACTION,
      features: [appConstants.FEATURE_ESSENTIALS_OFF],
      basic: true
    },
    {
      key: constants.ASSIGN_ASSET_ACTION,
      features: [appConstants.FEATURE_ESSENTIALS_OFF]
    },
    {
      key: constants.REMOVE_ASSET_ACTION
    },
    {
      key: constants.AUTO_PUNCH_ACTION
    }
  ]
};
const benefitsActionsOnExternalEvent = {
  key: constants.BENEFITS_GROUP,
  actionFields: [
    {
      key: constants.ADD_DYNAMIC_POINTS_ACTION
    },
    {
      key: constants.DEDUCT_DYNAMIC_POINTS_ACTION
    },
    {
      key: constants.ASSIGN_ASSET_ACTION,
      features: [appConstants.FEATURE_ESSENTIALS_OFF]
    },
    {
      key: constants.REMOVE_ASSET_ACTION
    },
    {
      key: constants.AUTO_PUNCH_ACTION
    }
  ]
};

const benefitsActionsOnTopup = {
  key: constants.BENEFITS_GROUP,
  actionFields: [
    {
      key: constants.TOP_UP_POINTS_ACTION
    },
    {
      key: constants.DEDUCT_POINTS_ACTION,
      features: [appConstants.FEATURE_ESSENTIALS_OFF]
    },
    {
      key: constants.ASSIGN_ASSET_ACTION,
      features: [appConstants.FEATURE_ESSENTIALS_OFF]
    },
    {
      key: constants.REMOVE_ASSET_ACTION
    },
    {
      key: constants.AUTO_PUNCH_ACTION
    }
  ]
};

const benefitsActionsOnPurchase = {
  key: constants.BENEFITS_GROUP,
  actionFields: [
    {
      key: constants.ACCUMULATE_POINTS_ACTION,
      automationId: constants.ADD_POINTS_ACTION
    },
    {
      key: constants.DEDUCT_POINTS_ACTION,
      features: [appConstants.FEATURE_ESSENTIALS_OFF]
    },
    {
      key: constants.ASSIGN_ASSET_ACTION,
      features: [appConstants.FEATURE_ESSENTIALS_OFF]
    },
    {
      key: constants.AUTO_PUNCH_ACTION
    }
  ]
};

const benefitsActionsForExceededPunchTrigger = {
  key: constants.BENEFITS_GROUP,
  actionFields: [
    {
      key: constants.ADD_POINTS_ACTION
    },
    {
      key: constants.ASSIGN_ASSET_ACTION,
      features: [appConstants.FEATURE_ESSENTIALS_OFF]
    },
    {
      key: constants.PUNCH_EXCEEDED_ACTION
    }
  ]
};

const communicationCommonActions = [{
  key: constants.SEND_PERSONAL_PUSH_ACTION,
  features: [
    {
      name: appConstants.FEATURES_APP,
      category: appConstants.FEATURES_CATEGORY_ADD_ON
    }
  ]
}, {
  key: constants.SEND_PERSONAL_WEB_PUSH_ACTION,
  features: [
    {
      name: appConstants.FEATURE_NAME_CUSTOMER_PORTAL,
      category: appConstants.FEATURES_CATEGORY_ADD_ON
    }
  ]
},
{
  key: constants.SEND_MESSAGE_ACTION,
  features: [
    {
      name: appConstants.FEATURES_APP,
      category: appConstants.FEATURES_CATEGORY_ADD_ON
    }
  ]
},
{
  key: constants.SEND_MEMBER_SMS_ACTION,
  features: [
    {
      name: appConstants.FEATURES_SMS,
      category: appConstants.FEATURES_CATEGORY_ADD_ON
    }]
},
{
  key: constants.SEND_MEMBER_EMAIL_ACTION,
  features: [
    {
      name: appConstants.FEATURES_MARKETING_EMAILS,
      category: appConstants.FEATURES_CATEGORY_ADD_ON
    }],
  basic: true
},
{
  key: constants.WALLET_PASS_PUSH_NOTIFICATION_ACTION,
  features: [
    {
      name: appConstants.FEATURES_WALLET_PASS,
      category: appConstants.FEATURES_CATEGORY_ADD_ON
    }]
},
{
  key: constants.WALLET_PASS_SYNC_ACTION,
  features: [
    {
      name: appConstants.FEATURES_WALLET_PASS,
      category: appConstants.FEATURES_CATEGORY_ADD_ON
    }]
},
];

const communicationActions = {
  key: constants.COMMUNICATION_GROUP,
  actionFields: communicationCommonActions.concat([{
    key: constants.UNSUBSCRIBE_EMAIL_OR_SMS
  },
  {
    key: constants.SEND_TC_CONSENT_SMS_OR_EMAIL_ACTION,
    features: [
      {
        name: appConstants.FEATURES_SENSITIVE_SETTINGS
      }]
  }
  ])
};

const communicationOneTimeActions = {
  key: constants.COMMUNICATION_GROUP,
  actionFields: communicationCommonActions.concat({
    key: constants.SEND_TC_CONSENT_SMS_OR_EMAIL_ACTION,
    basic: true
  })
};

const communicationActionsForMicroCampaign = {
  key: constants.COMMUNICATION_GROUP,
  actionFields: [
    {
      key: constants.SEND_PERSONAL_PUSH_ACTION,
      features: [
        {
          name: appConstants.FEATURES_APP,
          category: appConstants.FEATURES_CATEGORY_ADD_ON
        }]
    },
    {
      key: constants.SEND_PERSONAL_WEB_PUSH_ACTION,
      features: [
        {
          name: appConstants.FEATURE_NAME_CUSTOMER_PORTAL,
          category: appConstants.FEATURES_CATEGORY_ADD_ON
        }]
    },
    {
      key: constants.SEND_MEMBER_SMS_ACTION,
      features: [
        {
          name: appConstants.FEATURES_SMS,
          category: appConstants.FEATURES_CATEGORY_ADD_ON
        }]
    },
    {
      key: constants.SEND_MEMBER_EMAIL_ACTION,
      features: [
        {
          name: appConstants.FEATURES_MARKETING_EMAILS,
          category: appConstants.FEATURES_CATEGORY_ADD_ON
        }]
    },
  ]
};

const simpleEntireTicketDiscount = {
  key: activityConstants.SIMPLE_ENTIRE_TICKET_DISCOUNT,
  actionFields: [
    {
      key: constants.DISCOUNT_AMOUNT_OFF_ON_ENTIRE_TICKET_ACTION,
      [constants.ACTION_SUB_CATEGORY]: 'discountOnEntireTicket',
      features: [
        {
          name: appConstants.FEATURES_DISCOUNT
        }]
    },
    {
      key: constants.DISCOUNT_PERCENT_OFF_ON_ENTIRE_TICKET_ACTION,
      [constants.ACTION_SUB_CATEGORY]: 'discountOnEntireTicket',
      features: [
        {
          name: appConstants.FEATURES_DISCOUNT
        }]
    },
    {
      key: constants.SEND_CODES_TO_POS,
      [constants.ACTION_SUB_CATEGORY]: 'sendCodesToPos',
      features: [appConstants.FEATURE_ESSENTIALS_OFF]
    }
  ]
};

export const simpleEntireTicketDiscountGift = {
  key: activityConstants.SIMPLE_ENTIRE_TICKET_DISCOUNT,
  actionFields: [
    {
      key: constants.DISCOUNT_AMOUNT_OFF_ON_ENTIRE_TICKET_ACTION,
      [constants.ACTION_SUB_CATEGORY]: 'discountOnEntireTicket',
      features: [
        {
          name: appConstants.FEATURES_DISCOUNT,
          category: appConstants.FEATURES_CATEGORY_POS
        }]
    },
    {
      key: constants.DISCOUNT_PERCENT_OFF_ON_ENTIRE_TICKET_ACTION,
      [constants.ACTION_SUB_CATEGORY]: 'discountOnEntireTicket',
      features: [
        {
          name: appConstants.FEATURES_DISCOUNT,
          category: appConstants.FEATURES_CATEGORY_POS
        }]
    },
    {
      key: constants.SEND_CODES_TO_POS,
      [constants.ACTION_SUB_CATEGORY]: 'sendCodesToPos',
      features: [appConstants.FEATURE_ESSENTIALS_OFF]
    },
    {
      key: constants.NO_POS_GIFT,
      features: [
        {
          name: appConstants.FEATURES_NO_POS_GIFT,
          category: appConstants.FEATURES_CATEGORY_NO_POS_INTEGRATION
        }, appConstants.FEATURE_ESSENTIALS_OFF]
    }
  ]
};

export const simpleSpecificItemsDiscount = {
  key: activityConstants.SIMPLE_SPECIFIC_ITEMS_DISCOUNT,
  actionFields: [
    {
      key: constants.DISCOUNT_AMOUNT_OFF_ON_SPECIFIC_ITEMS_ACTION,
      features: [
        {
          name: appConstants.FEATURES_DISCOUNT,
          category: appConstants.FEATURES_CATEGORY_POS
        }, {
          name: appConstants.FEATURES_SHOPPING_CART_GET_MEMBER_BENEFITS_AND_REDEEM,
          category: appConstants.FEATURES_CATEGORY_POS
        }, appConstants.FEATURE_ESSENTIALS_OFF]
    },
    {
      key: constants.DISCOUNT_PERCENT_OFF_ON_SPECIFIC_ITEMS_ACTION,
      features: [
        {
          name: appConstants.FEATURES_DISCOUNT,
          category: appConstants.FEATURES_CATEGORY_POS
        }, {
          name: appConstants.FEATURES_SHOPPING_CART_GET_MEMBER_BENEFITS_AND_REDEEM,
          category: appConstants.FEATURES_CATEGORY_POS
        }, appConstants.FEATURE_ESSENTIALS_OFF]
    },
    {
      key: constants.DISCOUNT_SPECIAL_PRICE_ACTION,
      features: [
        {
          name: appConstants.FEATURES_DISCOUNT,
          category: appConstants.FEATURES_CATEGORY_POS
        }, {
          name: appConstants.FEATURES_SHOPPING_CART_GET_MEMBER_BENEFITS_AND_REDEEM,
          category: appConstants.FEATURES_CATEGORY_POS
        }, appConstants.FEATURE_ESSENTIALS_OFF]
    },
    {
      key: constants.DISCOUNT_FREE_ITEMS_ACTION,
      features: [
        {
          name: appConstants.FEATURES_DISCOUNT,
          category: appConstants.FEATURES_CATEGORY_POS
        }, {
          name: appConstants.FEATURES_SHOPPING_CART_GET_MEMBER_BENEFITS_AND_REDEEM,
          category: appConstants.FEATURES_CATEGORY_POS
        }, appConstants.FEATURE_ESSENTIALS_OFF]
    }
  ]
};

export const advancedDiscount = {
  key: activityConstants.ADVANCED_DISCOUNT,
  actionFields: [
    {
      key: constants.DISCOUNT_AMOUNT_OFF_ON_SPECIFIC_ITEMS_ADVANCED_ACTION,
      features: [
        {
          name: appConstants.FEATURES_DISCOUNT,
          category: appConstants.FEATURES_CATEGORY_POS
        }, {
          name: appConstants.FEATURES_SHOPPING_CART_GET_MEMBER_BENEFITS_AND_REDEEM,
          category: appConstants.FEATURES_CATEGORY_POS
        }, appConstants.FEATURE_ESSENTIALS_OFF]
    },
    {
      key: constants.DISCOUNT_PERCENT_OFF_ON_SPECIFIC_ITEMS_ADVANCED_ACTION,
      features: [
        {
          name: appConstants.FEATURES_DISCOUNT,
          category: appConstants.FEATURES_CATEGORY_POS
        }, {
          name: appConstants.FEATURES_SHOPPING_CART_GET_MEMBER_BENEFITS_AND_REDEEM,
          category: appConstants.FEATURES_CATEGORY_POS
        }, appConstants.FEATURE_ESSENTIALS_OFF]
    },
    {
      key: constants.DISCOUNT_SPECIAL_PRICE_ADVANCED_ACTION,
      features: [
        {
          name: appConstants.FEATURES_DISCOUNT,
          category: appConstants.FEATURES_CATEGORY_POS
        }, {
          name: appConstants.FEATURES_SHOPPING_CART_GET_MEMBER_BENEFITS_AND_REDEEM,
          category: appConstants.FEATURES_CATEGORY_POS
        }, appConstants.FEATURE_ESSENTIALS_OFF]
    },
    {
      key: constants.DISCOUNT_FREE_ITEMS_ADVANCED_ACTION,
      features: [
        {
          name: appConstants.FEATURES_DISCOUNT,
          category: appConstants.FEATURES_CATEGORY_POS
        }, {
          name: appConstants.FEATURES_SHOPPING_CART_GET_MEMBER_BENEFITS_AND_REDEEM,
          category: appConstants.FEATURES_CATEGORY_POS
        }, appConstants.FEATURE_ESSENTIALS_OFF]
    }
  ]
};

const membershipActions = {
  key: constants.MEMBERSHIP_GROUP,
  actionFields: [
    {
      key: constants.UPDATE_MEMBERSHIP_EXPIRATION_ACTION
    },
    {
      key: constants.TAG_MEMBERSHIP_ACTION,
      features: [appConstants.FEATURE_ESSENTIALS_OFF]
    },
    {
      key: constants.UN_TAG_MEMBERSHIP_ACTION,
      features: [appConstants.FEATURE_ESSENTIALS_OFF]
    },
  ]
};
const membershipActionsOnJoinedTheClub = {
  key: constants.MEMBERSHIP_GROUP,
  actionFields: [
    {
      key: constants.UPDATE_MEMBERSHIP_EXPIRATION_ACTION
    },
    {
      key: constants.TAG_MEMBERSHIP_ACTION,
      features: [appConstants.FEATURE_ESSENTIALS_OFF]
    },
    {
      key: constants.UN_TAG_MEMBERSHIP_ACTION,
      features: [appConstants.FEATURE_ESSENTIALS_OFF]
    },
    {
      key: constants.UPDATE_MEMBERSHIP_SENSITIVE_DATA_ACTION,
      features: [
        {
          name: appConstants.FEATURES_SENSITIVE_SETTINGS
        }],
      hideOnFeatureDenied: [appConstants.FEATURES_SENSITIVE_SETTINGS]
    }
  ]
};

const membershipActionsOnExternalEvent = {
  key: constants.MEMBERSHIP_GROUP,
  actionFields: [
    {
      key: constants.UPDATE_MEMBERSHIP_EXPIRATION_ACTION
    },
    {
      key: constants.UPDATE_MEMBERSHIP_FIELDS_ACTION,
      features: [appConstants.FEATURE_ESSENTIALS_OFF]
    },
    {
      key: constants.DYNAMIC_TAG_MEMBERSHIP_ACTION,
      features: [appConstants.FEATURE_ESSENTIALS_OFF]
    },
    {
      key: constants.DYNAMIC_UN_TAG_MEMBERSHIP_ACTION,
      features: [appConstants.FEATURE_ESSENTIALS_OFF]
    },
    {
      key: constants.UPDATE_MEMBERSHIP_SENSITIVE_DATA_ACTION,
      features: [
        {
          name: appConstants.FEATURES_SENSITIVE_SETTINGS
        }],
      hideOnFeatureDenied: [appConstants.FEATURES_SENSITIVE_SETTINGS]
    }
  ]
};


const membershipActionsOnOneTime = {
  key: constants.MEMBERSHIP_GROUP,
  actionFields: [
    {
      key: constants.UPDATE_MEMBERSHIP_EXPIRATION_ACTION
    },
    {
      key: constants.TAG_MEMBERSHIP_ACTION,
      features: [appConstants.FEATURE_ESSENTIALS_OFF],
      basic: true
    },
    {
      key: constants.UN_TAG_MEMBERSHIP_ACTION,
      features: [appConstants.FEATURE_ESSENTIALS_OFF],
      basic: true
    },
    {
      key: constants.DELETE_MEMBERSHIP_ACTION,
      features: [
        {
          name: appConstants.FEATURES_SENSITIVE_SETTINGS
        }],
      hideOnFeatureDenied: [appConstants.FEATURES_SENSITIVE_SETTINGS],
      basic: true
    }
  ]
};

const membershipActionsOnPurchase = {
  key: constants.MEMBERSHIP_GROUP,
  actionFields: [
    {
      key: constants.UPDATE_MEMBERSHIP_EXPIRATION_ACTION
    },
    {
      key: constants.TAG_MEMBERSHIP_ACTION,
      features: [appConstants.FEATURE_ESSENTIALS_OFF]
    },
    {
      key: constants.UN_TAG_MEMBERSHIP_ACTION,
      features: [appConstants.FEATURE_ESSENTIALS_OFF]
    }
  ]
};

const otherActions = {
  key: 'other',
  actionFields: [
    {
      key: constants.EXPORT_EVENT_ACTION,
      features: [appConstants.FEATURE_ESSENTIALS_OFF]
    }]
};

// conditions
const conditionsPerTriggers = {
  [constants.TRIGGER_BEACON_SIGNAL]: [schemaConditions.beaconType, schemaConditions.uid, schemaConditions.major, schemaConditions.minor, schemaConditions.probability],
  [constants.TRIGGER_CLAIM]: [schemaConditions.catalogItems, schemaConditions.tag, schemaConditions.benefit, schemaConditions.probability],
  [constants.TRIGGER_DEAL]: [schemaConditions.shoppingCartQuantityForBenefit, schemaConditions.totalSum, schemaConditions.shoppingCartPriceForBenefit, schemaConditions.purchaseBranchId, schemaConditions.purchaseLocationsGroups, schemaConditions.posId, schemaConditions.purchaseTags, schemaConditions.numOfMembers, schemaConditions.orderType, schemaConditions.purchaseTransactionSource, schemaConditions.purchaseTransactionSourceName],
  [constants.TRIGGER_GIFT]: [schemaConditions.shoppingCartQuantityForBenefit, schemaConditions.totalSum, schemaConditions.shoppingCartPriceForBenefit, schemaConditions.purchaseBranchId, schemaConditions.purchaseLocationsGroups, schemaConditions.posId, schemaConditions.purchaseTags, schemaConditions.numOfMembers, schemaConditions.orderType, schemaConditions.purchaseTransactionSource, schemaConditions.purchaseTransactionSourceName],
  [constants.TRIGGER_PROMO_CODE]: [schemaConditions.shoppingCartQuantityForBenefit, schemaConditions.totalSum, schemaConditions.shoppingCartPriceForBenefit, schemaConditions.purchaseBranchId, schemaConditions.purchaseLocationsGroups, schemaConditions.posId, schemaConditions.purchaseTags, schemaConditions.orderType, schemaConditions.purchaseTransactionSource, schemaConditions.purchaseTransactionSourceName, schemaConditions.probability],
  [constants.TRIGGER_PUNCH_CARD]: [schemaConditions.totalSum, schemaConditions.shoppingCartPrice, schemaConditions.purchaseBranchId, schemaConditions.purchaseLocationsGroups, schemaConditions.purchaseTags, schemaConditions.orderType],
  [constants.TRIGGER_EXCEEDED_PUNCH]: [schemaConditions.benefit, schemaConditions.probability],
  [constants.TRIGGER_EXTERNAL_EVENT]: [schemaConditions.externalEventType, schemaConditions.externalEventSubtype, schemaConditions.triggerEventSubmittedTransactionSource, schemaConditions.triggerEventSubmittedTransactionSourceName, schemaConditions.externalEventString1, schemaConditions.externalEventString2, schemaConditions.externalEventString3, schemaConditions.externalEventString4, schemaConditions.externalEventString5, schemaConditions.externalEventString6, schemaConditions.externalEventString7, schemaConditions.externalEventString8, schemaConditions.externalEventString9, schemaConditions.externalEventString10, schemaConditions.externalEventNumber1, schemaConditions.externalEventNumber2, schemaConditions.externalEventNumber3, schemaConditions.externalEventNumber4, schemaConditions.externalEventNumber5, schemaConditions.externalEventNumber6, schemaConditions.externalEventNumber7, schemaConditions.externalEventNumber8, schemaConditions.externalEventNumber9, schemaConditions.externalEventNumber10, schemaConditions.externalEventDate1, schemaConditions.externalEventDate2, schemaConditions.externalEventDate3, schemaConditions.externalEventDate4, schemaConditions.externalEventDate5, schemaConditions.externalEventDate6, schemaConditions.externalEventDate7, schemaConditions.externalEventDate8, schemaConditions.externalEventDate9, schemaConditions.externalEventDate10, schemaConditions.externalEventBool1, schemaConditions.externalEventBool2, schemaConditions.externalEventBool3, schemaConditions.externalEventBool4, schemaConditions.externalEventBool5, schemaConditions.externalEventBool6, schemaConditions.externalEventBool7, schemaConditions.externalEventBool8, schemaConditions.externalEventBool9, schemaConditions.externalEventBool10, schemaConditions.probability],
  [constants.TRIGGER_FORM_FILLED]: [schemaConditions.forms, schemaConditions.probability],
  [constants.TRIGGER_PAYS_WITH_POINTS_AT_POS]: [schemaConditions.actualCharge, schemaConditions.probability],
  [constants.TRIGGER_PAYS_WITH_CREDIT_AT_POS]: [schemaConditions.actualCharge, schemaConditions.probability],
  [constants.TRIGGER_PAYS_WITH_APP_CREDIT_CARD]: [schemaConditions.itemPrice, schemaConditions.paymentFlow, schemaConditions.itemCodeIpn, schemaConditions.probability],
  [constants.TRIGGER_TOP_UP]: [schemaConditions.itemPrice, schemaConditions.paymentFlowInApp, schemaConditions.itemCodeIpn, schemaConditions.providerType, schemaConditions.probability],
  [constants.TRIGGER_PAYS_WITH_APP]: [schemaConditions.itemPrice, schemaConditions.paymentFlowInApp, schemaConditions.itemCodeIpn, schemaConditions.probability],
  [constants.TRIGGER_PAYS_WITH_PAYPAL]: [schemaConditions.itemPrice, schemaConditions.probability],
  [constants.TRIGGER_APP_CREDIT_CARD_PAYMENT_IS_DECLINED]: [schemaConditions.itemPrice, schemaConditions.probability],
  [constants.TRIGGER_PAYPAL_PAYMENT_IS_DECLINED]: [schemaConditions.itemPrice, schemaConditions.probability],
  [constants.TRIGGER_JOINED_APP]: [schemaConditions.probability],
  [constants.TRIGGER_JOINED_CLUB]: [schemaConditions.usedReferralCode, schemaConditions.joinClubSource, schemaConditions.probability, schemaConditions.joinClubQuickRegistrationCode, schemaConditions.userActionTransactionSource, schemaConditions.userActionTransactionSourceName],
  [constants.TRIGGER_CLUB_MEMBER_ANNIVERSARY]: [schemaConditions.probability],
  [constants.TRIGGER_ONE_TIME_CODE]: [schemaConditions.probability],
  [constants.TRIGGER_OPENED_CLUB]: [schemaConditions.probability],
  [constants.TRIGGER_PUNCH]: [schemaConditions.benefit, schemaConditions.TotalPunchesAfterOperation, schemaConditions.lastPunch, schemaConditions.probability],
  [constants.TRIGGER_PURCHASE_ANALYZED]: [schemaConditions.shoppingCartQuantity, schemaConditions.totalSum, schemaConditions.shoppingCartPrice, schemaConditions.purchaseBranchId, schemaConditions.purchaseLocationsGroups, schemaConditions.posId, schemaConditions.purchaseTags, schemaConditions.numOfMembers, schemaConditions.orderType, schemaConditions.purchaseTransactionSource, schemaConditions.purchaseTransactionSourceName, schemaConditions.meanOfPayment, schemaConditions.probability],
  [constants.TRIGGER_START_PURCHASE]: [schemaConditions.shoppingCartQuantity, schemaConditions.totalSum, schemaConditions.shoppingCartPrice, schemaConditions.purchaseBranchId, schemaConditions.purchaseLocationsGroups, schemaConditions.posId, schemaConditions.purchaseTags, schemaConditions.numOfMembers, schemaConditions.orderType, schemaConditions.purchaseTransactionSource, schemaConditions.purchaseTransactionSourceName, schemaConditions.probability],
  [constants.TRIGGER_PURCHASED_ASSET]: [schemaConditions.benefit, schemaConditions.benefitPrice, schemaConditions.probability],
  [constants.TRIGGER_RECEIVED_ASSET]: [schemaConditions.benefit, schemaConditions.probability],
  [constants.TRIGGER_RECEIVED_POINTS]: [schemaConditions.numOfPoints, schemaConditions.pointsTotalSum, schemaConditions.probability],
  [constants.TRIGGER_RECEIVED_CREDIT]: [schemaConditions.creditUsed, schemaConditions.totalCredit, schemaConditions.probability],
  [constants.TRIGGER_REDEEM]: [schemaConditions.benefit, schemaConditions.probability, schemaConditions.userActionTransactionSource, schemaConditions.userActionTransactionSourceName],
  [constants.TRIGGER_SHOPPING_CART]: [schemaConditions.itemCode, schemaConditions.itemName, schemaConditions.itemTag, schemaConditions.price, schemaConditions.departmentCode, schemaConditions.departmentName, schemaConditions.itemsGroup],
  [constants.TRIGGER_TAG_OPERATION]: [schemaConditions.tag, schemaConditions.probability],
  [constants.TRIGGER_MEMBER_HAS_BIRTHDAY]: [schemaConditions.probability],
  [constants.TRIGGER_MEMBER_HAS_ANNIVERSARY]: [schemaConditions.probability],
  [constants.TRIGGER_SMS_UNSUBSCRIBE]: [schemaConditions.probability],
  [constants.TRIGGER_EMAIL_UNSUBSCRIBE]: [schemaConditions.probability],
  [constants.TRIGGER_UNTAG_OPERATION]: [schemaConditions.tag, schemaConditions.probability],
  [constants.TRIGGER_UPDATE_MEMBERSHIP]: [schemaConditions.probability],
  [constants.TRIGGER_USED_POINTS]: [schemaConditions.numOfPoints, schemaConditions.pointsTotalSum, schemaConditions.probability],
  [constants.TRIGGER_USED_CREDIT]: [schemaConditions.creditUsed, schemaConditions.totalCredit, schemaConditions.probability],
  [constants.TRIGGER_ONE_TIME_ACTION]: [schemaConditions.probability],
  [constants.TRIGGER_JOINED_CLUB_WITH_REFERRAL_CODE]: [schemaConditions.probability],
  [constants.TRIGGER_REFERRED_FRIEND_PURCHASE]: [schemaConditions.shoppingCartQuantity, schemaConditions.totalSum, schemaConditions.shoppingCartPrice, schemaConditions.purchaseBranchId, schemaConditions.purchaseLocationsGroups, schemaConditions.posId, schemaConditions.purchaseTags, schemaConditions.numOfMembers, schemaConditions.orderType, schemaConditions.purchaseTransactionSource, schemaConditions.purchaseTransactionSourceName, schemaConditions.meanOfPayment, schemaConditions.probability],
  [constants.TRIGGER_USED_MY_REFERRAL_CODE]: [schemaConditions.probability],
  [constants.TRIGGER_SHARE_BUDGET]: [schemaConditions.probability],
  [constants.TRIGGER_USER_CLAIMED_SHARED_BUDGET]: [schemaConditions.probability],
  [constants.TRIGGER_NOTIFY_USER_SHARED_BUDGET_DONE]: [schemaConditions.probability],
  [constants.TRIGGER_CONSENTED_TO_TERMS_AND_CONDITIONS]: [schemaConditions.tcConsentSource, schemaConditions.probability],
  [constants.TRIGGER_ASK_TO_BE_FORGOTTEN]: [schemaConditions.probability]
};

const conditionsPerUserAction = {
  [constants.TRIGGER_JOINED_CLUB]: {
    [constants.CONDITIONS_PER_ACTION]: [schemaConditions.referralData, schemaConditions.joinClubSource, schemaConditions.joinClubQuickRegistrationCode]
  },
  [constants.TRIGGER_PAYS_WITH_APP]: {
    [constants.CONDITIONS_PER_ACTION]: [schemaConditions.filterPaymentFlow, schemaConditions.itemPrice]
  },
  [constants.TRIGGER_GAVE_CONSENT]: {
    [constants.CONDITIONS_PER_ACTION]: [schemaConditions.gaveConsentSource]
  },
  [constants.TRIGGER_OPENED_CLUB]: {
    [constants.AGGREGATED_CONDITIONS_PER_ACTION]: [schemaConditions.numberOfUserActions]
  },
  [constants.TRIGGER_DID_NOT_OPEN_CLUB]: {
    [constants.CONDITIONS_PER_ACTION]: []
  },
  [constants.TRIGGER_PUNCH]: {
    [constants.CONDITIONS_PER_ACTION]: [schemaConditions.punchBenefit, schemaConditions.lastPunch]
  },
  [constants.TRIGGER_PURCHASE_ANALYZED]: {
    [constants.CONDITIONS_PER_ACTION]: [schemaConditions.shoppingCartPositiveQuantity, schemaConditions.totalSum, schemaConditions.shoppingCartPrice, schemaConditions.purchaseBranchId, schemaConditions.purchaseLocationsGroups, schemaConditions.posId, schemaConditions.purchaseTags, schemaConditions.numOfMembers, schemaConditions.orderType, schemaConditions.purchaseTransactionSource, schemaConditions.purchaseTransactionSourceName],
    [constants.AGGREGATED_CONDITIONS_PER_ACTION]: [schemaConditions.numberOfPurchases, schemaConditions.totalSumOfAllPurchases],
  },
  [constants.TRIGGER_REFERRED_FRIEND_PURCHASE]: {
    [constants.CONDITIONS_PER_ACTION]: [schemaConditions.purchaseBranchId, schemaConditions.purchaseLocationsGroups],
    /* [constants.CONDITIONS_PER_ACTION]: [schemaConditions.friendShoppingCartPositiveQuantity, schemaConditions.friendPurchaseTotalSum, schemaConditions.shoppingCartPrice, schemaConditions.purchaseBranchId], */
  },
  [constants.TRIGGER_FAILED_TO_SEND_SMS]: {
    [constants.CONDITIONS_PER_ACTION]: [schemaConditions.failSmsStep, schemaConditions.failSmsType, schemaConditions.activities],
  },

  [constants.TRIGGER_RECEIVED_ASSET]: {
    [constants.CONDITIONS_PER_ACTION]: [schemaConditions.benefit, schemaConditions.benefitSource],
    [constants.AGGREGATED_CONDITIONS_PER_ACTION]: [schemaConditions.numberOfUserActions],
  },
  [constants.TRIGGER_DIDNT_RECEIVE_ASSET]: {
    [constants.CONDITIONS_PER_ACTION]: [schemaConditions.benefit, schemaConditions.benefitSource]
  },
  [constants.TRIGGER_RECEIVED_POINTS]: {
    [constants.CONDITIONS_PER_ACTION]: [schemaConditions.numOfPoints, schemaConditions.pointsTotalSum],
    [constants.AGGREGATED_CONDITIONS_PER_ACTION]: [schemaConditions.numberOfUserActions],
  },
  [constants.TRIGGER_RECEIVED_CREDIT]: {
    [constants.CONDITIONS_PER_ACTION]: [schemaConditions.creditUsed, schemaConditions.totalCredit],
    [constants.AGGREGATED_CONDITIONS_PER_ACTION]: [schemaConditions.numberOfUserActions],
  },
  [constants.TRIGGER_REDEEM]: {
    [constants.CONDITIONS_PER_ACTION]: [schemaConditions.redeemBenefit, schemaConditions.branchId],
    [constants.AGGREGATED_CONDITIONS_PER_ACTION]: [schemaConditions.numberOfUserActions],
  },
  [constants.TRIGGER_USED_POINTS]: {
    [constants.CONDITIONS_PER_ACTION]: [schemaConditions.numOfPoints, schemaConditions.pointsTotalSum],
    [constants.AGGREGATED_CONDITIONS_PER_ACTION]: [schemaConditions.numberOfUserActions],
  },
  [constants.TRIGGER_USED_CREDIT]: {
    [constants.CONDITIONS_PER_ACTION]: [schemaConditions.creditUsed, schemaConditions.totalCredit],
    [constants.AGGREGATED_CONDITIONS_PER_ACTION]: [schemaConditions.numberOfUserActions],
  },
  [constants.TRIGGER_DIDNT_PURCHASE]: {
    [constants.CONDITIONS_PER_ACTION]: [schemaConditions.purchaseBranchId, schemaConditions.purchaseLocationsGroups, schemaConditions.certainItemsExist],
  },
  [constants.TRIGGER_DIDNT_REDEEM]: {
    [constants.CONDITIONS_PER_ACTION]: [schemaConditions.redeemBenefit, schemaConditions.branchId]
  },
  [constants.TRIGGER_PUSH_FAILED]: {},
  [constants.TRIGGER_EMAIL_RECEIVED_SUCCESSFULLY]: {
    [constants.CONDITIONS_PER_ACTION]: [schemaConditions.emailSentFrom, schemaConditions.emailTemplate]
  },
  [constants.TRIGGER_EMAIL_WAS_NOT_RECEIVED]: {
    [constants.CONDITIONS_PER_ACTION]: [schemaConditions.emailSentFrom, schemaConditions.emailTemplate]
  },
  [constants.TRIGGER_EMAIL_WAS_NOT_OPENED]: {
    [constants.CONDITIONS_PER_ACTION]: [schemaConditions.emailSentFrom, schemaConditions.emailTemplate]
  },
};

const removedGlobalConditionsPerTrigger = {
  [constants.TRIGGER_GIFT]: [constants.OCCURRENCES_GLOBAL_CONDITION],
  [constants.TRIGGER_PROMO_CODE]: [constants.MEMBERSHIP_GLOBAL_CONDITION, constants.OCCURRENCES_GLOBAL_CONDITION],
  [constants.TRIGGER_PUNCH_CARD]: [constants.OCCURRENCES_GLOBAL_CONDITION],
  [constants.TRIGGER_ONE_TIME_ACTION]: [constants.DATETIME_GLOBAL_CONDITION, constants.OCCURRENCES_GLOBAL_CONDITION],
  [constants.TRIGGER_FILTER_MEMBERS]: [constants.DATETIME_GLOBAL_CONDITION, constants.OCCURRENCES_GLOBAL_CONDITION],
  [constants.TRIGGER_MEMBER_HAS_BIRTHDAY]: [constants.DATETIME_GLOBAL_CONDITION],
  [constants.TRIGGER_MEMBER_HAS_ANNIVERSARY]: [constants.DATETIME_GLOBAL_CONDITION],
  [constants.TRIGGER_CLUB_MEMBER_ANNIVERSARY]: [constants.DATETIME_GLOBAL_CONDITION],
};

const actionGroup1 = [communicationActions, membershipActions, benefitsActions, otherActions];
const actionGroupTopUp = [communicationActions, membershipActions, benefitsActionsOnTopup, otherActions];
const actionGroup1ExternalEvent = [communicationActions, membershipActionsOnExternalEvent, benefitsActionsOnExternalEvent, otherActions];
const actionGroupJoinedClub = [communicationActions, membershipActionsOnJoinedTheClub, benefitsActions, otherActions];
const oneTimeActionActionGroup1 = [communicationOneTimeActions, membershipActionsOnOneTime, benefitsActions, otherActions];
const purchaseActions = [communicationActions, membershipActionsOnPurchase, benefitsActionsOnPurchase, otherActions];

const actionsPerTriggers = {
  [constants.TRIGGER_BEACON_SIGNAL]: actionGroup1,
  [constants.TRIGGER_CLAIM]: actionGroup1,
  [constants.TRIGGER_CODE_GENERATOR]: actionGroup1,
  [constants.TRIGGER_DEAL]: [simpleEntireTicketDiscount, simpleSpecificItemsDiscount, advancedDiscount],
  [constants.TRIGGER_GIFT]: [simpleEntireTicketDiscountGift, simpleSpecificItemsDiscount, advancedDiscount],
  [constants.TRIGGER_PROMO_CODE]: [simpleEntireTicketDiscount, simpleSpecificItemsDiscount, advancedDiscount],
  [constants.TRIGGER_PUNCH_CARD]: [simpleEntireTicketDiscount, simpleSpecificItemsDiscount, advancedDiscount],
  [constants.TRIGGER_EXCEEDED_PUNCH]: [communicationActions, membershipActions, benefitsActionsForExceededPunchTrigger, otherActions],
  [constants.TRIGGER_EXTERNAL_EVENT]: actionGroup1ExternalEvent,
  [constants.TRIGGER_FORM_FILLED]: actionGroup1,
  [constants.TRIGGER_PAYS_WITH_POINTS_AT_POS]: actionGroup1,
  [constants.TRIGGER_PAYS_WITH_CREDIT_AT_POS]: actionGroup1,
  [constants.TRIGGER_PAYS_WITH_APP_CREDIT_CARD]: actionGroup1,
  [constants.TRIGGER_TOP_UP]: actionGroupTopUp,
  [constants.TRIGGER_PAYS_WITH_APP]: actionGroup1,
  [constants.TRIGGER_PAYS_WITH_PAYPAL]: actionGroup1,
  [constants.TRIGGER_APP_CREDIT_CARD_PAYMENT_IS_DECLINED]: actionGroup1,
  [constants.TRIGGER_PAYPAL_PAYMENT_IS_DECLINED]: actionGroup1,
  [constants.TRIGGER_JOINED_APP]: actionGroup1,
  [constants.TRIGGER_JOINED_CLUB]: actionGroupJoinedClub,
  [constants.TRIGGER_CLUB_MEMBER_ANNIVERSARY]: actionGroup1,
  [constants.TRIGGER_ONE_TIME_CODE]: actionGroup1,
  [constants.TRIGGER_OPENED_CLUB]: actionGroup1,
  [constants.TRIGGER_PUNCH]: actionGroup1,
  [constants.TRIGGER_PURCHASE_ANALYZED]: purchaseActions,
  [constants.TRIGGER_START_PURCHASE]: actionGroup1,
  [constants.TRIGGER_PURCHASED_ASSET]: actionGroup1,
  [constants.TRIGGER_RECEIVED_ASSET]: actionGroup1,
  [constants.TRIGGER_RECEIVED_POINTS]: actionGroup1,
  [constants.TRIGGER_RECEIVED_CREDIT]: actionGroup1,
  [constants.TRIGGER_REDEEM]: actionGroup1,
  [constants.TRIGGER_TAG_OPERATION]: actionGroup1,
  [constants.TRIGGER_MEMBER_HAS_BIRTHDAY]: actionGroup1,
  [constants.TRIGGER_MEMBER_HAS_ANNIVERSARY]: actionGroup1,
  [constants.TRIGGER_SMS_UNSUBSCRIBE]: actionGroup1,
  [constants.TRIGGER_EMAIL_UNSUBSCRIBE]: actionGroup1,
  [constants.TRIGGER_UNTAG_OPERATION]: actionGroup1,
  [constants.TRIGGER_UPDATE_MEMBERSHIP]: actionGroup1,
  [constants.TRIGGER_USED_POINTS]: actionGroup1,
  [constants.TRIGGER_USED_CREDIT]: actionGroup1,
  [constants.TRIGGER_JOINED_CLUB_WITH_REFERRAL_CODE]: actionGroup1,
  [constants.TRIGGER_REFERRED_FRIEND_PURCHASE]: actionGroup1,
  [constants.TRIGGER_USED_MY_REFERRAL_CODE]: actionGroup1,
  [constants.TRIGGER_ONE_TIME_ACTION]: oneTimeActionActionGroup1,
  [constants.TRIGGER_CONSENTED_TO_TERMS_AND_CONDITIONS]: actionGroup1,
  [constants.TRIGGER_ASK_TO_BE_FORGOTTEN]: actionGroup1,
  [constants.TRIGGER_SHARE_BUDGET]: [
    {
      key: constants.COMMUNICATION_GROUP,
      actionFields: [
        {
          key: constants.SEND_PERSONAL_PUSH_ACTION,
          features: [appConstants.FEATURE_ESSENTIALS_OFF]
        },
        {
          key: constants.SEND_PERSONAL_WEB_PUSH_ACTION,
          features: [appConstants.FEATURE_ESSENTIALS_OFF]
        },
        {
          key: constants.SEND_MESSAGE_ACTION,
          features: [appConstants.FEATURE_ESSENTIALS_OFF]
        }]
    }],
  [constants.TRIGGER_USER_CLAIMED_SHARED_BUDGET]: [communicationActions],
  [constants.TRIGGER_NOTIFY_USER_SHARED_BUDGET_DONE]: [communicationActions],
  [constants.TRIGGER_MICRO_CAMPAIGN]: [communicationActionsForMicroCampaign]
};

const pointsTransactionMentions = {
  [constants.MENTION_POINTS_TRANSACTION_CATEGORY]: [
    mentions.weightedPointsMention
  ]
};
const usedPointsTransactionMentions = {
  [constants.MENTION_POINTS_TRANSACTION_CATEGORY]: [
    mentions.usedPointsMention
  ]
};
const budgetTransactionMentions = {
  [constants.MENTION_BUDGET_TRANSACTION_CATEGORY]: [
    mentions.weightedBudgetMention
  ]
};
const fullShareBudgetMentions = {
  [constants.MENTION_SHARE_BUDGET_CATEGORY]: [
    mentions.shareBudgetSenderMention,
    mentions.shareBudgetReceiveMention,
    mentions.shareBudgetAmountMention,
    mentions.shareBudgetTypeMention,
    mentions.shareBudgetLinkMention,
  ]
};

const shareBudgetMentions = {
  [constants.MENTION_SHARE_BUDGET_CATEGORY]: [
    mentions.shareBudgetSenderMention,
    mentions.shareBudgetReceiveMention,
    mentions.shareBudgetAmountMention,
    mentions.shareBudgetTypeMention,
  ]
};

const membershipByRegFormMentions = {
  [constants.MENTION_MEMBERSHIP_BY_REG_FORM_CATEGORY]: [
    mentions.memberIdMention,
    mentions.firstNameMention,
    mentions.lastNameMention,
    mentions.emailMention,
    mentions.birthdayMention,
    mentions.anniversaryMention,
    mentions.termsOfUseDateMention,
    mentions.genericString1Mention,
    mentions.genericString2Mention,
    mentions.genericString3Mention,
    mentions.genericString4Mention,
    mentions.genericString5Mention,
    mentions.genericString6Mention,
    mentions.genericString7Mention,
    mentions.genericString8Mention,
    mentions.genericString9Mention,
    mentions.genericString10Mention,
    mentions.genericInteger1Mention,
    mentions.genericInteger2Mention,
    mentions.genericInteger3Mention,
    mentions.genericInteger4Mention,
    mentions.genericInteger5Mention,
    mentions.genericInteger6Mention,
    mentions.genericInteger7Mention,
    mentions.genericInteger8Mention,
    mentions.genericInteger9Mention,
    mentions.genericInteger10Mention,
    mentions.genericDate1Mention,
    mentions.genericDate2Mention,
    mentions.genericDate3Mention,
    mentions.genericDate4Mention,
    mentions.genericDate5Mention,
    mentions.genericDate6Mention,
    mentions.genericDate7Mention,
    mentions.genericDate8Mention,
    mentions.genericDate9Mention,
    mentions.genericDate10Mention
  ]
};

const membershipAddressMentions = {
  [constants.MENTION_MEMBERSHIP_ADDRESS_CATEGORY]: [
    mentions.addressCityMention,
    mentions.addressCountryMention,
    mentions.addressFloorMention,
    mentions.addressHomeMention,
    mentions.addressLine1Mention,
    mentions.addressLine2Mention,
    mentions.addressStateMention,
    mentions.addressStreetMention,
    mentions.addressZipCodeMention,
    mentions.favoriteBranchIdMention,
    mentions.homeBranchIdMention,
    mentions.phoneNumberMention
  ]
};

const membershipAlwaysShowMentions = {
  [constants.MENTION_MEMBERSHIP_ALWAYS_SHOW_CATEGORY]: [
    mentions.budgetWeightedMention,
    mentions.expirationDateMention,
    mentions.pointsMention,
    mentions.comoMemberIdMention,
  ]
};

const externalEventMentions = {
  [constants.MENTION_MEMBERSHIP_EXTERNAL_EVENT_CATEGORY]: [
    mentions.dateValue1Mention,
    mentions.dateValue2Mention,
    mentions.dateValue3Mention,
    mentions.dateValue4Mention,
    mentions.dateValue5Mention,
    mentions.dateValue6Mention,
    mentions.dateValue7Mention,
    mentions.dateValue8Mention,
    mentions.dateValue9Mention,
    mentions.dateValue10Mention,
    mentions.numericValue1Mention,
    mentions.numericValue2Mention,
    mentions.numericValue3Mention,
    mentions.numericValue4Mention,
    mentions.numericValue5Mention,
    mentions.numericValue6Mention,
    mentions.numericValue7Mention,
    mentions.numericValue8Mention,
    mentions.numericValue9Mention,
    mentions.numericValue10Mention,
    mentions.stringValue1Mention,
    mentions.stringValue2Mention,
    mentions.stringValue3Mention,
    mentions.stringValue4Mention,
    mentions.stringValue5Mention,
    mentions.stringValue6Mention,
    mentions.stringValue7Mention,
    mentions.stringValue8Mention,
    mentions.stringValue9Mention,
    mentions.stringValue10Mention,
    mentions.subTypeMention,
    mentions.eventTimeExternalEventMention,
    mentions.GetTimeStampMention(constants.MENTION_MEMBERSHIP_EXTERNAL_EVENT_CATEGORY),
    mentions.GetUnixTimeStampMention(constants.MENTION_MEMBERSHIP_EXTERNAL_EVENT_CATEGORY),
  ]
};

const purchaseMentions = {
  [constants.MENTION_MEMBERSHIP_PURCHASE_CATEGORY]: [
    mentions.branchIdMention,
    mentions.cashierMention,
    mentions.totalSumMention,
    mentions.GetTimeStampMention(constants.MENTION_MEMBERSHIP_PURCHASE_CATEGORY),
    mentions.GetUnixTimeStampMention(constants.MENTION_MEMBERSHIP_PURCHASE_CATEGORY)
  ]
};

const updateMembershipMentions = {
  [constants.MENTION_UPDATE_MEMBERSHIP_CATEGORY]: [
    mentions.GetTimeStampMention(constants.MENTION_UPDATE_MEMBERSHIP_CATEGORY),
    mentions.GetUnixTimeStampMention(constants.MENTION_UPDATE_MEMBERSHIP_CATEGORY),
    mentions.addressCityUpdateMembershipMention,
    mentions.addressCountryUpdateMembershipMention,
    mentions.addressFloorUpdateMembershipMention,
    mentions.addressHomeUpdateMembershipMention,
    mentions.addressLine1UpdateMembershipMention,
    mentions.addressLine2UpdateMembershipMention,
    mentions.addressStateUpdateMembershipMention,
    mentions.addressStreetUpdateMembershipMention,
    mentions.addressZipCodeUpdateMembershipMention,
    mentions.anniversaryUpdateMembershipMention,
    mentions.birthdayUpdateMembershipMention,
    mentions.budgetWeightedUpdateMembershipMention,
    mentions.emailUpdateMembershipMention,
    mentions.expirationDateUpdateMembershipMention,
    mentions.favoriteBranchIdUpdateMembershipMention,
    mentions.firstNameUpdateMembershipMention,
    mentions.genericDate1UpdateMembershipMention,
    mentions.genericDate2UpdateMembershipMention,
    mentions.genericDate3UpdateMembershipMention,
    mentions.genericDate4UpdateMembershipMention,
    mentions.genericDate5UpdateMembershipMention,
    mentions.genericDate6UpdateMembershipMention,
    mentions.genericDate7UpdateMembershipMention,
    mentions.genericDate8UpdateMembershipMention,
    mentions.genericDate9UpdateMembershipMention,
    mentions.genericDate10UpdateMembershipMention,
    mentions.genericInteger1UpdateMembershipMention,
    mentions.genericInteger2UpdateMembershipMention,
    mentions.genericInteger3UpdateMembershipMention,
    mentions.genericInteger4UpdateMembershipMention,
    mentions.genericInteger5UpdateMembershipMention,
    mentions.genericInteger6UpdateMembershipMention,
    mentions.genericInteger7UpdateMembershipMention,
    mentions.genericInteger8UpdateMembershipMention,
    mentions.genericInteger9UpdateMembershipMention,
    mentions.genericInteger10UpdateMembershipMention,
    mentions.genericString1UpdateMembershipMention,
    mentions.genericString2UpdateMembershipMention,
    mentions.genericString3UpdateMembershipMention,
    mentions.genericString4UpdateMembershipMention,
    mentions.genericString5UpdateMembershipMention,
    mentions.genericString6UpdateMembershipMention,
    mentions.genericString7UpdateMembershipMention,
    mentions.genericString8UpdateMembershipMention,
    mentions.genericString9UpdateMembershipMention,
    mentions.genericString10UpdateMembershipMention,
    mentions.homeBranchIdUpdateMembershipMention,
    mentions.lastNameUpdateMembershipMention,
    mentions.memberIdUpdateMembershipMention,
    mentions.phoneNumberUpdateMembershipMention,
    mentions.pointsUpdateMembershipMention,
    mentions.termsOfUseDateUpdateMembershipMention
  ]
};

const joinClubMentions = {
  ...{
    [constants.MENTION_MEMBERSHIP_JOIN_CLUB_CATEGORY]: [
      mentions.GetTimeStampMention(constants.MENTION_MEMBERSHIP_JOIN_CLUB_CATEGORY),
      mentions.GetUnixTimeStampMention(constants.MENTION_MEMBERSHIP_JOIN_CLUB_CATEGORY),
    ]
  },
  ...membershipByRegFormMentions,
  ...membershipAlwaysShowMentions,
  ...membershipAddressMentions
};

const mentionsPerTriggerGroup1 = {
  ...membershipByRegFormMentions,
  ...membershipAlwaysShowMentions
};

const mentionsPerTriggerGroup2 = {
  ...membershipByRegFormMentions,
  ...membershipAlwaysShowMentions,
  ...purchaseMentions
};

const mentionsCategoriesOrder = {
  [constants.MENTION_MEMBERSHIP_CATEGORY]: 0,
  [constants.MENTION_MEMBERSHIP_PURCHASE_CATEGORY]: 1,
  [constants.MENTION_MEMBERSHIP_EXTERNAL_EVENT_CATEGORY]: 2,
  [constants.MENTION_MEMBERSHIP_JOIN_CLUB_CATEGORY]: 3,
  [constants.MENTION_UPDATE_MEMBERSHIP_CATEGORY]: 4
};

const mentionsPerTriggers = {
  '': mentionsPerTriggerGroup1,
  [constants.TRIGGER_BEACON_SIGNAL]: mentionsPerTriggerGroup1,
  [constants.TRIGGER_CLAIM]: mentionsPerTriggerGroup1,
  [constants.TRIGGER_EXCEEDED_PUNCH]: mentionsPerTriggerGroup1,
  [constants.TRIGGER_EXTERNAL_EVENT]: {
    ...membershipByRegFormMentions,
    ...membershipAlwaysShowMentions,
    ...membershipAddressMentions,
    ...externalEventMentions
  },
  [constants.TRIGGER_FORM_FILLED]: mentionsPerTriggerGroup1,
  [constants.TRIGGER_PAYS_WITH_POINTS_AT_POS]: mentionsPerTriggerGroup1,
  [constants.TRIGGER_PAYS_WITH_CREDIT_AT_POS]: mentionsPerTriggerGroup1,
  [constants.TRIGGER_PAYS_WITH_APP_CREDIT_CARD]: mentionsPerTriggerGroup1,
  [constants.TRIGGER_PAYS_WITH_APP]: mentionsPerTriggerGroup1,
  [constants.TRIGGER_TOP_UP]: mentionsPerTriggerGroup1,
  [constants.TRIGGER_PAYS_WITH_PAYPAL]: mentionsPerTriggerGroup1,
  [constants.TRIGGER_APP_CREDIT_CARD_PAYMENT_IS_DECLINED]: mentionsPerTriggerGroup1,
  [constants.TRIGGER_PAYPAL_PAYMENT_IS_DECLINED]: mentionsPerTriggerGroup1,
  [constants.TRIGGER_JOINED_APP]: mentionsPerTriggerGroup1,
  [constants.TRIGGER_JOINED_CLUB]: joinClubMentions,
  [constants.TRIGGER_ONE_TIME_CODE]: mentionsPerTriggerGroup1,
  [constants.TRIGGER_OPENED_CLUB]: mentionsPerTriggerGroup1,
  [constants.TRIGGER_PUNCH]: mentionsPerTriggerGroup1,
  [constants.TRIGGER_PURCHASE_ANALYZED]: mentionsPerTriggerGroup2,
  [constants.TRIGGER_START_PURCHASE]: mentionsPerTriggerGroup2,
  [constants.TRIGGER_PURCHASED_ASSET]: mentionsPerTriggerGroup1,
  [constants.TRIGGER_RECEIVED_ASSET]: mentionsPerTriggerGroup1,
  [constants.TRIGGER_RECEIVED_POINTS]: {
    ...mentionsPerTriggerGroup1,
    ...pointsTransactionMentions
  },
  [constants.TRIGGER_RECEIVED_CREDIT]: {
    ...mentionsPerTriggerGroup1,
    ...budgetTransactionMentions
  },
  [constants.TRIGGER_REDEEM]: mentionsPerTriggerGroup1,
  [constants.TRIGGER_SHOPPING_CART]: mentionsPerTriggerGroup1,
  [constants.TRIGGER_TAG_OPERATION]: mentionsPerTriggerGroup1,
  [constants.TRIGGER_SMS_UNSUBSCRIBE]: mentionsPerTriggerGroup1,
  [constants.TRIGGER_EMAIL_UNSUBSCRIBE]: mentionsPerTriggerGroup1,
  [constants.TRIGGER_UNTAG_OPERATION]: mentionsPerTriggerGroup1,
  [constants.TRIGGER_UPDATE_MEMBERSHIP]: {
    ...membershipByRegFormMentions,
    ...membershipAlwaysShowMentions,
    ...membershipAddressMentions,
    ...updateMembershipMentions
  },
  [constants.TRIGGER_USED_POINTS]: {
    ...mentionsPerTriggerGroup1,
    ...usedPointsTransactionMentions
  },
  [constants.TRIGGER_USED_CREDIT]: {
    ...mentionsPerTriggerGroup1,
    ...budgetTransactionMentions
  },
  [constants.TRIGGER_ONE_TIME_ACTION]: mentionsPerTriggerGroup1,
  [constants.TRIGGER_JOINED_CLUB_WITH_REFERRAL_CODE]: joinClubMentions,
  [constants.TRIGGER_USED_MY_REFERRAL_CODE]: mentionsPerTriggerGroup1,
  [constants.TRIGGER_REFERRED_FRIEND_PURCHASE]: mentionsPerTriggerGroup1,
  [constants.TRIGGER_CONSENTED_TO_TERMS_AND_CONDITIONS]: mentionsPerTriggerGroup1,
  [constants.TRIGGER_ASK_TO_BE_FORGOTTEN]: mentionsPerTriggerGroup1,
  [constants.TRIGGER_MEMBER_HAS_ANNIVERSARY]: mentionsPerTriggerGroup1,
  [constants.TRIGGER_MEMBER_HAS_BIRTHDAY]: mentionsPerTriggerGroup1,
  [constants.TRIGGER_CLUB_MEMBER_ANNIVERSARY]: mentionsPerTriggerGroup1,
  [constants.TRIGGER_PUNCH_CARD]: mentionsPerTriggerGroup1,
  [constants.TRIGGER_SHARE_BUDGET]: {
    ...membershipByRegFormMentions,
    ...membershipAlwaysShowMentions,
    ...membershipAddressMentions,
    ...fullShareBudgetMentions
  },
  [constants.TRIGGER_USER_CLAIMED_SHARED_BUDGET]: {
    ...membershipByRegFormMentions,
    ...membershipAlwaysShowMentions,
    ...membershipAddressMentions,
    ...shareBudgetMentions
  },
  [constants.TRIGGER_NOTIFY_USER_SHARED_BUDGET_DONE]: {
    ...membershipByRegFormMentions,
    ...membershipAlwaysShowMentions,
    ...membershipAddressMentions,
    ...shareBudgetMentions
  },
  [constants.TRIGGER_MICRO_CAMPAIGN]: mentionsPerTriggerGroup1
};

const emailActionMentions = {
  ...{
    [constants.MENTION_EMAIL_ACTION_CATEGORY]: [
      mentions.comoMemberIdMentionForEmailAction,
      mentions.totalSumMention,
      mentions.branchIdMention,
      mentions.addressZipCodeMention,
      mentions.expirationDateMention,
      mentions.favoriteBranchIdMention,
      mentions.homeBranchIdMention,
      mentions.commonExtIDMention,
      mentions.pointsMention,
      mentions.budgetWeightedMention,
    ]
  },
  ...membershipByRegFormMentions,
/*  ...pointsTransactionMentions,
  ...budgetTransactionMentions*/
};

const mentionsMembershipSubCategories = [
  constants.MENTION_MEMBERSHIP_BY_REG_FORM_CATEGORY,
  constants.MENTION_MEMBERSHIP_ADDRESS_CATEGORY,
  constants.MENTION_MEMBERSHIP_ALWAYS_SHOW_CATEGORY
];

const membershipByRegFormConditions = {
  [activityConstants.CONDITIONS_MEMBERSHIP_BY_REG_FORM_CATEGORY]: [
    schemaConditions.membership_gender,
    schemaConditions.membership_genericString1,
    schemaConditions.membership_genericString2,
    schemaConditions.membership_genericString3,
    schemaConditions.membership_genericString4,
    schemaConditions.membership_genericString5,
    schemaConditions.membership_genericString6,
    schemaConditions.membership_genericString7,
    schemaConditions.membership_genericString8,
    schemaConditions.membership_genericString9,
    schemaConditions.membership_genericString10,
    schemaConditions.membership_genericInteger1,
    schemaConditions.membership_genericInteger2,
    schemaConditions.membership_genericInteger3,
    schemaConditions.membership_genericInteger4,
    schemaConditions.membership_genericInteger5,
    schemaConditions.membership_genericInteger6,
    schemaConditions.membership_genericInteger7,
    schemaConditions.membership_genericInteger8,
    schemaConditions.membership_genericInteger9,
    schemaConditions.membership_genericInteger10,
    schemaConditions.membership_genericCheckBox1,
    schemaConditions.membership_genericCheckBox2,
    schemaConditions.membership_genericCheckBox3,
    schemaConditions.membership_genericCheckBox4,
    schemaConditions.membership_genericCheckBox5,
    schemaConditions.membership_genericCheckBox6,
    schemaConditions.membership_genericCheckBox7,
    schemaConditions.membership_genericCheckBox8,
    schemaConditions.membership_genericCheckBox9,
    schemaConditions.membership_genericCheckBox10,
    schemaConditions.membership_global_condition_genericDate1,
    schemaConditions.membership_global_condition_genericDate2,
    schemaConditions.membership_global_condition_genericDate3,
    schemaConditions.membership_global_condition_genericDate4,
    schemaConditions.membership_global_condition_genericDate5,
    schemaConditions.membership_global_condition_genericDate6,
    schemaConditions.membership_global_condition_genericDate7,
    schemaConditions.membership_global_condition_genericDate8,
    schemaConditions.membership_global_condition_genericDate9,
    schemaConditions.membership_global_condition_genericDate10,
    schemaConditions.membership_favoriteBranchID,
    schemaConditions.membership_homeBranchID,
    schemaConditions.membership_birthday,
    schemaConditions.membership_birthdayMonth,
    schemaConditions.membership_birthdayMonthAndDay,
    schemaConditions.membership_anniversary,
    schemaConditions.membership_anniversaryMonth,
    schemaConditions.membership_expirationDate,
    schemaConditions.membership_addressCity,
    schemaConditions.membership_addressState,
    schemaConditions.membership_addressCountry,
    schemaConditions.membership_addressHome
  ]
};

// all possible membership fields, used by updateMembership trigger
const allMembershipConditions = {
  [activityConstants.CONDITIONS_ALL_MEMBERSHIP_BY_REG_FORM_CATEGORY]: [
    schemaConditions.membership_firstName,
    schemaConditions.membership_lastName,
    schemaConditions.membership_phoneNumber,
    schemaConditions.membership_govId,
    schemaConditions.membership_email,
    schemaConditions.membership_extClubMemberID,
    schemaConditions.membership_birthday,
    schemaConditions.membership_birthdayMonth,
    schemaConditions.membership_birthdayMonthAndDay,
    schemaConditions.membership_gender,
    schemaConditions.membership_genericString1,
    schemaConditions.membership_genericString2,
    schemaConditions.membership_genericString3,
    schemaConditions.membership_genericString4,
    schemaConditions.membership_genericString5,
    schemaConditions.membership_genericString6,
    schemaConditions.membership_genericString7,
    schemaConditions.membership_genericString8,
    schemaConditions.membership_genericString9,
    schemaConditions.membership_genericString10,
    schemaConditions.membership_genericCheckBox1,
    schemaConditions.membership_genericCheckBox2,
    schemaConditions.membership_genericCheckBox3,
    schemaConditions.membership_genericCheckBox4,
    schemaConditions.membership_genericCheckBox5,
    schemaConditions.membership_genericCheckBox6,
    schemaConditions.membership_genericCheckBox7,
    schemaConditions.membership_genericCheckBox8,
    schemaConditions.membership_genericCheckBox9,
    schemaConditions.membership_genericCheckBox10,
    schemaConditions.membership_genericInteger1,
    schemaConditions.membership_genericInteger2,
    schemaConditions.membership_genericInteger3,
    schemaConditions.membership_genericInteger4,
    schemaConditions.membership_genericInteger5,
    schemaConditions.membership_genericInteger6,
    schemaConditions.membership_genericInteger7,
    schemaConditions.membership_genericInteger8,
    schemaConditions.membership_genericInteger9,
    schemaConditions.membership_genericInteger10,
    schemaConditions.membership_genericDate1,
    schemaConditions.membership_genericDate2,
    schemaConditions.membership_genericDate3,
    schemaConditions.membership_genericDate4,
    schemaConditions.membership_genericDate5,
    schemaConditions.membership_genericDate6,
    schemaConditions.membership_genericDate7,
    schemaConditions.membership_genericDate8,
    schemaConditions.membership_genericDate9,
    schemaConditions.membership_genericDate10,
    schemaConditions.membership_anniversary,
    schemaConditions.membership_anniversaryMonth,
    schemaConditions.membership_memberID,
    schemaConditions.membership_addressCity,
    schemaConditions.membership_homeBranchID,
    schemaConditions.membership_addressHome,
    schemaConditions.membership_addressFloor,
    schemaConditions.membership_addressStreet,
    schemaConditions.membership_addressLine1,
    schemaConditions.membership_addressLine2,
    schemaConditions.membership_addressState,
    schemaConditions.membership_addressZipCode,
    schemaConditions.membership_addressCountry,
    schemaConditions.membership_expirationDate,
    schemaConditions.membership_favoriteBranchID
  ]
};

const membershipAlwaysShowConditions = {
  [activityConstants.CONDITIONS_MEMBERSHIP_ALWAYS_SHOW]: [
    schemaConditions.membership_allowEmail,
    schemaConditions.membership_allowSMS,
    schemaConditions.membership_budgetWeighted,
    schemaConditions.membership_locationEnabled,
    schemaConditions.membership_membershipStatus,
    schemaConditions.membership_mobileAppUsed,
    schemaConditions.membership_points,
    schemaConditions.membership_accumulated_points,
    schemaConditions.membership_accumulated_budgetWeighted,
    schemaConditions.membership_pushNotificationEnabled,
    schemaConditions.membership_tag,
    schemaConditions.membership_hasConsent,
    schemaConditions.membership_rfm,
    schemaConditions.membership_lastVisit,
    schemaConditions.membership_preferredLocation,
    schemaConditions.membership_preferredLocationsGroup,
  ]
};

const membershipOneTimeConditions = {
  [activityConstants.CONDITIONS_MEMBERSHIP_BY_REG_FORM_CATEGORY]: [
    schemaConditions.membership_caseInsensitive_firstName,
    schemaConditions.membership_caseInsensitive_lastName,
    schemaConditions.membership_hasConsent,
    ...membershipByRegFormConditions[activityConstants.CONDITIONS_MEMBERSHIP_BY_REG_FORM_CATEGORY]
  ]
};

const membershipFilterMemberConditions = {
  [activityConstants.CONDITIONS_MEMBERSHIP_BY_REG_FORM_CATEGORY]: [
    schemaConditions.membership_caseInsensitive_firstName,
    schemaConditions.membership_caseInsensitive_lastName,
    ...membershipByRegFormConditions[activityConstants.CONDITIONS_MEMBERSHIP_BY_REG_FORM_CATEGORY]
  ]
};

function membershipAlwaysShowConditionsExcludeConsent() {
  return {
    [activityConstants.CONDITIONS_MEMBERSHIP_ALWAYS_SHOW]: membershipAlwaysShowConditions[activityConstants.CONDITIONS_MEMBERSHIP_ALWAYS_SHOW]
      .filter((c) => c.key !== constants.CONTEXT_MEMBERSHIP_HAS_CONSENT)
  };
}

const membershipGlobalConditionsPerTrigger = {
  [constants.TRIGGER_BEACON_SIGNAL]: {
    ...membershipFilterMemberConditions,
    ...membershipAlwaysShowConditions
  },
  [constants.TRIGGER_CLAIM]: {
    ...membershipFilterMemberConditions,
    ...membershipAlwaysShowConditions
  },
  [constants.TRIGGER_EXCEEDED_PUNCH]: {
    ...membershipFilterMemberConditions,
    ...membershipAlwaysShowConditions
  },
  [constants.TRIGGER_EXTERNAL_EVENT]: {
    ...membershipFilterMemberConditions,
    ...membershipAlwaysShowConditions
  },
  [constants.TRIGGER_FORM_FILLED]: {
    ...membershipFilterMemberConditions,
    ...membershipAlwaysShowConditions
  },
  [constants.TRIGGER_PAYS_WITH_POINTS_AT_POS]: {
    ...membershipFilterMemberConditions,
    ...membershipAlwaysShowConditions
  },
  [constants.TRIGGER_PAYS_WITH_CREDIT_AT_POS]: {
    ...membershipFilterMemberConditions,
    ...membershipAlwaysShowConditions
  },
  [constants.TRIGGER_PAYS_WITH_APP_CREDIT_CARD]: {
    ...membershipFilterMemberConditions,
    ...membershipAlwaysShowConditions
  },
  [constants.TRIGGER_TOP_UP]: {
    ...membershipFilterMemberConditions,
    ...membershipAlwaysShowConditions
  },
  [constants.TRIGGER_PAYS_WITH_APP]: {
    ...membershipFilterMemberConditions,
    ...membershipAlwaysShowConditions
  },
  [constants.TRIGGER_PAYS_WITH_PAYPAL]: {
    ...membershipFilterMemberConditions,
    ...membershipAlwaysShowConditions
  },
  [constants.TRIGGER_APP_CREDIT_CARD_PAYMENT_IS_DECLINED]: {
    ...membershipFilterMemberConditions,
    ...membershipAlwaysShowConditions
  },
  [constants.TRIGGER_PAYPAL_PAYMENT_IS_DECLINED]: {
    ...membershipFilterMemberConditions,
    ...membershipAlwaysShowConditions
  },
  [constants.TRIGGER_JOINED_APP]: {
    ...membershipFilterMemberConditions,
    ...membershipAlwaysShowConditions
  },
  [constants.TRIGGER_JOINED_CLUB]: {
    ...membershipFilterMemberConditions,
    ...membershipAlwaysShowConditionsExcludeConsent()
  },
  [constants.TRIGGER_ONE_TIME_CODE]: {
    ...membershipFilterMemberConditions,
    ...membershipAlwaysShowConditions
  },
  [constants.TRIGGER_OPENED_CLUB]: {
    ...membershipFilterMemberConditions,
    ...membershipAlwaysShowConditions
  },
  [constants.TRIGGER_PUNCH]: {
    ...membershipFilterMemberConditions,
    ...membershipAlwaysShowConditions
  },
  [constants.TRIGGER_PURCHASE_ANALYZED]: {
    ...membershipFilterMemberConditions,
    ...membershipAlwaysShowConditions
  },
  [constants.TRIGGER_START_PURCHASE]: {
    ...membershipFilterMemberConditions,
    ...membershipAlwaysShowConditions
  },
  [constants.TRIGGER_PURCHASED_ASSET]: {
    ...membershipFilterMemberConditions,
    ...membershipAlwaysShowConditions
  },
  [constants.TRIGGER_RECEIVED_ASSET]: {
    ...membershipFilterMemberConditions,
    ...membershipAlwaysShowConditions
  },
  [constants.TRIGGER_RECEIVED_POINTS]: {
    ...membershipFilterMemberConditions,
    ...membershipAlwaysShowConditions
  },
  [constants.TRIGGER_RECEIVED_CREDIT]: {
    ...membershipFilterMemberConditions,
    ...membershipAlwaysShowConditions
  },
  [constants.TRIGGER_REDEEM]: {
    ...membershipFilterMemberConditions,
    ...membershipAlwaysShowConditions
  },
  [constants.TRIGGER_SHOPPING_CART]: {
    ...membershipFilterMemberConditions,
    ...membershipAlwaysShowConditions
  },
  [constants.TRIGGER_TAG_OPERATION]: {
    ...membershipFilterMemberConditions,
    ...membershipAlwaysShowConditions
  },
  [constants.TRIGGER_SMS_UNSUBSCRIBE]: {
    ...membershipFilterMemberConditions,
    ...membershipAlwaysShowConditions
  },
  [constants.TRIGGER_EMAIL_UNSUBSCRIBE]: {
    ...membershipFilterMemberConditions,
    ...membershipAlwaysShowConditions
  },
  [constants.TRIGGER_UNTAG_OPERATION]: {
    ...membershipFilterMemberConditions,
    ...membershipAlwaysShowConditions
  },
  [constants.TRIGGER_UPDATE_MEMBERSHIP]: {
    ...membershipFilterMemberConditions,
    ...membershipAlwaysShowConditions
  },
  [constants.TRIGGER_USED_POINTS]: {
    ...membershipFilterMemberConditions,
    ...membershipAlwaysShowConditions
  },
  [constants.TRIGGER_USED_CREDIT]: {
    ...membershipFilterMemberConditions,
    ...membershipAlwaysShowConditions
  },
  [constants.TRIGGER_JOINED_CLUB_WITH_REFERRAL_CODE]: {
    ...membershipFilterMemberConditions,
    ...membershipAlwaysShowConditions
  },
  [constants.TRIGGER_REFERRED_FRIEND_PURCHASE]: {
    ...membershipFilterMemberConditions,
    ...membershipAlwaysShowConditions
  },
  [constants.TRIGGER_USED_MY_REFERRAL_CODE]: {
    ...membershipFilterMemberConditions,
    ...membershipAlwaysShowConditions
  },
  [constants.TRIGGER_CONSENTED_TO_TERMS_AND_CONDITIONS]: {
    ...membershipFilterMemberConditions,
    ...membershipAlwaysShowConditions
  },
  [constants.TRIGGER_ASK_TO_BE_FORGOTTEN]: {
    ...membershipFilterMemberConditions,
    ...membershipAlwaysShowConditions
  },
  [constants.TRIGGER_MEMBER_HAS_BIRTHDAY]: {
    ...membershipFilterMemberConditions,
    ...membershipAlwaysShowConditions
  },
  [constants.TRIGGER_MEMBER_HAS_ANNIVERSARY]: {
    ...membershipFilterMemberConditions,
    ...membershipAlwaysShowConditions
  },
  [constants.TRIGGER_CLUB_MEMBER_ANNIVERSARY]: {
    ...membershipFilterMemberConditions,
    ...membershipAlwaysShowConditions
  },
  [constants.TRIGGER_DEAL]: {
    ...membershipFilterMemberConditions,
    ...membershipAlwaysShowConditionsExcludeConsent()
  },
  [constants.TRIGGER_ONE_TIME_ACTION]: {
    ...membershipFilterMemberConditions,
    ...membershipAlwaysShowConditions
  },
  [constants.TRIGGER_FILTER_MEMBERS]: {
    ...membershipFilterMemberConditions,
    ...membershipAlwaysShowConditions,
  },
  [constants.TRIGGER_GIFT]: {
    ...membershipFilterMemberConditions,
    ...membershipAlwaysShowConditions
  },
  [constants.TRIGGER_SHARE_BUDGET]: {
    ...membershipFilterMemberConditions,
    ...membershipAlwaysShowConditions
  },
  [constants.TRIGGER_PROMO_CODE]: {
    [activityConstants.CONDITIONS_MEMBERSHIP_BY_REG_FORM_CATEGORY]: [],
    [activityConstants.CONDITIONS_MEMBERSHIP_ALWAYS_SHOW]: []
  },
  [constants.TRIGGER_PUNCH_CARD]: {
    ...membershipFilterMemberConditions,
    ...membershipAlwaysShowConditions
  },
  [constants.NO_TRIGGER]: {
    ...membershipFilterMemberConditions,
    ...membershipAlwaysShowConditions
  }
};

/**
 * update alwaysShow conditions list in updateMembership context
 * @returns {{membershipAlwaysShow: *[]}}
 */
function getAlwaysShowForUpdateMembership() {
  const newMembershipAlwaysShowConditions = cloneDeep(membershipAlwaysShowConditions);
  newMembershipAlwaysShowConditions.membershipAlwaysShow = newMembershipAlwaysShowConditions.membershipAlwaysShow
    .filter((c) => (c.key !== constants.CONTEXT_MEMBERSHIP_TAG &&
      c.key !== constants.CONTEXT_MEMBERSHIP_BUDGETWEIGHTED &&
      c.key !== constants.CONTEXT_MEMBERSHIP_FAVORITE_BRANCH_ID &&
      c.key !== constants.CONTEXT_MEMBERSHIP_STATUS &&
      c.key !== constants.CONTEXT_MEMBERSHIP_POINTS &&
      c.key !== constants.CONTEXT_MEMBERSHIP_STORED_CREDIT_CARD &&
      c.key !== constants.CONTEXT_MEMBERSHIP_RFM &&
      c.key !== constants.CONTEXT_MEMBERSHIP_LAST_VISIT &&
      c.key !== constants.CONTEXT_MEMBERSHIP_PREFERRED_LOCATION &&
      c.key !== constants.CONTEXT_MEMBERSHIP_SECOND_PREFERRED_LOCATION &&
      c.key !== constants.CONTEXT_MEMBERSHIP_ACCUMULATED_POINTS &&
      c.key !== constants.CONTEXT_MEMBERSHIP_ACCUMULATED_BUDGET_WEIGHTED
    ));
  newMembershipAlwaysShowConditions.membershipAlwaysShow = newMembershipAlwaysShowConditions.membershipAlwaysShow.map((c) => {
    const {
      group,
      ...cleanCondition
    } = c;
    return cleanCondition;
  });
  newMembershipAlwaysShowConditions.membershipAlwaysShow.push(schemaConditions.codeTag);
  newMembershipAlwaysShowConditions.membershipAlwaysShow.push(schemaConditions.operation);
  newMembershipAlwaysShowConditions.membershipAlwaysShow.push(schemaConditions.probability);
  return newMembershipAlwaysShowConditions;
}

/**
 * remove group property from membership conditions in UpdateMembership context
 * @returns {*}
 */
function getUpdatedMembershipConditions() {
  const updatedMembershipConditions = cloneDeep(allMembershipConditions);
  updatedMembershipConditions.allMembershipConditions = updatedMembershipConditions.allMembershipConditions.map((c) => {
    // noinspection JSUnusedLocalSymbols
    const {
      group,
      ...cleanCondition
    } = c;
    return cleanCondition;
  });
  return updatedMembershipConditions;
}

const updateMembershipConditions = {
  ...getUpdatedMembershipConditions(),
  ...getAlwaysShowForUpdateMembership()
};

const globalConditions = {
  [activityConstants.MEMBERSHIP_GLOBAL_CONDITIONS]: membershipGlobalConditionsPerTrigger,
  [activityConstants.DATETIME_GLOBAL_CONDITIONS]: {
    [activityConstants.DATES_RANGE_CONDITION]: schemaConditions.datesRange,
    [activityConstants.DAYS_TIMES_CONDITION]: schemaConditions.timeRangeInDaysOfWeek
  }
};

const oneTimeGlobalConditions = {
  [activityConstants.MEMBERSHIP_GLOBAL_CONDITIONS]: {
    ...membershipByRegFormConditions,
    ...membershipAlwaysShowConditions,
    ...membershipOneTimeConditions
  }
};
const dataValueTypeDefaults = {
  [constants.DATA_VALUE_TYPE_INTEGER]: 0,
  [constants.DATA_VALUE_TYPE_STRING]: '',
  [constants.DATA_VALUE_TYPE_STRING_ARRAY]: [],
  [constants.DATA_VALUE_TYPE_BOOLEAN]: 'false' // enforced by server
};

const punchCommunicationTriggers = [schemaTriggers.punch, schemaTriggers.punchMiddlePunch, schemaTriggers.punchLastPunch, schemaTriggers.receivedAsset, schemaTriggers.redeem];
const punchCommunicationActions = {
  key: constants.COMMUNICATION_GROUP,
  actionFields: [
    {
      key: constants.SEND_PERSONAL_PUSH_ACTION,
      features: [appConstants.FEATURE_ESSENTIALS_OFF]
    },
    {
      key: constants.SEND_PERSONAL_WEB_PUSH_ACTION,
      features: [appConstants.FEATURE_ESSENTIALS_OFF]
    },
    {
      key: constants.SEND_MESSAGE_ACTION,
      features: [appConstants.FEATURE_ESSENTIALS_OFF]
    }]
};

const schema = {
  [activityConstants.TRIGGER_GROUPS]: triggers,
  [activityConstants.CONDITIONS_PER_TRIGGERS]: conditionsPerTriggers,
  [activityConstants.USER_ACTION_FILTERS]: filterUserActions,
  [activityConstants.CONDITIONS_PER_USER_ACTION]: conditionsPerUserAction,
  [activityConstants.ACTIONS_PER_TRIGGERS]: actionsPerTriggers,
  [activityConstants.MENTIONS_PER_TRIGGERS]: mentionsPerTriggers,
  [activityConstants.GLOBAL_CONDITIONS]: globalConditions,
  [activityConstants.ONE_TIME_CONDITIONS]: oneTimeGlobalConditions,
  [activityConstants.EMAIL_ACTION_MENTIONS]: emailActionMentions,
  [activityConstants.UPDATE_MEMBERSHIP_CONDITIONS]: updateMembershipConditions,
  [activityConstants.DATA_VALUE_TYPE_DEFAULTS]: dataValueTypeDefaults,
  [constants.MENTIONS_CATEGORIES_ORDER]: mentionsCategoriesOrder,
  [constants.MENTIONS_MEMBERSHIP_SUB_CATEGORIES]: mentionsMembershipSubCategories,
  [constants.REMOVED_GLOBAL_CONDITIONS_PER_TRIGGER]: removedGlobalConditionsPerTrigger,
  [constants.PUNCH_CARD_REQUIRED_PUNCHES_OPTIONS]: [2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
  [activityConstants.PUNCH_COMMUNICATION_TRIGGERS]: punchCommunicationTriggers,
  [activityConstants.PUNCH_COMMUNICATION_ACTIONS]: punchCommunicationActions,
};

export const redeemableActivities = [
  activityConstants.ACTIVITY_TYPE_DEAL,
  activityConstants.ACTIVITY_TYPE_PUNCH_CARD,
  activityConstants.ACTIVITY_TYPE_GIFT,
  activityConstants.ACTIVITY_TYPE_PROMO_CODE,
];

export const redeemableRuleAndItsTriggers = [
  activityConstants.ACTIVITY_TYPE_RULE,
  constants.TRIGGER_PURCHASE_ANALYZED
];

export default schema;
