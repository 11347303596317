import axios from 'axios';
import * as queryStringUtils from '../utils/queryStringUtils';

axios.defaults.withCredentials = false;

const initRequester = (hub1Path, increaseCounter, decreaseCounter, push) => {

  axios.interceptors.response.use(responseSuccess, responseError);

  function responseSuccess(res) {
    decreaseCounter();
    return res;
  }

  async function responseError(err) {
    const status = err.response.status;

    // Avoid Recursive Calls: Ensure the logging API call itself does not trigger the interceptor
    if (err.response.config.url === '/api/auth/logout/log') {
      return Promise.reject(err);
    }

    // Add a 401 response interceptor
    if (status === 401) {
      const redirectUrl = window.location.pathname + window.location.search;

      const isDev = process.env.NODE_ENV === 'development';
      const isHub2Login = localStorage.getItem('isHub2Login') === 'true';

      // Log the error details by calling an error logging API
      try {
        const logErrorPayload = {
          message: err.message,
          status: err.response.status,
          api: err.response.config.url,
          method: err.response.config.method,
          environment: process.env.NODE_ENV,
          isHub2Login,
          redirectUrl
        };

        await axios.post('/api/auth/logout/log', logErrorPayload);
      } catch (loggingError) {
        console.error('Error while logging:', loggingError);
      }

      return window.location.replace(isDev || isHub2Login ? `/login?redirect=${redirectUrl}` : `${hub1Path}/dashboard/#/login`);
    }

    if (status === 403) {
      push({
        pathname: '/forbidden',
        search: location.search,
      });
    }
    if (err.request.responseURL.includes('ApplicationData') && status === 500) {
      push({
        pathname: '/somethingsWentWrong',
        search: location.search,
      });
    }

    decreaseCounter();
    return Promise.reject(err);
  }

  // Add a request interceptor
  axios.interceptors.request.use(requestSuccess, requestError);

  function requestSuccess(config) {
    const { 'location_id': locationId } = queryStringUtils.parse(location.search);
    if (locationId) {
      config.headers['hub-auth-location-id'] = Number(locationId);
    }
    increaseCounter();
    return config;
  }

  function requestError() {
  }

};

export default initRequester;
