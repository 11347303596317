// Actions
export const GET_CONFIGURATION = 'app/customerPortal/GET_CONFIGURATION';
export const SET_CUSTOMER_PORTAL_DATA_IS_LOADING = 'app/customerPortal/SET_CUSTOMER_PORTAL_DATA_IS_LOADING';
export const GET_CUSTOMER_PORTAL_SUCCESS = 'app/customerPortal/GET_CUSTOMER_PORTAL_SUCCESS';
export const VALIDATION_ERROR = 'app/customerPortal/VALIDATION_ERROR';
export const REMOVE_VALIDATION_ERROR = 'app/customerPortal/REMOVE_VALIDATION_ERROR';
export const RESET_ERRORS = 'app/customerPortal/RESET_ERRORS';
export const CONFIGURATION_SAVE_SUCCESS = 'app/customerPortal/CONFIGURATION_SAVE_SUCCESS';
export const GET_CUSTOMER_PORTAL_ERROR = 'app/customerPortal/GET_CUSTOMER_PORTAL_ERROR';
export const GET_CUSTOMER_PORTAL_SAVING_ERROR = 'app/customerPortal/GET_CUSTOMER_PORTAL_SAVING_ERROR';
export const FIELD_CHANGED = 'app/customerPortal/FIELD_CHANGED';
export const FIELD_CHANGED_TRANSLATION = 'app/customerPortal/FIELD_CHANGED_TRANSLATION';
export const THEME_FIELD_CHANGE = 'app/customerPortal/THEME_FIELD_CHANGE';
export const MENU_FIELD_CHANGE = 'app/customerPortal/MENU_FIELD_CHANGE';
export const MENU_FIELD_CHANGE_TRANSLATION = 'app/customerPortal/MENU_FIELD_CHANGE_TRANSLATION';
export const MENU_FIELD_REDIRECT_ROUTE_CHANGE = 'app/customerPortal/MENU_FIELD_REDIRECT_ROUTE_CHANGE';
export const ADD_CUSTOM_MENU_ITEM = 'app/customerPortal/ADD_CUSTOM_MENU_ITEM';
export const ADD_NEW_COUNTRY = 'app/customerPortal/ADD_NEW_COUNTRY';
export const REMOVE_CUSTOM_MENU_ITEM = 'app/customerPortal/REMOVE_CUSTOM_MENU_ITEM';
export const ADD_LANGUAGE = 'app/customerPortal/ADD_LANGUAGE';
export const ADD_COUNTRIES = 'app/customerPortal/ADD_COUNTRIES';
export const REMOVE_COUNTRY = 'app/customerPortal/REMOVE_COUNTRY';
export const REMOVE_DEFAULT_LANGUAGE = 'app/customerPortal/REMOVE_DEFAULT_LANGUAGE';
export const CHANGE_DEFAULT_COUNTRY = 'app/customerPortal/CHANGE_DEFAULT_COUNTRY';
export const CHANGE_REMOVE_LEADING_ZERO = 'app/customerPortal/CHANGE_REMOVE_LEADING_ZERO';
export const CHANGE_USE_COUNTRY_CODE = 'app/customerPortal/CHANGE_USE_COUNTRY_CODE';
export const ADD_DEFAULT_LANGUAGE = 'app/customerPortal/ADD_DEFAULT_LANGUAGE';
export const ADD_DEFAULT_COUNTRY = 'app/customerPortal/ADD_DEFAULT_COUNTRY';
export const SAVE_CONFIGURATION = 'app/customerPortal/SAVE_CONFIGURATION';
export const SAVE_TOAST_OPENED = 'app/customerPortal/SAVE_TOAST_OPENED';
export const SAVING_CONFIGURATION = 'app/customerPortal/SAVING_CONFIGURATION';

// State path
export const CUSTOMER_PORTAL_DATA_LOADED = 'CustomerPortalDataLoaded';
export const CUSTOMER_PORTAL = 'customerPortal';
export const CUSTOMER_PORTAL_DATA = 'data';
export const CUSTOMER_PORTAL_COUNTRIES = 'countriesData';
export const HAS_SUBSCRIPTION = 'hasSubscription';
export const CUSTOMER_PORTAL_LANGUAGES = 'languagesData';
export const IS_BUSINESS_WALLET_PASS_ACTIVE = 'isBusinessWalletPassActive';
export const CUSTOMER_PORTAL_DATA_ERROR = 'customerPortalError';
export const CUSTOMER_PORTAL_DATA_ERROR_MESSAGE = 'customerPortalErrorMessage';
export const DATA_MENU = 'menu';
export const COUNTRIES_DATA = 'countries';
export const LANGUAGE_DATA = 'languages';
export const DEFAULT_LANGUAGE_DATA = 'defaultLanguage';
export const DEFAULT_COUNTRY_DATA = 'defaultCountry';
export const REMOVE_LEADING_ZERO = 'removeLeadingZero';
export const USE_COUNTRY_CODE = 'useCountryCode';
export const SAVED_CONFIGURATION = 'isSaving';
export const TOAST_IS_OPEN = 'toastIsOpen';
export const ERRORS = 'errors';

// fields and values
export const LOGIN_SCREEN = 'loginScreen';
export const SPLASH_SCREEN = 'splashScreen';
export const MENU_REWARDS = 'Rewards';
export const SUBSCRIPTION = 'Subscription';
export const WALLET_PASS = 'Wallet Pass';
export const MENU_ACTIVITY = 'Activity';
export const MENU_USER_PROFILE = 'User Profile';
export const MENU_POINT_SHOP = 'Point Shop';
export const FRIEND_REFERRAL = 'Friend Referral';
export const FRIEND_REFERRAL_TYPE = 'friendReferral';
export const ACTIVITY = 'activity';
export const ACTIVITY_LOGO_IMAGE_ID = 'activityLogoImageId';
export const USER_PROFILE = 'userProfile';
export const CURRENCY_SYMBOL_LOCATION_BEFORE_VALUE = 'before';
export const CURRENCY_SYMBOL_LOCATION_AFTER_VALUE = 'after';
export const BUSINESS_WALLET_NONE = 'None';
export const BUSINESS_WALLET_CREDIT = 'Credit';
export const BUSINESS_WALLET_POINTS = 'Points';
export const BUSINESS_WALLET_BOTH_CREDIT_AND_POINTS = 'Both Points & Credit';
export const NAV_BAR_NAVIGATION_ACCESS = 'navBarNavigationAccess';
export const BALANCE_MONETARY = 'Monetary';
export const BALANCE_NON_MONETARY = 'Non-Monetary';

export const REWARDS_DEFAULT_ACTIVE_PAGE = 'rewardsDefaultActivePage';
export const REWARDS_ACTIVE_PAGE_ALL_REWARDS_VALUE = 'All';
export const REWARDS_ACTIVE_PAGE_ALL_REWARDS = 'All Rewards';
export const REWARDS_ACTIVE_PAGE_ACTIVE_VALUE = 'Active';
export const REWARDS_ACTIVE_PAGE_ACTIVE = 'Active';
export const REWARDS_ACTIVE_PAGE_FUTURE_REWARDS_VALUE = 'NotYet';
export const REWARDS_ACTIVE_PAGE_FUTURE_REWARDS = 'Future Rewards';
export const REWARDS_ACTIVE_PAGE_REDEEMED_EXPIRED_VALUE = 'Redeemed';
export const REWARDS_ACTIVE_PAGE_REDEEMED_EXPIRED = 'Redeemed / Expired';


// fields
export const BUSINESS_NAME = 'businessName';
export const POINT_NAME = 'pointName';
export const CREDIT_NAME = 'creditName';
export const POINT_NAME_PLURAL = 'pointsNamePlural';
export const ENABLE_CUSTOMER_PORTAL = 'isCustomerPortalEnable'
export const SUB_DOMAIN_NAME = 'subDomainName';
export const SIGN_IN_PAGE_CTA = 'signInPageCta';
export const SIGN_UP_PAGE_CTA = 'signUpPageCta';
export const BEFORE_LOGIN_CTA = 'beforeLoginCta';
export const AFTER_LOGIN_CTA = 'afterLoginCta';
export const AFTER_LOGIN_TEXT = 'afterLoginText';
export const CREDIT_NAME_PLURAL = 'creditNamePlural';
export const VIDEO_EMBED_URL = 'videoEmbedUrl';
export const FAV_ICON_ID = 'favIconId';
export const EXPIRED_REWARD_IMAGE_ID = 'expiredRewardImageId';
export const REDEEMED_REWARD_IMAGE_ID = 'redeemedRewardImageId';
export const NO_REWARD_IMAGE_ID = 'noRewardImageId';
export const NO_REWARD_TEXT = 'noRewardText';
export const PROFILE_LOGO_IMAGE_ID = 'logoImageId';
export const PROFILE_BACKGROUND_IMAGE_ID = 'backgroundImageId';
export const PROFILE_UPDATE_DETAILS_IMAGE_ID = 'updateDetailsImageId';
export const PROFILE_GENERATE_ID_CODE_IMAGE_ID = 'generateIdCodeImageId';
export const PROFILE_DATA_AND_LEGAL_IMAGE_ID = 'dataAndLegalImageId';
export const PROFILE_REWARDS_FOR_YOU_IMAGE_ID = 'rewardsForYouImageId';
export const POINTS_PURCHASED_SUCCESS_IMAGE_ID = 'pointsPurchaseSuccessImageId';
export const DEFAULT_THEME = 'defaultTheme';
export const ONLY_FOR_LOGGED_IN_USER = 'onlyForLoggedInUser';
export const ALLOW_QR_CODE = 'allowQrCode';
export const ALLOW_VERIFICATION_QR_CODE = 'allowVerificationQrCode';
export const ALLOW_GIFTS_ICON = 'allowGiftsIcon';
export const SHOW_POINTS_ON_NAVBAR = 'showPointsOnNavBar';
export const BUSINESS_WALLET = 'businessWallet';
export const BUTTON_STYLE = 'buttonStyle';
export const IS_WALLET_PASS_ACTIVE = 'isWalletPassActive';
export const SHOW_USER_GREETINGS = 'showUserGreetings';
export const DARK_THEME = 'darkTheme';
export const LIGHT_THEME = 'lightTheme';
export const LOGIN_SCREEN_TEXT = 'loginScreenText';
export const LOGIN_SCREEN_IMAGE_ID = 'loginScreenImageId';
export const SPLASH_SCREEN_TEXT = 'splashScreenText';
export const SPLASH_SCREEN_IMAGE_ID = 'splashScreenImageId';
export const LOGO_IMAGE_ID = 'logoImageId';
export const THEME_PRIMARY = 'primary';
export const THEME_SECONDARY = 'secondary';
export const THEME_ACCENT = 'accent';
export const THEME_NEUTRAL = 'neutral';
export const THEME_BASE200 = 'base200';
export const THEME_BASE100 = 'base100';
export const THEME_BASE300 = 'base300';
export const THEME_INFO = 'info';
export const THEME_SUCCESS = 'success';
export const THEME_ERROR = 'error';
export const THEME_LINES = 'lines';
export const THEME_WARNING = 'warning';
export const THEME_FONT_CONTRAST = 'fontContrast';
export const MENU_DEFAULT_NAME = 'defaultName';
export const MENU_IS_ACTIVE = 'isActive';
export const MENU_NAME = 'name';
export const MENU_URL = 'url';
export const MENU_ORDER = 'menuOrder';
export const MENU_ALLOW_PUNCH_CARD_REWARDS = 'allowPunchCardRewards';
export const MENU_ALLOW_FEEDBACK = 'allowFeedback';
export const DEFAULT_PAGE = 'defaultPage';
export const ALLOW_DYNAMIC_URL = 'allowDynamicUrl';
export const DEMO_MODE_TOGGLE = 'demoModeToggle';
export const FRIEND_REFERRAL_PRE_HEAD_LINE = 'preHeadLine';
export const FRIEND_REFERRAL_MAIN_HEAD_LINE = 'mainHeadLine';
export const FRIEND_REFERRAL_SUB_HEAD_LINE = 'subHeadLine';
export const FRIEND_REFERRAL_HOW_IT_WORKS_TEXT = 'howItWorksText';
export const FRIEND_REFERRAL_STEP_1 = 'step1';
export const FRIEND_REFERRAL_STEP_2 = 'step2';
export const FRIEND_REFERRAL_STEP_3 = 'step3';
export const FRIEND_REFERRAL_SHARE_TEXT = 'shareText';
export const FRIEND_REFERRAL_STEP_1_IMAGE_ID = 'step1ImageId';
export const FRIEND_REFERRAL_STEP_2_IMAGE_ID = 'step2ImageId';
export const FRIEND_REFERRAL_STEP_3_IMAGE_ID = 'step3ImageId';
export const FRIEND_REFERRAL_SHARE_IMAGE_ID = 'shareImageId';
export const FRIEND_REFERRAL_COPY_LINK_IMAGE_ID = 'copyLinkImageId';
export const FRIEND_REFERRAL_GET_REFERRAL_CODE_IMAGE_ID = 'getReferralCodeImageId';
export const NAVIGATE_TO_LINK = 'navigateToLink';
export const OPEN_IN_NEW_WINDOW = 'openInNewWindow';
export const OPEN_WITH_NAV_BAR = 'openWithNavBar';
export const IS_REDIRECT_ROUTE = 'isRedirectRoute';
export const MENU_IS_DEFAULT = 'isDefault';
export const CODE = 'code';
export const COUNTRY_CODE = 'countryCode';
export const INTERNATIONALIZATION_NAME = 'name';
export const VALUE = 'value';
export const TEXT = 'text';
export const DIRECTION = 'direction';
export const KEY = 'key';
export const ID = 'id';
export const UNIT_OF_MEASURE = 'unitOfMeasure';
export const CURRENCY_SYMBOL_LOCATION = 'currencySymbolLocation';
export const FEEDBACK_EMAIL = 'feedbackEmail';
export const CREDITS_BALANCE_TYPE = 'creditsBalanceType';
export const POINTS_BALANCE_TYPE = 'pointsBalanceType';
export const SHOW_VERIFICATION_CODE_ON_PROFILE = 'showVerificationIdCodeOnProfile';
export const SHOW_VERIFICATION_CODE_ON_NAVBAR = 'showVerificationIdCodeOnNavBar';
export const MENU_CUSTOM_MENU_ITEM_NAME = "Custom Menu Item";
export const TRANSLATIONS = 'translations';

export const CUSTOM_MENU_ITEM_DISPLAY_AREA_SIDEBAR_TEXT = "Sidebar Menu";
export const CUSTOM_MENU_ITEM_DISPLAY_AREA_NAVBAR_TEXT = "Profile Screen";
export const CUSTOM_MENU_ITEM_DISPLAY_AREA_BOTH_TEXT = "Both";
export const CUSTOM_MENU_ITEM_DISPLAY_AREA_SIDEBAR_VALUE = "SidebarMenu";
export const CUSTOM_MENU_ITEM_DISPLAY_AREA_NAVBAR_VALUE = "ProfileScreen";
export const CUSTOM_MENU_ITEM_DISPLAY_AREA = "displayArea";
export const FULL_REGISTRATION_FORM = 'fullRegistrationForm';
