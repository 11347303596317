/* eslint-disable no-undef */
import CopyToClipboard from '@como/web-hub-components/src/CopyToClipboard/InputWithCopyToClipboard';
import React from 'react';
import PropTypes from 'prop-types';
import QRCode from 'qrcode';
import { Grid, Header, Icon, } from 'semantic-ui-react';
import { PhonePreview } from '@como/web-hub-components';
import { injectIntl } from 'react-intl';
import * as activityConstants from '../../activity.constants';
import ToggleButton from '../../../../common/components/ToggleButton/ToggleButton';
import Tooltip from '../../../../common/components/Tooltip/Tooltip';
import withAuthorization from '../../../../common/helpers/authorization';
import { FEATURE_GIFT_LANDING_PAGE } from '../../../App/app.constants';
import InputWrapper from '../../../../common/components/FormInputFields/InputWrapper/InputWrapper';
import CheckboxWrapper from '../../../../common/components/FormInputFields/CheckboxWrapper/CheckboxWrapper';


class LandingPageTabContent extends React.PureComponent {

  static propTypes = {
    actions: PropTypes.object.isRequired,
    isActivityDraft: PropTypes.bool.isRequired,
    activityPrefix: PropTypes.string.isRequired,
    landingPageData: PropTypes.object,
    headerMsgId: PropTypes.string.isRequired,
    landingPageBaseUrl: PropTypes.string.isRequired,
    landingPageViewDirection: PropTypes.string.isRequired,
    hasFullPermission: PropTypes.func.isRequired,
    locationId: PropTypes.number.isRequired,
    locationDisplayName: PropTypes.string.isRequired,
    activityName: PropTypes.string.isRequired,
    legalSectionText: PropTypes.string,
  };

  constructor(props) {
    super(props);
    this.downloadQrCode = this.downloadQrCode.bind(this);
    const { formatMessage } = props.intl; // eslint-disable-line react/prop-types
    const landingPageEnterPhoneText = formatMessage({ id: 'activity.landing.page.defaultEnterPhoneText' });
    const landingPageSuccessHeaderText = formatMessage({ id: 'activity.landing.page.defaultSuccessHeaderText' });
    const landingPageSuccessMessageText = formatMessage({ id: 'activity.landing.page.defaultSuccessMessageText' });
    const landingPageExpiredHeaderText = formatMessage({ id: 'activity.landing.page.defaultExpiredHeaderText' });
    const landingPageExpiredMessageText = formatMessage({ id: 'activity.landing.page.defaultExpiredMessageText' });
    const landingPageExistingMemberMessageText = formatMessage({ id: 'activity.landing.page.defaultExistingMemberMessageText' });
    const landingPageExistingMemberHeaderText = formatMessage({ id: 'activity.landing.page.defaultExistingMemberHeaderText' });
    const landingPageFacebookPixel = '';
    const landingPageGooglePixel = '';
    const landingPageRedirectUrlSuccess = '';
    const landingPageRedirectUrlFailure = '';
    const previewUrl = `${this.stripTrailingSlash(props.landingPageBaseUrl)}/preview?cookieConsentGrantedExternally=true`;

    if (props.landingPageData == null) {
      this.state = {
        landingPageEnterPhoneText,
        landingPageSuccessHeaderText,
        landingPageSuccessMessageText,
        landingPageExpiredHeaderText,
        landingPageExpiredMessageText,
        landingPageExistingMemberMessageText,
        landingPageExistingMemberHeaderText,
        landingPageFacebookPixel,
        landingPageGooglePixel,
        landingPageRedirectUrlSuccess,
        landingPageRedirectUrlFailure,
        textsCollapsed: true,
        previewUrl
      };
    } else {
      this.state = {
        landingPageEnterPhoneText: props.landingPageData.get(activityConstants.LANDING_PAGE_ENTER_PHONE_TEXT) || landingPageEnterPhoneText,
        landingPageSuccessHeaderText: props.landingPageData.get(activityConstants.LANDING_PAGE_SUCCESS_HEADER_TEXT) || landingPageSuccessHeaderText,
        landingPageSuccessMessageText: props.landingPageData.get(activityConstants.LANDING_PAGE_SUCCESS_MESSAGE_TEXT) || landingPageSuccessMessageText,
        landingPageExpiredHeaderText: props.landingPageData.get(activityConstants.LANDING_PAGE_EXPIRED_HEADER_TEXT) || landingPageExpiredHeaderText,
        landingPageExpiredMessageText: props.landingPageData.get(activityConstants.LANDING_PAGE_EXPIRED_MESSAGE_TEXT) || landingPageExpiredMessageText,
        landingPageExistingMemberMessageText: props.landingPageData.get(activityConstants.LANDING_PAGE_EXISTING_MEMBER_MESSAGE_TEXT) || landingPageExistingMemberMessageText,
        landingPageExistingMemberHeaderText: props.landingPageData.get(activityConstants.LANDING_PAGE_EXISTING_MEMBER_HEADER_TEXT) || landingPageExistingMemberHeaderText,
        landingPageFacebookPixel: props.landingPageData.get(activityConstants.LANDING_PAGE_FACEBOOK_PIXEL),
        landingPageGooglePixel: props.landingPageData.get(activityConstants.LANDING_PAGE_GOOGLE_PIXEL),
        landingPageRedirectUrlSuccess: props.landingPageData.get(activityConstants.LANDING_PAGE_REDIRECT_URL_SUCCESS),
        landingPageRedirectUrlFailure: props.landingPageData.get(activityConstants.LANDING_PAGE_REDIRECT_URL_FAILURE),
        textsCollapsed: true,
        previewUrl,
        qrComputedUrl: props.landingPageData.get(activityConstants.LANDING_PAGE_SHORTENED_URL) || `${this.stripTrailingSlash(props.landingPageBaseUrl)}/${props.landingPageData.get(activityConstants.LANDING_PAGE_DATA_HASH_ID)}`
      };
    }
  }

  componentDidMount() {
    QRCode.toCanvas(document.getElementById('qrCode'), this.state.qrComputedUrl || '', {
      width: 200,
      errorCorrectionLevel: 'H'
    }, (error) => {
      if (error) console.error(error);
    });
  }

  onLandingPageActiveCheckboxChange = (isActive) => {
    this.props.actions.UpdateLandingPageActivationStatus(
      isActive,
      this.props.landingPageViewDirection,
      this.state.landingPageEnterPhoneText,
      this.state.landingPageSuccessHeaderText,
      this.state.landingPageSuccessMessageText,
      this.state.landingPageExpiredHeaderText,
      this.state.landingPageExpiredMessageText,
      this.state.landingPageExistingMemberHeaderText,
      this.state.landingPageExistingMemberMessageText,
      this.state.landingPageFacebookPixel,
      this.state.landingPageGooglePixel,
      this.state.landingPageRedirectUrlSuccess,
      this.state.landingPageRedirectUrlFailure,
      true // Social Section
    );
  };
  requestFeatureActivation = () => {
    this.props.actions.requestToEnableGiftLandingPageFeature({
      locationId: this.props.locationId,
      locationDisplayName: this.props.locationDisplayName,
    });
  };
  onShowLegalSectionToggleChange = (checked) => {
    this.props.actions.UpdateLandingPageLegalSectionStatus(checked, this.props.legalSectionText);
  };
  onShowSocialSectionToggleChange = (checked) => {
    this.props.actions.UpdateLandingPageSocialSectionStatus(checked);
  };

  onOnlyNewMembersCheckboxChange = (e, data) => {
    this.props.actions.OnlyNewMembersCheckboxChange(data.checked);
  };

  onInputChange = (e, data) => {
    this.setState({
      [data.name]: data.value
    });
    this.props.actions.UpdateLandingPageInputText(data.name, data.value);
  };

  stripTrailingSlash = (str) => str.endsWith('/') ?
    str.slice(0, -1) :
    str;

  onExpandCollapseTexts() {
    this.setState({
      textsCollapsed: !this.state.textsCollapsed
    });
  }

  downloadQrCode = (el) => {
    el.preventDefault();
    const data = document.getElementById('qrCode').toDataURL().replace('image/png', 'image/octet-stream;');
    const filename = `${this.props.locationId}_${this.props.activityName}_LandingPage.png`;
    const saveLink = document.createElementNS('http://www.w3.org/1999/xhtml', 'a');
    saveLink.href = data;
    saveLink.download = filename;
    const event = document.createEvent('MouseEvents');
    event.initMouseEvent('click', true, false, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null);
    saveLink.dispatchEvent(event);
  };

  render() {
    const { formatMessage } = this.props.intl; // eslint-disable-line react/prop-types
    const { activityPrefix, headerMsgId, landingPageBaseUrl, landingPageData, isActivityDraft } = this.props;
    const { previewUrl } = this.state;
    const automationIdPrefix = `${activityPrefix}.landing-page`;
    let landingPageActive = false;
    if (landingPageData != null && landingPageData.get(activityConstants.LANDING_PAGE_ACTIVATION_STATUS) != null) {
      landingPageActive = landingPageData.get(activityConstants.LANDING_PAGE_ACTIVATION_STATUS);
    }

    let phonePreview;
    let phoneSuccessPreview;
    let computedUrl = false;
    let onlyNewMembersCheckbox = false;
    let landingPageShowLegalSection = false;
    let landingPageShowSocialSection = true;

    if (landingPageData != null && landingPageData.get(activityConstants.LANDING_PAGE_ONLY_NEW_MEMBERS_CHECKBOX) != null) {
      onlyNewMembersCheckbox = landingPageData.get(activityConstants.LANDING_PAGE_ONLY_NEW_MEMBERS_CHECKBOX);
    }

    if (landingPageData != null && landingPageData.get(activityConstants.LANDING_PAGE_SHOW_LEGAL_SECTION) != null) {
      landingPageShowLegalSection = landingPageData.get(activityConstants.LANDING_PAGE_SHOW_LEGAL_SECTION);
    }

    if (landingPageData != null && landingPageData.get(activityConstants.LANDING_PAGE_SHOW_SOCIAL_SECTION) != null) {
      landingPageShowSocialSection = landingPageData.get(activityConstants.LANDING_PAGE_SHOW_SOCIAL_SECTION);
    }

    if (landingPageData == null || landingPageData.get(activityConstants.LANDING_PAGE_DATA_HASH_ID) == null) {
      phonePreview =
        <PhonePreview classNmae={'phonePreview'} url={`${previewUrl}`} />;
      phoneSuccessPreview = (<PhonePreview
        classNmae={'phonePreview'}
        url={`${this.stripTrailingSlash(landingPageBaseUrl)}/exampleSuccessMode`}
      />);
    } else {
      computedUrl = this.state.qrComputedUrl;
      phonePreview = <PhonePreview classNmae={'phonePreview'} url={`${this.stripTrailingSlash(this.props.landingPageBaseUrl)}/${this.props.landingPageData.get(activityConstants.LANDING_PAGE_DATA_HASH_ID)}?cookieConsentGrantedExternally=true`} />;
      phoneSuccessPreview = (<PhonePreview
        classNmae={'phonePreview'}
        url={`${this.stripTrailingSlash(landingPageBaseUrl)}/${landingPageData.get(activityConstants.LANDING_PAGE_DATA_HASH_ID)}?giftSuccessMode=true`}
      />);
    }

    const featureDisabled = !this.props.hasFullPermission(FEATURE_GIFT_LANDING_PAGE);
    const requestToEnableEmailSubject = `Landing Page - Enable For ${this.props.locationDisplayName} - ${this.props.locationId}`;
    const requestToEnableEmailBody = `Hello, please enable the Gift Landing Page feature for ${this.props.locationDisplayName} - ${this.props.locationId}`;
    const requestToEnableHref = `mailto:ComoSupport@comosense.com?cc=ofir.shapira@comosense.com&subject=${requestToEnableEmailSubject}&body=${requestToEnableEmailBody}`;

    return (
      <Grid className="landing-page-tab">
        <Grid.Row>
          <Grid.Row className={'header-row'}>
            <Header>{formatMessage({ id: 'activity.landing.page.header' })}</Header>
            <div className="hint-tip-label">
              {formatMessage({ id: 'activity.landing.page.hint' })}
            </div>
          </Grid.Row>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={5} style={{ paddingLeft: '0' }}>
            <Grid.Row>
              <ToggleButton
                name="activateLandingPage"
                enabled={landingPageActive}
                data-automation-id={`${this.props.activityPrefix}.activateLandingPage`}
                automationId={`${this.props.activityPrefix}.activateLandingPage`}
                id={`${this.props.activityPrefix}.activateLandingPage.id`}
                onChange={this.onLandingPageActiveCheckboxChange}
                label={formatMessage({ id: 'activity.landing.page.activate' })}
                disabled={featureDisabled}
              />
              <p
                className={`landing_page_toggle_label${landingPageActive ? ' landing_page_toggle_label--active' : ''}`}
              >{formatMessage({ id: 'activity.landing.page.activate' })}
                {!computedUrl && !featureDisabled && <Tooltip
                  wide="very"
                  trigger={(<Icon className="como-ic-help" style={{ color: '#797f99' }}/>)}
                  content={formatMessage({ id: 'activity.landing.activate.hint' })}
                />}
              </p>
              {
                featureDisabled &&
                <p className={'requestToEnable'}>
                  <a
                    onClick={this.requestFeatureActivation}
                    href={requestToEnableHref}
                  >{formatMessage({ id: 'activity.landing.page.requestToEnable' })}</a>
                </p>
              }
              {computedUrl &&
                <><CopyToClipboard
                  value={computedUrl}
                  labelKey={'activity.landing.page.link'}
                  disabled
                  propertyName={'LandingPageLink'}
                  onChange={() => false}
                />
                  <div style={{ marginTop: '2em' }}>
                    <canvas id="qrCode"/>
                    <div><a
                      id="download"
                      style={{ marginLeft: '1em' }}
                      href={''}
                      onClick={this.downloadQrCode}
                    >{formatMessage({ id: 'activity.landing.page.downloadQrCode' })}</a>
                    </div>
                  </div>
                </>}
              {landingPageActive && <div style={{ marginTop: '2em' }}>
                <CheckboxWrapper
                  style={{ marginBottom: '2em' }}
                  name="onlyNewMembersCheckboxChange"
                  checked={onlyNewMembersCheckbox}
                  data-automation-id={'activity.landing.page.onlyNewMembers'}
                  onChange={this.onOnlyNewMembersCheckboxChange}
                  label={formatMessage({ id: 'activity.landing.page.onlyNewMembers' })}
                />
                <div className={'url-wrapper'} style={{ marginTop: '2em', width: '100%' }}>
                  <p className="action-label">{formatMessage({ id: 'activity.landing.page.redirect.url.success' })}</p>
                  <InputWrapper
                    name={activityConstants.LANDING_PAGE_REDIRECT_URL_SUCCESS}
                    onChange={this.onInputChange}
                    value={this.state.landingPageRedirectUrlSuccess}
                    prefix={`${this.props.activityPrefix}.${activityConstants.LANDING_PAGE_REDIRECT_URL_SUCCESS}`}
                    placeholder={formatMessage({ id: 'activity.landing.page.redirect.url.success.placeholder' })}
                  />
                  {/*<p className="action-label">{formatMessage({ id: 'activity.landing.page.redirect.url.failure' })}</p>
                  <InputWrapper
                    name={activityConstants.LANDING_PAGE_REDIRECT_URL_FAILURE}
                    onChange={this.onInputChange}
                    value={this.state.landingPageRedirectUrlFailure}
                    prefix={`${this.props.activityPrefix}.${activityConstants.LANDING_PAGE_REDIRECT_URL_FAILURE}`}
                    placeholder={formatMessage({ id: 'activity.landing.page.redirect.url.failure.placeholder' })}
                  />*/}
                </div>
                <p className="action-label">{formatMessage({ id: 'activity.landing.page.facebookPixel' })}</p>
                <InputWrapper
                  name={activityConstants.LANDING_PAGE_FACEBOOK_PIXEL}
                  onChange={this.onInputChange}
                  value={this.state.landingPageFacebookPixel}
                  prefix={`${this.props.activityPrefix}.${activityConstants.LANDING_PAGE_FACEBOOK_PIXEL}`}
                  placeholder={formatMessage({ id: 'activity.landing.page.facebookPixel.placeholder' })}
                />
                <p className="action-label">{formatMessage({ id: 'activity.landing.page.googlePixel' })}</p>
                <InputWrapper
                  name={activityConstants.LANDING_PAGE_GOOGLE_PIXEL}
                  onChange={this.onInputChange}
                  value={this.state.landingPageGooglePixel}
                  prefix={`${this.props.activityPrefix}.${activityConstants.LANDING_PAGE_GOOGLE_PIXEL}`}
                  placeholder={formatMessage({ id: 'activity.landing.page.googlePixel.placeholder' })}
                />
              </div>}
              {landingPageActive && <React.Fragment>
                <div
                  role="button"
                  className={'edit-texts-label'}
                  style={{ marginTop: '2em' }}
                  onClick={() => this.onExpandCollapseTexts(this.state.textsCollapsed)}
                >
                  <Icon
                    className={`expand-collapse-toggle como-ic-angle-${this.state.textsCollapsed ? 'up' : 'down'}`}
                    onClick={() => this.onExpandCollapseTexts(this.state.textsCollapsed)}
                    size="large"
                    data-automation-id={`expand-collapse-handler`}
                  />
                  {formatMessage({ id: 'activity.landing.page.edit.texts' })}
                </div>
                {!this.state.textsCollapsed && <React.Fragment>
                  <p
                    className="action-label"
                  >{formatMessage({ id: 'activity.landing.page.show.legal.section' })}
                  </p>
                  <ToggleButton
                    name={activityConstants.LANDING_PAGE_SHOW_LEGAL_SECTION}
                    enabled={landingPageShowLegalSection}
                    data-automation-id={`${this.props.activityPrefix}.showLegalSection`}
                    automationId={`${this.props.activityPrefix}.showLegalSection`}
                    id={`${this.props.activityPrefix}.showLegalSection.id`}
                    disabled={!landingPageShowLegalSection && !this.props.legalSectionText}
                    onChange={this.onShowLegalSectionToggleChange}
                  />
                  <p
                    className="action-label"
                  >{formatMessage({ id: 'activity.landing.page.show.social.section' })}
                  </p>
                  <ToggleButton
                    name={activityConstants.LANDING_PAGE_SHOW_SOCIAL_SECTION}
                    enabled={landingPageShowSocialSection}
                    data-automation-id={`${this.props.activityPrefix}.showSocialSection`}
                    automationId={`${this.props.activityPrefix}.showSocialSection`}
                    id={`${this.props.activityPrefix}.showSocialSection.id`}
                    onChange={this.onShowSocialSectionToggleChange}
                  />
                  {/*<p className="action-label">{formatMessage({ id: 'activity.landing.page.activate.enterPhone' })}</p>
                  <InputWrapper
                    name={activityConstants.LANDING_PAGE_ENTER_PHONE_TEXT}
                    onChange={this.onInputChange}
                    value={this.state.landingPageEnterPhoneText}
                    prefix={`${this.props.activityPrefix}.${activityConstants.LANDING_PAGE_ENTER_PHONE_TEXT}`}
                    placeholder={formatMessage({ id: 'activity.landing.page.activate.enterPhone.placeholder' })}
                  />*/}
                  <p
                    className="action-label"
                  >{formatMessage({ id: 'activity.landing.page.activate.successfulRedeem.title' })}</p>
                  <InputWrapper
                    name={activityConstants.LANDING_PAGE_SUCCESS_HEADER_TEXT}
                    onChange={this.onInputChange}
                    value={this.state.landingPageSuccessHeaderText}
                    prefix={`${this.props.activityPrefix}.${activityConstants.LANDING_PAGE_SUCCESS_HEADER_TEXT}`}
                    placeholder={formatMessage({ id: 'activity.landing.page.activate.successfulRedeem.title.placeholder' })}
                  />
                  <p
                    className="action-label"
                  >{formatMessage({ id: 'activity.landing.page.activate.successfulRedeem.description' })}</p>
                  <InputWrapper
                    name={activityConstants.LANDING_PAGE_SUCCESS_MESSAGE_TEXT}
                    onChange={this.onInputChange}
                    value={this.state.landingPageSuccessMessageText}
                    prefix={`${this.props.activityPrefix}.${activityConstants.LANDING_PAGE_SUCCESS_MESSAGE_TEXT}`}
                    placeholder={formatMessage({ id: 'activity.landing.page.activate.successfulRedeem.description.placeholder' })}
                  />
                  <p
                    className="action-label"
                  >{formatMessage({ id: 'activity.landing.page.activate.expired.header' })}</p>
                  <InputWrapper
                    name={activityConstants.LANDING_PAGE_EXPIRED_HEADER_TEXT}
                    onChange={this.onInputChange}
                    value={this.state.landingPageExpiredHeaderText}
                    prefix={`${this.props.activityPrefix}.${activityConstants.LANDING_PAGE_EXPIRED_HEADER_TEXT}`}
                    placeholder={formatMessage({ id: 'activity.landing.page.activate.expired.header.placeholder' })}
                  />
                  <p
                    className="action-label"
                  >{formatMessage({ id: 'activity.landing.page.activate.expired.message' })}</p>
                  <InputWrapper
                    name={activityConstants.LANDING_PAGE_EXPIRED_MESSAGE_TEXT}
                    onChange={this.onInputChange}
                    value={this.state.landingPageExpiredMessageText}
                    prefix={`${this.props.activityPrefix}.${activityConstants.LANDING_PAGE_EXPIRED_MESSAGE_TEXT}`}
                    placeholder={formatMessage({ id: 'activity.landing.page.activate.expired.message.placeholder' })}
                  />
                  <p
                    className="action-label"
                  >{formatMessage({ id: 'activity.landing.page.activate.existing.member.header' })}</p>
                  <InputWrapper
                    name={activityConstants.LANDING_PAGE_EXISTING_MEMBER_HEADER_TEXT}
                    onChange={this.onInputChange}
                    value={this.state.landingPageExistingMemberHeaderText}
                    prefix={`${this.props.activityPrefix}.${activityConstants.LANDING_PAGE_EXISTING_MEMBER_HEADER_TEXT}`}
                    placeholder={formatMessage({ id: 'activity.landing.page.activate.existing.member.header.placeholder' })}
                  />
                  <p
                    className="action-label"
                  >{formatMessage({ id: 'activity.landing.page.activate.existing.member.message' })}</p>
                  <InputWrapper
                    name={activityConstants.LANDING_PAGE_EXISTING_MEMBER_MESSAGE_TEXT}
                    onChange={this.onInputChange}
                    value={this.state.landingPageExistingMemberMessageText}
                    prefix={`${this.props.activityPrefix}.${activityConstants.LANDING_PAGE_EXISTING_MEMBER_MESSAGE_TEXT}`}
                    placeholder={formatMessage({ id: 'activity.landing.page.activate.existing.member.message.placeholder' })}
                  />
                </React.Fragment>}
              </React.Fragment>}
            </Grid.Row>
          </Grid.Column>
          <Grid.Column width={6} className="right-col">
            <Grid.Row>
              <div className="hint-tip-label hint-tip-label-header" style={{ marginTop: '10px' }}>
                <span style={{ fontStyle: 'initial' }}>{formatMessage({ id: 'activity.landing.preview.header' })}</span>
                <br/>
                <span>{formatMessage({ id: 'activity.landing.preview.success' })}</span>
              </div>
              <div>
                {phonePreview}
              </div>
            </Grid.Row>
          </Grid.Column>
          <Grid.Column width={5}>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}

export default withAuthorization(injectIntl(LandingPageTabContent));
