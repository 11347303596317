import { fromJS, List } from 'immutable';
import moment from 'moment';
import 'moment-timezone';
import _ from 'lodash';

import * as schemaConstants from '../../activitySchema/activitySchema.constants';
import * as constants from '../../activity.constants';
import * as defaults from '../activity.reducer.defaults';
import * as datesConstants from '../../../../common/utils/dateTime.constants';
import * as membershipChangedConstants
  from '../../views/conditions/updateMembershipCondition/updateMembership.constants';

/** *
 * Returns an empty condition or a specific condition with default values if the given trigger supports only one condition type
 * @param trigger
 * @param schema
 * @param isConditionInCases
 * @returns {*}
 */
export function createDefaultCondition(trigger, schema, isConditionInCases) {
  const conditionsForTrigger = schema.getIn([constants.CONDITIONS_PER_TRIGGERS, trigger]);
  const newCondition = fromJS(defaults.emptyCondition);
  const filteredConditionsForTrigger = getConditionsForTriggerCount(conditionsForTrigger, isConditionInCases);
  if (filteredConditionsForTrigger && filteredConditionsForTrigger.count() === 1) {
    return newCondition
      .set(constants.CONDITION_KEY, conditionsForTrigger.getIn([0, constants.SCHEMA_CONDITION_KEY]))
      .set(constants.OPERATOR_KEY, conditionsForTrigger.getIn([0, constants.SCHEMA_CONDITION_OPERATORS, 0, constants.VALUE]))
      .set(constants.CONDITION_VALUE_DATA_TYPE, conditionsForTrigger.getIn([0, constants.SCHEMA_CONDITION_VALUE_DATA_TYPE]));
  }
  return newCondition;
}

function getConditionsForTriggerCount(conditionsForTrigger, isConditionInCases) {
  if (isConditionInCases) {
    return conditionsForTrigger;
  }
  return conditionsForTrigger.filter((condition) => !condition.get(schemaConstants.CASES_ONLY_CONDITION));
}

export function updateConditionField(condition, trigger, fieldName, fieldValue, schema, isUserActionFilter = false) {

  // if user selected different condition (condition key has changed) - initialize the new condition:
  if (fieldName === constants.CONDITION_KEY) {
    const newCondition = fromJS(defaults.emptyCondition).set(constants.CONDITION_KEY, fieldValue);
    const result = defaultizeCondition(newCondition, trigger, schema, isUserActionFilter);
    return handleSpecialOperators(result);
  }

  if (condition.get(constants.CONDITION_KEY) === constants.CONTEXT_MEMBERSHIP_TAG && fieldName === constants.CONDITION_VALUE) {
    const trimFieldValue = fieldValue.map((f) => f.trim());
    const updatedCondition = condition.set(fieldName, fromJS(trimFieldValue));
    return handleSpecialOperators(updatedCondition);
  }
  
  // otherwise, simply update the specific field of the condition:
  const updatedCondition = condition.set(fieldName, fromJS(fieldValue));
  return handleSpecialOperators(updatedCondition);
}

export function handleSpecialOperators(condition) {
  // in case the operator key is "containsNone", we don't need any condition value
  if (condition.get(constants.OPERATOR_KEY) === schemaConstants.OPERATOR_KEY_CONTAINS_NONE) {
    return condition.set(constants.CONDITION_VALUE, null);
  }

  return condition;
}

export function defaultizeCondition(condition, trigger, schema, isUserActionFilter = false) {

  const conditionKey = condition.get(constants.CONDITION_KEY);

  // fixed bug: error when condition is empty
  if (conditionKey === '') {
    return condition;
  }

  // special case handling for CONTEXT_TIMESTAMP:
  if (conditionKey === schemaConstants.CONTEXT_TIMESTAMP) {
    // add empty dateTimeRange object to case condition when empty
    const dateTime = createInitialDateTimeCondition(schema);
    return dateTime.get(constants.DATES_RANGE_CONDITION);
  }

  const conditionSchema = getConditionSchema(conditionKey, trigger, schema, isUserActionFilter);

  const requiredSpecialUpdateMembershipConditionBehavior =
    trigger === schemaConstants.TRIGGER_UPDATE_MEMBERSHIP
    && conditionKey.includes(schemaConstants.UPDATE_MEMBERSHIP_UPDATED_FIELDS)
    && ![schemaConstants.CODE_TAG, schemaConstants.OPERATION].includes(conditionKey);

  // by default select the first operator for this condition
  const operatorsList = conditionSchema
    ? conditionSchema.get(constants.SCHEMA_CONDITION_OPERATORS)
    : List();

  const operator = requiredSpecialUpdateMembershipConditionBehavior
    ? schemaConstants.OPERATORS_UPDATE_MEMBERSHIP
    : operatorsList.getIn([0, constants.VALUE]) || '';

  const dataValueType = requiredSpecialUpdateMembershipConditionBehavior
    ? schemaConstants.DATA_VALUE_TYPE_MEMBER_FIELD_CHANGED_FROM_TO
    : conditionSchema.get(constants.CONDITION_VALUE_DATA_TYPE);

  let result = condition
    .set(constants.OPERATOR_KEY, operator)
    .set(constants.CONDITION_VALUE_DATA_TYPE, dataValueType);

  if (requiredSpecialUpdateMembershipConditionBehavior) {
    return result.set(constants.CONDITION_VALUE, initializeUpdateMembershipCondition(conditionKey));
  }

  if (dataValueType === schemaConstants.DATA_VALUE_TYPE_DATE) {
    const startOfDay = moment().startOf('day').format(datesConstants.DATE_ONLY_FORMAT);
    return result.set(constants.CONDITION_VALUE, startOfDay);
  }

  switch (conditionKey) {
    case schemaConstants.CONTEXT_TIMESTAMP_DAYS:
      // add empty daysTimes object to case condition if empty
      return result.set(constants.CONDITION_VALUE, fromJS([defaults.defaultDaysTimesConditionValue]));
    case schemaConstants.CONDITION_KEY_SHOPPING_CART_CERTAIN_ITEMS:
      result = result.set(constants.ITEMS_POPULATION, defaults.emptyItemsPopulationImmutable)
                     .set(constants.CONDITION_VALUE, 1);
      break;
    case schemaConstants.CODE_TAG:
      return result.set(constants.CONDITION_VALUE, List());
    case schemaConstants.OPERATION:
      return result.set(constants.CONDITION_VALUE, fromJS(schemaConstants.OPERATION_VALUE_UPDATE_MEMBERSHIP));
    case schemaConstants.CONTEXT_REFERRAL_DATA:
      return result.set(constants.CONDITION_VALUE, fromJS(defaults.defaultReferralDataConditionValueImmutable));
    case schemaConstants.CONTEXT_PURCHASE_TOTAL_SUM:
      return result.set(constants.CONDITION_VALUE, 0);
    case schemaConstants.CONTEXT_SHOPPING_CART_TOTAL_PRICE:
      return result.set(constants.ITEMS_POPULATION, defaults.emptyItemsPopulationImmutable)
        .set(constants.CONDITION_VALUE, 0);
/*
    case schemaConstants.CONTEXT_FRIEND_SHOPPING_CART_TOTAL_QUANTITY:
*/
    case schemaConstants.CONTEXT_SHOPPING_CART_TOTAL_QUANTITY:
      return result
        .set(constants.ITEMS_POPULATION, defaults.emptyItemsPopulationImmutable)
        .set(constants.CONDITION_VALUE, 1);
    default: {
      const defaultValue = schema.getIn([constants.DATA_VALUE_TYPE_DEFAULTS, dataValueType]);
      result = result.set(constants.CONDITION_VALUE, defaultValue);
      break;
    }
  }

  return result;
}

export function createInitialDateTimeCondition(schema) {
  const dateTimeGlobalConditions = schema.getIn([constants.GLOBAL_CONDITIONS, constants.DATETIME_GLOBAL_CONDITIONS]);
  const datesRangeSchemaCondition = dateTimeGlobalConditions.get(constants.DATES_RANGE_CONDITION);
  const daysTimesSchemaCondition = dateTimeGlobalConditions.get(constants.DAYS_TIMES_CONDITION);
  const datesRangeInitialCondition = {
    [constants.CONDITION_KEY]: datesRangeSchemaCondition.get(constants.SCHEMA_CONDITION_KEY),
    [constants.OPERATOR_KEY]: datesRangeSchemaCondition.get(constants.SCHEMA_CONDITION_OPERATORS).find((operator) =>
      operator.get(constants.SCHEMA_CONDITION_KEY) === datesConstants.DATE_TIME_IS_AFTER).get(constants.VALUE),
    // current time in utc
    [constants.CONDITION_VALUE]: moment().utc().format(datesConstants.STANDARD_TIME_FORMAT),
    [constants.CONDITION_VALUE_DATA_TYPE]: datesRangeSchemaCondition.get(constants.CONDITION_VALUE_DATA_TYPE)
  };
  const daysTimesInitialCondition = {
    [constants.CONDITION_KEY]: daysTimesSchemaCondition.get(constants.SCHEMA_CONDITION_KEY),
    [constants.OPERATOR_KEY]: daysTimesSchemaCondition.getIn([constants.SCHEMA_CONDITION_OPERATORS, 0]).get(constants.VALUE),
    [constants.CONDITION_VALUE]: [defaults.defaultDaysTimesConditionValue],
    [constants.CONDITION_VALUE_DATA_TYPE]: daysTimesSchemaCondition.get(constants.CONDITION_VALUE_DATA_TYPE)
  };
  return fromJS({
    [constants.DATES_RANGE_CONDITION]: datesRangeInitialCondition,
    [constants.DAYS_TIMES_CONDITION]: daysTimesInitialCondition
  });

}

/**
 * initialize update membership value - set object model and fix conditionKey
 * @param {string} conditionKey
 * @returns {*}
 */
export function initializeUpdateMembershipCondition(conditionKey) {
  return fromJS(defaults.emptyUpdateMembershipConditionValue)
    .setIn([membershipChangedConstants.UPDATE_MEMBERSHIP_FROM, constants.CONDITION_KEY],
      `${conditionKey}${membershipChangedConstants.UPDATE_MEMBERSHIP_FROM_SUFFIX}`)
    .setIn([membershipChangedConstants.UPDATE_MEMBERSHIP_TO, constants.CONDITION_KEY],
      `${conditionKey}${membershipChangedConstants.UPDATE_MEMBERSHIP_TO_SUFFIX}`);
}

/** *
 * Transform DateTime global condition temp model to normalized backend-compatible model
 * @param tempModel
 * @returns {*}
 */
export function transformDateTimeGlobalConditionTempModel(tempModel) {
  if (tempModel.get(constants.TYPE) === constants.DATETIME_ANY) {
    return null;
  }
  // todo: consider with Tal/Ruth change of contract: maybe better to pass datesRangeCondition or dateAfterCondition instead of dynamic model type
  let dateFrom;
  let result = tempModel;
  if (result.getIn([constants.DATES_RANGE_CONDITION, constants.CONDITION_VALUE, datesConstants.DATE_FROM])) {
    dateFrom = moment.utc(result.getIn([constants.DATES_RANGE_CONDITION, constants.CONDITION_VALUE, datesConstants.DATE_FROM])).format(datesConstants.STANDARD_TIME_FORMAT);
    const dateTo = moment.utc(result.getIn([constants.DATES_RANGE_CONDITION, constants.CONDITION_VALUE, datesConstants.DATE_TO])).format(datesConstants.STANDARD_TIME_FORMAT);
    result = result
        .setIn([constants.DATES_RANGE_CONDITION, constants.CONDITION_VALUE, datesConstants.DATE_FROM], fromJS(dateFrom))
        .setIn([constants.DATES_RANGE_CONDITION, constants.CONDITION_VALUE, datesConstants.DATE_TO], fromJS(dateTo));
  } else {
    dateFrom = moment
      .utc(result.getIn([constants.DATES_RANGE_CONDITION, constants.CONDITION_VALUE]))
      .format(datesConstants.STANDARD_TIME_FORMAT);
    result = result.setIn([constants.DATES_RANGE_CONDITION, constants.CONDITION_VALUE], fromJS(dateFrom));
  }
  return result.delete(constants.TYPE);
}

function getConditionSchema(conditionKey, trigger, schema, isUserActionFilter) {
  let schemaConditions;
  if (isUserActionFilter) {
    schemaConditions = schema.getIn([constants.CONDITIONS_PER_USER_ACTION, trigger, schemaConstants.CONDITIONS_PER_ACTION])
      .concat(schema.getIn([constants.CONDITIONS_PER_USER_ACTION, trigger, schemaConstants.AGGREGATED_CONDITIONS_PER_ACTION]));
    if (!schemaConditions) {
      return null;
    }
  } else {
    const triggerConditions = schema.getIn([constants.CONDITIONS_PER_TRIGGERS, trigger]);
    const membershipGlobalConditionsSchema = schema.getIn([constants.GLOBAL_CONDITIONS, constants.MEMBERSHIP_GLOBAL_CONDITIONS, trigger]);
    const membershipOneTimeGlobalConditionsSchema = schema.getIn([constants.ONE_TIME_CONDITIONS, constants.MEMBERSHIP_GLOBAL_CONDITIONS]);

    const updateMembershipConditionsSchema = schema.get(constants.UPDATE_MEMBERSHIP_CONDITIONS);
    schemaConditions = (triggerConditions || List())
      .concat(membershipGlobalConditionsSchema.get(constants.CONDITIONS_MEMBERSHIP_BY_REG_FORM_CATEGORY))
      .concat(membershipGlobalConditionsSchema.get(constants.CONDITIONS_MEMBERSHIP_ALWAYS_SHOW))
      .concat(schema.getIn([constants.GLOBAL_CONDITIONS, constants.DATETIME_GLOBAL_CONDITIONS]))
      .concat(updateMembershipConditionsSchema.get(constants.CONDITIONS_ALL_MEMBERSHIP_BY_REG_FORM_CATEGORY))
      .concat(updateMembershipConditionsSchema.get(constants.CONDITIONS_MEMBERSHIP_ALWAYS_SHOW))
      .concat(membershipOneTimeGlobalConditionsSchema.get(constants.CONDITIONS_MEMBERSHIP_BY_REG_FORM_CATEGORY));
  }
  return schemaConditions.find((cs) => cs.get(constants.KEY) === conditionKey);
}


/**
 * remove empty conditions,
 * cast value to array when condition is list
 * @param conditions
 */
export function adjustConditions(conditions) {
  return conditions
    .filter((condition) => condition[constants.CONDITION_KEY] !== '')
    .map((condition) => {
      const newCondition = condition;
      if ([constants.IS_ONE_OF, constants.IS_NOT_ONE_OF].indexOf(newCondition[constants.OPERATOR_KEY]) > -1 &&
        Array.isArray(newCondition[constants.CONDITION_VALUE])) {
        newCondition[constants.CONDITION_VALUE] = newCondition[constants.CONDITION_VALUE]
          .map((str) => str.trim())
          .filter((str) => !_.isEmpty(str));
      } else if ([constants.IS_ONE_OF, constants.IS_NOT_ONE_OF].indexOf(newCondition[constants.OPERATOR_KEY]) > -1 &&
        typeof newCondition[constants.CONDITION_VALUE] === 'object') { // This case if for referral code with specific codes
        if (newCondition[constants.CONDITION_VALUE][constants.CONDITION_VALUE_REFERRAL_CODE]) {
          newCondition[constants.CONDITION_VALUE][constants.CONDITION_VALUE_REFERRAL_CODE] =
            newCondition[constants.CONDITION_VALUE][constants.CONDITION_VALUE_REFERRAL_CODE]
              .map((str) => str.trim())
              .filter((str) => !_.isEmpty(str));
        }
      } else if (typeof newCondition[constants.CONDITION_VALUE] === 'string') {
        newCondition[constants.CONDITION_VALUE] = newCondition[constants.CONDITION_VALUE].trim();
      }
      if (!_.isNil(newCondition[constants.ITEMS_POPULATION]) && !_.isNil(newCondition[constants.ITEMS_POPULATION][constants.CONDITIONS])) {
        newCondition[constants.ITEMS_POPULATION][constants.CONDITIONS][constants.CONDITIONS_LIST] =
          adjustConditions(newCondition[constants.ITEMS_POPULATION][constants.CONDITIONS][constants.CONDITIONS_LIST]);
      }
      return newCondition;
    });
}
