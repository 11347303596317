/*
 *
 * MicroCampaign reducer
 *
 */

import { fromJS } from 'immutable';
import * as constants from './microCampaign.constants';
import * as defaults from '../MicroCampaign/microCampaign.defaults';
import * as activityActionsUtils from '../Activity/reducers/utils/action.reducer.utils';
import * as activityConstants from '../Activity/activity.constants';
import * as validationUtils from './utils/microCampaign.validation.utils';
import * as schemaConstants from '../Activity/activitySchema/activitySchema.constants';
import * as utils from './utils/microCampaign.reducer.utils';
import * as appConstants from '../App/app.constants';


const initialState = fromJS({});


function microCampaignReducer(state = initialState, action) {

  let forceValidation = false;
  let newState = state;

  switch (action.type) {

    case constants.CREATE_MICRO_CAMPAIGN:
      {
        newState = newState
          .set(activityConstants.DATA, defaults.initialMicroCampaign)
          .set(constants.MICRO_CAMPAIGN_DATA_LOADED, true);
        break;
      }

    case constants.UPDATE_MICRO_CAMPAIGN_FIELD:
      {
        newState = newState.setIn([activityConstants.DATA, action.data.name], action.data.value);
        newState = microCampaignValidationsImpl(newState, forceValidation, action.data.rootState);

        break;
      }

    case constants.UPDATE_COMMUNICATION_ACTION_TYPE:
      {
        const communicationType = action.index === 0 ? constants.MICRO_CAMPAIGN_ACTION_TYPE_LAUNCH : constants.MICRO_CAMPAIGN_ACTION_TYPE_REMINDER;
        let updatedAction = activityActionsUtils.initializeActionByType(action.actionName, null, null, null, communicationType);
        if (action.actionName === schemaConstants.SEND_MEMBER_SMS_ACTION || action.actionName === schemaConstants.SEND_PERSONAL_PUSH_ACTION || action.actionName === schemaConstants.SEND_PERSONAL_WEB_PUSH_ACTION) {
          updatedAction = updatedAction.set(activityConstants.SMS_MESSAGE, action.defaultText);
        }
        newState = newState.setIn([activityConstants.DATA, constants.MICRO_CAMPAIGN_ASSET_TEMPLATES, action.assetSetIndex, activityConstants.ACTIONS, action.index], updatedAction);
        newState = microCampaignValidationsImpl(newState, forceValidation);

        break;
      }
    case constants.SUBMIT_COMMUNICATION_ACTION_TO_CUSTOMER:
      {
        const communicationAction = newState.getIn([activityConstants.DATA, constants.MICRO_CAMPAIGN_ASSET_TEMPLATES, action.actionsWrapperIndex, activityConstants.ACTIONS, action.actionIndex]);
        const communicationActionValidationErrors = state.get(constants.MICRO_CAMPAIGN_VALIDATION_ERRORS);
        let testToMyself = fromJS({});
        testToMyself = testToMyself
          .set(appConstants.USER, action.user)
          .set(activityConstants.ACTION, communicationAction)
          .set(activityConstants.VALIDATION_ERRORS, fromJS(communicationActionValidationErrors))
          .set(activityConstants.TEST_TO_MYSELF_COMPLETED, false)
          .setIn([activityConstants.ACTION_PATH, activityConstants.ACTIONS_WRAPPER_INDEX], action.actionsWrapperIndex)
          .setIn([activityConstants.ACTION_PATH, activityConstants.ACTION_INDEX], action.actionIndex)
          .set(activityConstants.TEST_TO_MYSELF_SUCCEEDED, false);
        if (communicationActionValidationErrors && Object.keys(communicationActionValidationErrors).length !== 0) {
          newState = newState.set(constants.MICRO_CAMPAIGN_VALIDATION_ERRORS, fromJS(communicationActionValidationErrors));
        }
        newState = newState
          .set(activityConstants.TEST_TO_MYSELF, testToMyself)
          .setIn([activityConstants.VALIDATION_METADATA, activityConstants.ACTIVITY_VALIDATION_MODE], activityConstants.ACTIVITY_VALIDATION_MODE_DONT_VALIDATE);
        break;
      }

    case constants.UPDATE_MICRO_CAMPAIGN_METADATA:
      {
        newState = newState.setIn([activityConstants.DATA, action.key], action.value);
        newState = microCampaignValidationsImpl(newState, forceValidation);

        break;
      }

    case constants.GET_MICRO_CAMPAIGN: {
      newState = newState.set(constants.MICRO_CAMPAIGN_DATA_LOADED, false);
      break;
    }

    case constants.GET_MICRO_CAMPAIGN_SUCCESS:
      {
        if(action.data && action.data.assets && !action.data.assets.length){
          action.data.assets = [defaults.emptyAssetTemplate];
        }
        action.data.assets.map((asset) => asset.actions.map((a) => {
          if (a.actionType === 'draft'){
            a.actionType = "";
          }
          return a;
        }));
        newState = newState

          .set(activityConstants.DATA, fromJS(action.data))
          .set(constants.IS_DIRTY, false)
          .set(constants.MICRO_CAMPAIGN_DATA_LOADED, true);

        newState = newState.setIn([activityConstants.DATA, activityConstants.TRIGGER], schemaConstants.TRIGGER_MICRO_CAMPAIGN);

        const updatedAssetSets = newState.getIn([activityConstants.DATA, constants.MICRO_CAMPAIGN_ASSET_TEMPLATES]).map((assetSet) => {
          if (assetSet.get(activityConstants.ACTIONS).size < 2) {
            const defaultActions = utils.setDefaultCommunicationActions(newState.getIn([activityConstants.DATA, constants.MICRO_CAMPAIGN_ASSET_TEMPLATES, 0, activityConstants.ACTIONS]));
            return assetSet.set(activityConstants.ACTIONS, defaultActions);
          }
          return assetSet;
        });
        newState = newState.setIn([activityConstants.DATA, constants.MICRO_CAMPAIGN_ASSET_TEMPLATES], updatedAssetSets);
        break;
      }

    case constants.REMOVE_MICRO_CAMPAIGN_PUBLISH_IN_PROGRESS:
      {
        newState = newState
        .set(constants.PUBLISH_IN_PROGRESS, false);
        break;
      }

    case constants.REMOVE_MICRO_CAMPAIGN_SAVE_AS_DRAFT_IN_PROGRESS:
      {
        newState = newState
        .set(constants.SAVE_AS_DRAFT_IN_PROGRESS, false);
        break;
      }

    case constants.CLEAR_MICRO_CAMPAIGN_DATA: {
      newState = newState
        .set(activityConstants.DATA, defaults.initialMicroCampaign)
        .set(constants.PUBLISH_IN_PROGRESS, false)
        .set(constants.SAVE_AS_DRAFT_IN_PROGRESS, false)
        .delete(activityConstants.VALIDATION_ERRORS);
      break;
    }

    case constants.SAVE_MICRO_CAMPAIGN_SUCCESS:
    case constants.SAVE_MICRO_CAMPAIGN_ERROR: {
      newState = newState.set(constants.PUBLISH_IN_PROGRESS, false);
      break;
    }

    case constants.SET_MICRO_CAMPAIGN_PUBLISH_IN_PROGRESS: {
      newState = newState.set(constants.PUBLISH_IN_PROGRESS, true);
      break;
    }

    case constants.SET_MICRO_CAMPAIGN_ERRORS: {
      newState = state
        .set(constants.MICRO_CAMPAIGN_VALIDATION_ERRORS, action[constants.MICRO_CAMPAIGN_VALIDATION_ERRORS])
        .set(constants.MICRO_CAMPAIGN_VALIDATION_NAME_TAKEN_FLAG, action[constants.MICRO_CAMPAIGN_VALIDATION_NAME_TAKEN_FLAG])
        .set(activityConstants.ACTIVITY_VALIDATION_MODE, action[activityConstants.ACTIVITY_VALIDATION_MODE]);
      break;

    }

    case constants.CHECK_MICRO_CAMPAIGN_NAME_AVAILABILITY: {
      newState = newState.set(constants.CHECKING_MICRO_CAMPAIGN_NAME_AVAILABILITY, true);
      break;
    }

    case constants.CHECK_MICRO_CAMPAIGN_NAME_AVAILABILITY_SUCCESS: {
      // update metadata before validation
      newState = newState.set(constants.MICRO_CAMPAIGN_VALIDATION_NAME_TAKEN_FLAG, !action.nameAvailable);
      forceValidation = true;
      break;
    }

    case constants.CHECK_MICRO_CAMPAIGN_NAME_AVAILABILITY_DONE: {
      newState = newState.set(constants.CHECKING_MICRO_CAMPAIGN_NAME_AVAILABILITY, false);
      break;
    }

    case constants.ADD_BENEFIT_TO_MICRO_CAMPAIGN: {
      let newAssetSet = defaults.emptyAssetTemplateImmutable;
      newAssetSet = newAssetSet.set(activityConstants.IS_COLLAPSED, false);
      const assetTemplates = newState.getIn([activityConstants.DATA, constants.MICRO_CAMPAIGN_ASSET_TEMPLATES]).push(newAssetSet);
      newState = newState.setIn([activityConstants.DATA, constants.MICRO_CAMPAIGN_ASSET_TEMPLATES], assetTemplates);
      break;
    }

    case constants.DELETE_ASSET_TEMPLATE: {
      // backup notify actions
      newState = newState.deleteIn([activityConstants.DATA, constants.MICRO_CAMPAIGN_ASSET_TEMPLATES, action.index]);
      break;
    }

    case constants.CLEAR_ASSET_TEMPLATE: {
      newState = newState.setIn([activityConstants.DATA, constants.MICRO_CAMPAIGN_ASSET_TEMPLATES, action.index], defaults.emptyAssetTemplateImmutable);
      break;
    }

    case constants.TOGGLE_ASSET_SET_DISPLAY: {
      newState = newState.setIn([activityConstants.DATA, constants.MICRO_CAMPAIGN_ASSET_TEMPLATES, action.index, activityConstants.IS_COLLAPSED], action.isCollapsed);
      break;
    }

    case constants.SET_ALL_EXPAND_COLLAPSE_MODE: {
      let cases = newState.getIn([activityConstants.DATA, constants.MICRO_CAMPAIGN_ASSET_TEMPLATES]);
      cases = cases.map((activityCase) => activityCase.set(activityConstants.IS_COLLAPSED, action.isCollapsed));
      newState = newState.setIn([activityConstants.DATA, constants.MICRO_CAMPAIGN_ASSET_TEMPLATES], cases);
      break;

    }

    default: {
      newState = state;
    }

  }

  newState = markAsDirtyIfNeeded(action, state, newState);

  return newState;

}

function microCampaignValidationsImpl(state, forceValidation, rootState) {
  // Re-validate if needed
  let newState = state;
  const validationErrors = newState.get(constants.MICRO_CAMPAIGN_VALIDATION_ERRORS);
  if (forceValidation || validationErrors) {
    newState = validationUtils.validateMicroCampaign(newState, newState.get(constants.MICRO_CAMPAIGN_STATUS), rootState);
  }
  return newState;
}

function markAsDirtyIfNeeded(action, prevState, newState) {

  if (prevState !== newState && prevState.get(constants.MICRO_CAMPAIGN_DATA_LOADED)) {
    return newState.set(constants.IS_DIRTY, true);
  }
  return newState;
}

export default microCampaignReducer;
