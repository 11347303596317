import { fromJS } from 'immutable';
import * as constants from './customerPortal.constants';

const initialState = fromJS({});


function customerPortalReducer(state = initialState, action) {

  let newState;

  switch (action.type) {
    case constants.GET_CUSTOMER_PORTAL_SUCCESS:
      newState =
        state.set(constants.CUSTOMER_PORTAL_DATA, fromJS(action.data.customerPortalModel))
          .set(constants.CUSTOMER_PORTAL_COUNTRIES, fromJS(action.data.countries))
          .set(constants.CUSTOMER_PORTAL_LANGUAGES, fromJS(action.data.languages))
          .set(constants.HAS_SUBSCRIPTION, fromJS(action.data.hasSubscription))
          .set(constants.IS_BUSINESS_WALLET_PASS_ACTIVE, fromJS(action.data.isBusinessWalletPassActive))
          .set(constants.SAVED_CONFIGURATION, fromJS(false))
          .set(constants.TOAST_IS_OPEN, fromJS(false));
      break;
    case constants.SET_CUSTOMER_PORTAL_DATA_IS_LOADING:
      newState = state.set(constants.CUSTOMER_PORTAL_DATA_LOADED, action.isLoading);
      break;
    case constants.GET_CUSTOMER_PORTAL_ERROR:
      newState = state.set(constants.CUSTOMER_PORTAL_DATA_ERROR, fromJS(true))
                      .set(constants.SAVED_CONFIGURATION, fromJS(false))
                      .set(constants.TOAST_IS_OPEN, fromJS(false));
      break;
    case constants.GET_CUSTOMER_PORTAL_SAVING_ERROR: {
      newState = state.set(constants.CUSTOMER_PORTAL_DATA_ERROR, fromJS(true))
        .set(constants.CUSTOMER_PORTAL_DATA_ERROR_MESSAGE, fromJS(action.message))
        .set(constants.SAVED_CONFIGURATION, fromJS(false));
      break;
    }
    case constants.RESET_ERRORS: {
      newState = state.set(constants.CUSTOMER_PORTAL_DATA_ERROR, fromJS(false))
        .set(constants.CUSTOMER_PORTAL_DATA_ERROR_MESSAGE, fromJS(''));
      break;
    }
    case constants.FIELD_CHANGED: {
      const { field, value } = action.payload;

      /*
       * Instead of adding additional field for array of field name for nested properties
       * if the field passed it of type string then create an array with single entry
       * else create use the array of field names and spread it in setIn function so
       * the end result of the payload is automatically nested. Otherwise, we see something
       * like prop1.nestedProp1 etc. instead of properly nested properties in object.
       * this helps in keeping all the previous logic same and also allow the nested
       * objects to be set as well.
       * */
      const finalFields = typeof field === 'string' ? [field] : [...field];

      newState = state.setIn([constants.CUSTOMER_PORTAL_DATA, ...finalFields], value);
      break;
    }
    case constants.FIELD_CHANGED_TRANSLATION: {
      const { field, value, selectedLanguage } = action.payload;
      newState = state.setIn([constants.CUSTOMER_PORTAL_DATA, constants.TRANSLATIONS, selectedLanguage, field], value);
      break;
    }
    case constants.THEME_FIELD_CHANGE: {
      const { type, field, value } = action.payload;
      newState = state.setIn([constants.CUSTOMER_PORTAL_DATA, type, field], value);
      break;
    }
    case constants.MENU_FIELD_CHANGE: {
      const { index, field, value } = action.payload;
      newState = state.setIn([constants.CUSTOMER_PORTAL_DATA, constants.DATA_MENU, index, field], value);
      break;
    }
    case constants.MENU_FIELD_CHANGE_TRANSLATION: {
      const { index, field, value, selectedLanguage } = action.payload;
      newState = state.setIn([constants.CUSTOMER_PORTAL_DATA, constants.DATA_MENU, index, constants.TRANSLATIONS, selectedLanguage, field], value);
      break;
    }
    case constants.MENU_FIELD_REDIRECT_ROUTE_CHANGE: {
      const { index, field, value } = action.payload;
      const menuItems = state.getIn([constants.CUSTOMER_PORTAL_DATA, constants.DATA_MENU]);
      const newMenuItems = menuItems.map((item, i) => {
          return item.set(field, (i === index ? value : false));
      });

      newState = state.setIn([constants.CUSTOMER_PORTAL_DATA, constants.DATA_MENU], newMenuItems);
      break;
    }
    case constants.ADD_CUSTOM_MENU_ITEM: {
      const customMenu = {
        openWithNavBar: true,
        openInNewWindow: false,
        navigateToLink: false,
        name: constants.MENU_CUSTOM_MENU_ITEM_NAME,
        defaultName: constants.MENU_CUSTOM_MENU_ITEM_NAME,
        menuOrder: 1,
        url: null,
        isActive: false,
        isDefault: false,
        allowDynamicUrl: false,
        onlyForLoggedInUser: true,
        displayArea: constants.CUSTOM_MENU_ITEM_DISPLAY_AREA_SIDEBAR_VALUE,
        translations: {}
      };

      //Add Translation object for new menu
      const translations = state.getIn([constants.CUSTOMER_PORTAL_DATA, constants.TRANSLATIONS]);
      translations.keySeq().forEach(language => {
        customMenu.translations[language] = { name: constants.MENU_CUSTOM_MENU_ITEM_NAME };
      });


      const menus = state.getIn([constants.CUSTOMER_PORTAL_DATA, constants.DATA_MENU]).concat([fromJS(customMenu)]);
      newState = state.setIn([constants.CUSTOMER_PORTAL_DATA, constants.DATA_MENU], menus);

      break;
    }
    case constants.ADD_NEW_COUNTRY: {
      const country = {
        name: '',
        countryCode: '',
        languages: null,
        defaultLanguage: {
          name: '',
          code: '',
          direction: ''
        }
      };
      const countries = state.getIn([constants.CUSTOMER_PORTAL_DATA, constants.COUNTRIES_DATA]).concat([fromJS(country)]);
      newState = state.setIn([constants.CUSTOMER_PORTAL_DATA, constants.COUNTRIES_DATA], countries);

      // if there is more than country then switch remove leading zero and use country code

      if (countries.size > 1) {
        const newCountriesData = countries.map((item, index) => {
          let currentItem = item;
          if (item.get(constants.USE_COUNTRY_CODE)) {
            currentItem = currentItem.set(constants.USE_COUNTRY_CODE, false);
          }
          if (item.get(constants.REMOVE_LEADING_ZERO)) {
            currentItem = currentItem.set(constants.REMOVE_LEADING_ZERO, false);
          }
          return currentItem;
        });

        newState = state.setIn([constants.CUSTOMER_PORTAL_DATA, constants.COUNTRIES_DATA], newCountriesData);
      }


      break;
    }
    case constants.REMOVE_CUSTOM_MENU_ITEM: {
      const { index } = action.payload;

      const menus = state.getIn([constants.CUSTOMER_PORTAL_DATA, constants.DATA_MENU]).splice(index, 1);
      newState = state.setIn([constants.CUSTOMER_PORTAL_DATA, constants.DATA_MENU], menus);

      break;
    }
    case constants.ADD_LANGUAGE: {
      const { selectedLanguages, countryIndex } = action.payload;
      newState = state.setIn([constants.CUSTOMER_PORTAL_DATA, constants.COUNTRIES_DATA, countryIndex, constants.LANGUAGE_DATA], fromJS(selectedLanguages));

      //sync languages with translations
      const configuration = newState.get(constants.CUSTOMER_PORTAL_DATA);
      const selectedCountries = configuration.get(constants.COUNTRIES_DATA);
      let translations = configuration.get(constants.TRANSLATIONS).toJS();

      let currentMenus = configuration.get(constants.DATA_MENU).toJS();
      let selectedLanguagesForTranslation = [];

      // Get all the selected languages
      if(selectedCountries && selectedCountries.size > 0) {
        selectedCountries.forEach((country) => {
          country.get(constants.LANGUAGE_DATA).map((language, index) => {
            if (!selectedLanguagesForTranslation.some(item => item === language)) {
              selectedLanguagesForTranslation.push(language.get(constants.CODE));
            }
          });
        });
      }

      //Remove any language that doesn't exist in the selected languages
      Object.keys(translations).forEach(languageCode => {
        if (!selectedLanguagesForTranslation.includes(languageCode)) {
          delete translations[languageCode];
        }
      });

      //Add any languages from selectedLanguagesForTranslation that are NOT in translations
      selectedLanguagesForTranslation.forEach(languageCode => {
        if (!translations[languageCode]) {
          translations[languageCode] = {
            [constants.POINT_NAME]: configuration.get(constants.POINT_NAME),
            [constants.POINT_NAME_PLURAL]: configuration.get(constants.POINT_NAME_PLURAL),
            [constants.CREDIT_NAME]: configuration.get(constants.CREDIT_NAME),
            [constants.CREDIT_NAME_PLURAL]: configuration.get(constants.CREDIT_NAME_PLURAL),
            [constants.LOGIN_SCREEN_TEXT]: configuration.get(constants.LOGIN_SCREEN_TEXT),
            [constants.SPLASH_SCREEN_TEXT]: configuration.get(constants.SPLASH_SCREEN_TEXT),
            [constants.AFTER_LOGIN_TEXT]: configuration.get(constants.AFTER_LOGIN_TEXT),
            [constants.SIGN_IN_PAGE_CTA]: configuration.get(constants.SIGN_IN_PAGE_CTA),
            [constants.BEFORE_LOGIN_CTA]: configuration.get(constants.BEFORE_LOGIN_CTA),
            [constants.SIGN_UP_PAGE_CTA]: configuration.get(constants.SIGN_UP_PAGE_CTA),
            [constants.AFTER_LOGIN_CTA]:  configuration.get(constants.AFTER_LOGIN_CTA),

            // rewards from menu
            [constants.NO_REWARD_TEXT]: configuration.get(constants.NO_REWARD_TEXT),

            // friend referral
            [constants.FRIEND_REFERRAL_PRE_HEAD_LINE]: configuration.getIn([constants.FRIEND_REFERRAL_TYPE, constants.FRIEND_REFERRAL_PRE_HEAD_LINE]),
            [constants.FRIEND_REFERRAL_MAIN_HEAD_LINE]: configuration.getIn([constants.FRIEND_REFERRAL_TYPE, constants.FRIEND_REFERRAL_MAIN_HEAD_LINE]),
            [constants.FRIEND_REFERRAL_SUB_HEAD_LINE]: configuration.getIn([constants.FRIEND_REFERRAL_TYPE, constants.FRIEND_REFERRAL_SUB_HEAD_LINE]),
            [constants.FRIEND_REFERRAL_HOW_IT_WORKS_TEXT]: configuration.getIn([constants.FRIEND_REFERRAL_TYPE, constants.FRIEND_REFERRAL_HOW_IT_WORKS_TEXT]),
            [constants.FRIEND_REFERRAL_STEP_1]: configuration.getIn([constants.FRIEND_REFERRAL_TYPE, constants.FRIEND_REFERRAL_STEP_1]),
            [constants.FRIEND_REFERRAL_STEP_2]: configuration.getIn([constants.FRIEND_REFERRAL_TYPE, constants.FRIEND_REFERRAL_STEP_2]),
            [constants.FRIEND_REFERRAL_STEP_3]: configuration.getIn([constants.FRIEND_REFERRAL_TYPE, constants.FRIEND_REFERRAL_STEP_3]),
            [constants.FRIEND_REFERRAL_SHARE_TEXT]: configuration.getIn([constants.FRIEND_REFERRAL_TYPE, constants.FRIEND_REFERRAL_SHARE_TEXT]),
          };
        }
      });

      //add any language from `selectedLanguagesForTranslation` that are NOT in menu translations
      currentMenus.forEach(menuItem => {
        const { translations: menuTranslations } = menuItem;

        // Remove extra languages from menu translations
        Object.keys(menuTranslations).forEach(languageCode => {
          if (!selectedLanguagesForTranslation.includes(languageCode)) {
            delete menuTranslations[languageCode];
          }
        });

        // Add missing languages to menu translations
        selectedLanguagesForTranslation.forEach(languageCode => {
          if (!menuTranslations[languageCode]) {
            menuTranslations[languageCode] = { name: menuItem.name };
          }
        });
      });

      newState = newState.setIn([constants.CUSTOMER_PORTAL_DATA, constants.TRANSLATIONS], fromJS(translations))
        .setIn([constants.CUSTOMER_PORTAL_DATA, constants.DATA_MENU], fromJS(currentMenus));

      break;
    }
    case constants.REMOVE_DEFAULT_LANGUAGE: {
      const { countryIndex } = action.payload;
      newState = state.setIn([constants.CUSTOMER_PORTAL_DATA, constants.COUNTRIES_DATA, countryIndex, constants.DEFAULT_LANGUAGE_DATA], null);
      break;
    }
    case constants.ADD_DEFAULT_LANGUAGE: {
      const { countryIndex, name, code, direction } = action.payload;
      newState = state.setIn([constants.CUSTOMER_PORTAL_DATA, constants.COUNTRIES_DATA, countryIndex, constants.DEFAULT_LANGUAGE_DATA], fromJS({ name, code, direction }));
      break;
    }
    case constants.ADD_COUNTRIES: {
      const { selectedCountries, countryIndex } = action.payload;
      if (selectedCountries && selectedCountries.length > 0) {
        newState = state.setIn([constants.CUSTOMER_PORTAL_DATA, constants.COUNTRIES_DATA, countryIndex], fromJS(selectedCountries[0]));
      }
      break;
    }
    case constants.CHANGE_DEFAULT_COUNTRY: {
      const { countryIndex, isDefault } = action.payload;

      const countriesData = state.getIn([constants.CUSTOMER_PORTAL_DATA, constants.COUNTRIES_DATA]);

      // Allow only one default country
      const newCountriesData = countriesData.map((item, index) => {
        if (index === countryIndex) {
          return item.set(constants.DEFAULT_COUNTRY_DATA, isDefault);
        }

        return item.set(constants.DEFAULT_COUNTRY_DATA, false);
      });

      newState = state.setIn([constants.CUSTOMER_PORTAL_DATA, constants.COUNTRIES_DATA], newCountriesData);
      break;
    }
    case constants.CHANGE_REMOVE_LEADING_ZERO: {
      const { countryIndex, value } = action.payload;

      const countriesData = state.getIn([constants.CUSTOMER_PORTAL_DATA, constants.COUNTRIES_DATA]);

      const newCountriesData = countriesData.map((item, index) => {
        if (index === countryIndex) {
          return item.set(constants.REMOVE_LEADING_ZERO, value);
        }
        return item;
      });

      newState = state.setIn([constants.CUSTOMER_PORTAL_DATA, constants.COUNTRIES_DATA], newCountriesData);
      break;
    }
    case constants.CHANGE_USE_COUNTRY_CODE: {
      const { countryIndex, value } = action.payload;

      const countriesData = state.getIn([constants.CUSTOMER_PORTAL_DATA, constants.COUNTRIES_DATA]);

      const newCountriesData = countriesData.map((item, index) => {
        let currentItem = item;
        if (index === countryIndex) {
          currentItem = currentItem.set(constants.USE_COUNTRY_CODE, value);
          if (value) { // mark remove leading zero when use county code is checked off
            currentItem = currentItem.set(constants.REMOVE_LEADING_ZERO, value);
          }
        }
        return currentItem;
      });

      newState = state.setIn([constants.CUSTOMER_PORTAL_DATA, constants.COUNTRIES_DATA], newCountriesData);
      break;
    }
    case constants.REMOVE_COUNTRY: {
      const { countryIndex } = action.payload;
      const countries = state.getIn([constants.CUSTOMER_PORTAL_DATA, constants.COUNTRIES_DATA]).splice(countryIndex, 1);

      if(countries.size > 0 && !countries.some(country => country.get(constants.DEFAULT_COUNTRY_DATA))) {
        const updatedCountries = countries.setIn([0, constants.DEFAULT_COUNTRY_DATA], true);
        newState = state.setIn([constants.CUSTOMER_PORTAL_DATA, constants.COUNTRIES_DATA], updatedCountries);
        break;
      }

      newState = state.setIn([constants.CUSTOMER_PORTAL_DATA, constants.COUNTRIES_DATA], countries);
      break;
    }
    case constants.ADD_DEFAULT_COUNTRY: {
      const { name, countryCode } = action.payload;
      newState = state.setIn([constants.CUSTOMER_PORTAL_DATA, constants.DEFAULT_COUNTRY_DATA], fromJS({ name, countryCode }));
      break;
    }
    case constants.CONFIGURATION_SAVE_SUCCESS: {
      newState = state.set(constants.SAVED_CONFIGURATION, false);
      break;
    }
    case constants.SAVING_CONFIGURATION: {
      newState = state.set(constants.SAVED_CONFIGURATION, true);
      break;
    }
    case constants.SAVE_TOAST_OPENED: {
      newState = state.set(constants.TOAST_IS_OPEN, action.isOpened);
      break;
    }
    case constants.VALIDATION_ERROR: {
      newState = state.set(constants.ERRORS, action.errors);
      break;
    }
    case constants.REMOVE_VALIDATION_ERROR: {
      newState = state.delete(constants.ERRORS);
      break;
    }
    default:
      newState = state;
  }


  return newState;

}

export default customerPortalReducer;
