import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { Button, Icon } from 'semantic-ui-react';
import Toast from '@como/web-hub-components/src/Toast/Toast';

import * as schemaConstants from '../../../../activitySchema/activitySchema.constants';
import * as appSelectors from '../../../../../App/selectors';
import { STRING_TYPE } from '../../../../../../common/components/FormInputFields/inputConstants';
import InputWrapper from '../../../../../../common/components/FormInputFields/InputWrapper/InputWrapper';
import injectSaga from '../../../../../../utils/injectSaga';
import * as reducerConstants from '../../../../../../constants/reducer.constants';
import saga from '../../../../activity.saga';
import * as validationUtils from '../../../../reducers/utils/validations/validation.utils';
import './testToMyself.styles.less';
import * as activitySelectors from '../../../../activity.selectors';
import * as microCampaignSelectors from '../../../../../MicroCampaign/microCampaign.selectors';
import * as appConstants from '../../../../../App/app.constants';
import * as activityActions from '../../../../activity.actions';
import * as activityConstants from '../../../../activity.constants';


export class TestToMyself extends React.PureComponent {

  // eslint-disable-next-line no-undef
  static propTypes = {
    actionType: PropTypes.string.isRequired,
    user: PropTypes.object,
    actionIndex: PropTypes.number.isRequired,
    actionsWrapperIndex: PropTypes.number.isRequired,
    submitActionToCustomer: PropTypes.func,
    intl: PropTypes.object,
    testCompleted: PropTypes.bool,
    testSucceeded: PropTypes.bool,
    testInProgress: PropTypes.bool,
    actions: PropTypes.object,
    actionPath: PropTypes.object,
    microCampaignActionPath: PropTypes.object,
    testInProgressIndex: PropTypes.number,
    testInProgressWrapperIndex: PropTypes.number,
    label: PropTypes.string
  };

  constructor(props) {
    super(props);
    this.state = {
      value: '',
      inputValid: true
    };
  }

  componentWillMount() {
    this.setState({ value: this.getInputValueFromUser() });
  }

  getInputValueFromUser = () => {
    switch (this.props.actionType) {
      case schemaConstants.SEND_MEMBER_SMS_ACTION:
        return this.props.user.get(appConstants.USER_PHONE_NUMBER);
      case schemaConstants.SEND_MEMBER_EMAIL_ACTION:
        return this.props.user.get(appConstants.USER_EMAIL_ADDRESS);
      default:
        return '';
    }
  };

  onInputChange = (event) => {
    this.setState({ value: event.target.value });
  };

  onSubmitPress = () => {
    this.setState({
      inputValid: true
    });
    let inputValid;
    switch (this.props.actionType) {
      case schemaConstants.SEND_MEMBER_SMS_ACTION: {
        inputValid = validationUtils.validatePhoneNumber(this.state.value);
        this.setState({ inputValid });
        break;
      }
      case schemaConstants.SEND_MEMBER_EMAIL_ACTION: {
        inputValid = validationUtils.validateEmailAddress(this.state.value);
        this.setState({ inputValid });
        break;
      }
      case schemaConstants.SEND_PERSONAL_PUSH_ACTION:
      case schemaConstants.SEND_PERSONAL_WEB_PUSH_ACTION: {
        inputValid = validationUtils.validatePhoneNumber(this.state.value);
        this.setState({ inputValid });
        break;
      }
      default:
        break;
    }
    if (inputValid) {
      this.props.submitActionToCustomer(this.props.user, this.state.value, this.props.actionsWrapperIndex, this.props.actionIndex, this.props.actionType);
    }
  };

  handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      return false;
    }
  };

  resetToast = () => {
    this.props.actions.clearTestSucceededFlag();
  };

  render() {
    const { formatMessage } = this.props.intl; // eslint-disable-line react/prop-types
    const toastIsOpen = !(this.props.microCampaignActionPath && this.props.microCampaignActionPath.size > 0) ? this.props.testSucceeded
      && this.props.actionPath.get(activityConstants.ACTIONS_WRAPPER_INDEX) === this.props.actionsWrapperIndex
      && this.props.actionPath.get(activityConstants.ACTION_INDEX) === this.props.actionIndex :
      this.props.testSucceeded
      && this.props.microCampaignActionPath.get(activityConstants.ACTIONS_WRAPPER_INDEX) === this.props.actionsWrapperIndex
      && this.props.microCampaignActionPath.get(activityConstants.ACTION_INDEX) === this.props.actionIndex;
    const shouldLoaderBeShown = this.props.testInProgress && this.props.testInProgressWrapperIndex == this.props.actionsWrapperIndex && this.props.testInProgressIndex == this.props.actionIndex;

    return (
      <div className="test-to-myself">
        <span>{formatMessage({ id: this.props.label || `test.to.myself.${this.props.actionType}` })}</span>
        <InputWrapper
          className={`test-to-myself-input${!this.state.inputValid ? ' error' : ''}`}
          disabled={this.props.testSucceeded}
          name="testToMyselfInput"
          onChange={this.onInputChange}
          prefix="test-to-myself-input"
          type={STRING_TYPE}
          value={this.state.value}
          error={!this.state.inputValid ? `testToMyself.error.${this.props.actionType}` : null}
          placeholder={formatMessage({ id: `test.to.myself.placeholder.${this.props.actionType}` })}
          onKeyDown={this.handleKeyPress}
        />
        <Button
          data-automation-id="test-to-myself-submit"
          basic
          className="link"
          onClick={this.onSubmitPress}
          disabled={shouldLoaderBeShown}
          loading={shouldLoaderBeShown}
        >
          {!shouldLoaderBeShown && <Icon className="como-ic-blue-copy"/>}

        </Button>
        <Toast
          intl={this.props.intl}
          className="test-to-myself-toast"
          isOpen={toastIsOpen}
          messageKey={'test.to.myself.action.in.progress'}
          onClose={this.resetToast}
        />
        <p className="note">
          {this.props.actionType === schemaConstants.SEND_PERSONAL_PUSH_ACTION ? formatMessage({ id: 'test.to.myself.push.note' }) : formatMessage({ id: 'test.to.myself.note' })}
        </p>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: appSelectors.getUser(state),
  testCompleted: activitySelectors.testToMySelfCompleted(state),
  testSucceeded: activitySelectors.testToMySelfSucceeded(state),
  testInProgress: activitySelectors.testToMySelfInProgress(state),
  testInProgressWrapperIndex: activitySelectors.testToMySelfInProgressWrapperIndex(state),
  testInProgressIndex: activitySelectors.testToMySelfInProgressIndex(state),
  actionPath: activitySelectors.getTestedActionPath(state),
  microCampaignActionPath: microCampaignSelectors.getMicroCampaignTestToMyselfActionPath(state)
});

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(activityActions, dispatch)
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);
const withSaga = injectSaga({ key: reducerConstants.ACTIVITY_BRANCH, saga });

export default compose(withConnect, withSaga, injectIntl)(TestToMyself);
