import * as constants from './activitySchema.constants';
import * as appConstants from '../../App/app.constants';
import * as schemaOperators from './activitySchema.operators';

export const totalSum = {
  key: constants.CONTEXT_PURCHASE_TOTAL_SUM,
  valueType: constants.VALUE_TYPE_CURRENCY,
  operators: schemaOperators.currencyOperators,
  dataValueType: constants.DATA_VALUE_TYPE_INTEGER,
};

export const friendPurchaseTotalSum = {
  key: constants.CONTEXT_FRIEND_PURCHASE_TOTAL_SUM,
  valueType: constants.VALUE_TYPE_CURRENCY,
  operators: schemaOperators.currencyOperators,
  dataValueType: constants.DATA_VALUE_TYPE_INTEGER,
};

export const purchaseBranchId = {
  key: constants.CONTEXT_PURCHASE_BRANCH_ID,
  valueType: constants.VALUE_TYPE_ARRAY,
  operators: schemaOperators.arrayOperators,
  dataValueType: constants.DATA_VALUE_TYPE_STRING_ARRAY,
};
export const purchaseLocationsGroups = {
  key: constants.CONTEXT_LOCATIONS_GROUPS,
  valueType: constants.DATA_VALUE_TYPE_LOCATIONS_GROUPS,
  operators: schemaOperators.arrayOperators,
  dataValueType: constants.DATA_VALUE_TYPE_STRING_ARRAY,
  features: [{ name: appConstants.FEATURES_LOCATIONS_GROUPS, hide: true }],
};

export const membership_preferredLocation = {
  key: constants.CONTEXT_MEMBERSHIP_PREFERRED_LOCATION,
  valueType: constants.VALUE_TYPE_LOCATIONS,
  operators: schemaOperators.arrayOperators,
  group: constants.MEMBERSHIP_GROUP,
  dataValueType: constants.DATA_VALUE_TYPE_STRING_ARRAY,
  isAutoTag: true
};

export const membership_preferredLocationsGroup = {
  key: constants.CONTEXT_MEMBERSHIP_PREFERRED_LOCATION_GROUP,
  valueType: constants.DATA_VALUE_TYPE_LOCATIONS_GROUPS,
  operators: schemaOperators.arrayOperators,
  group: constants.MEMBERSHIP_GROUP,
  dataValueType: constants.DATA_VALUE_TYPE_STRING_ARRAY,
  features: [{ name: appConstants.FEATURES_LOCATIONS_GROUPS, hide: true }, appConstants.FEATURE_BASIC_PLAN_EXCLUDE],
};
export const failSmsStep = {
  key: constants.CONTEXT_FAIL_SMS_STEP,
  valueType: constants.CONTEXT_FAIL_SMS_STEP,
  operators: schemaOperators.arrayOperators,
  dataValueType: constants.DATA_VALUE_TYPE_STRING_ARRAY,
};

export const failSmsType = {
  key: constants.CONTEXT_FAIL_SMS_TYPE,
  valueType: constants.CONTEXT_FAIL_SMS_TYPE,
  operators: schemaOperators.arrayOperators,
  dataValueType: constants.DATA_VALUE_TYPE_STRING_ARRAY,
};

export const branchId = {
  key: constants.CONDITION_KEY_BRANCH_ID,
  valueType: constants.VALUE_TYPE_ARRAY,
  operators: schemaOperators.arrayOperators,
  dataValueType: constants.DATA_VALUE_TYPE_STRING_ARRAY,
};

export const posId = {
  key: constants.CONTEXT_PURCHASE_POS_ID,
  valueType: constants.VALUE_TYPE_ARRAY,
  operators: schemaOperators.arrayOperators,
  dataValueType: constants.DATA_VALUE_TYPE_STRING_ARRAY,
};

export const numOfMembers = {
  key: constants.CONTEXT_PURCHASE_NUMBER_OF_MEMBERS,
  valueType: constants.VALUE_TYPE_NUMBER,
  operators: schemaOperators.numberOperators,
  dataValueType: constants.DATA_VALUE_TYPE_INTEGER
};

export const purchaseTags = {
  key: constants.CONTEXT_PURCHASE_TAGS,
  valueType: constants.VALUE_TYPE_ARRAY,
  operators: schemaOperators.arrayOperators,
  dataValueType: constants.DATA_VALUE_TYPE_STRING_ARRAY,
};

export const purchaseTransactionSource = {
  key: constants.CONTEXT_PURCHASE_TRANSACTION_SOURCE,
  valueType: constants.VALUE_TYPE_ARRAY,
  operators: schemaOperators.arrayOperators,
  dataValueType: constants.DATA_VALUE_TYPE_STRING_ARRAY,
};

export const purchaseTransactionSourceName = {
  key: constants.CONTEXT_PURCHASE_TRANSACTION_SOURCE_NAME,
  valueType: constants.VALUE_TYPE_ARRAY,
  operators: schemaOperators.arrayOperators,
  dataValueType: constants.DATA_VALUE_TYPE_STRING_ARRAY,
};

export const userActionTransactionSource = {
  key: 'context.UserAction.TransactionSource',
  valueType: constants.VALUE_TYPE_ARRAY,
  operators: schemaOperators.arrayOperators,
  dataValueType: constants.DATA_VALUE_TYPE_STRING_ARRAY,
};

export const userActionTransactionSourceName = {
  key: 'context.UserAction.TransactionSourceName',
  valueType: constants.VALUE_TYPE_ARRAY,
  operators: schemaOperators.arrayOperators,
  dataValueType: constants.DATA_VALUE_TYPE_STRING_ARRAY,
};

export const triggerEventSubmittedTransactionSource = {
  key: 'context.Data.Data.TransactionSource',
  valueType: constants.VALUE_TYPE_ARRAY,
  operators: schemaOperators.arrayOperators,
  dataValueType: constants.DATA_VALUE_TYPE_STRING_ARRAY,
};

export const triggerEventSubmittedTransactionSourceName = {
  key: 'context.Data.Data.TransactionSourceName',
  valueType: constants.VALUE_TYPE_ARRAY,
  operators: schemaOperators.arrayOperators,
  dataValueType: constants.DATA_VALUE_TYPE_STRING_ARRAY,
};

export const paymentFlow = {
  key: constants.CONTEXT_PAYMENT_FLOW,
  valueType: constants.VALUE_TYPE_PAYMENT_FLOW,
  operators: schemaOperators.stringOperatorsEnum,
  dataValueType: constants.DATA_VALUE_TYPE_STRING
};

export const paymentFlowInApp = {
  key: constants.CONTEXT_PAYMENT_FLOW,
  valueType: constants.VALUE_TYPE_PAYMENT_FLOW_IN_APP,
  operators: schemaOperators.stringOperatorsEnum,
  dataValueType: constants.DATA_VALUE_TYPE_STRING
};

export const filterPaymentFlow = {
  key: constants.CONTEXT_PAYMENT_FLOW,
  valueType: constants.FILTER_VALUE_TYPE_PAYMENT_FLOW_IN_APP,
  operators: schemaOperators.arrayOperators,
  dataValueType: constants.DATA_VALUE_TYPE_STRING_ARRAY
};

export const actualCharge = {
  key: constants.CONTEXT_ACTUAL_CHARGE,
  valueType: constants.VALUE_TYPE_CURRENCY,
  features: [{ name: appConstants.FEATURES_PAY_WITH_BUDGET }],
  operators: schemaOperators.currencyOperators,
  dataValueType: constants.DATA_VALUE_TYPE_INTEGER
};

export const itemPrice = {
  key: constants.CONTEXT_ITEM_PRICE,
  valueType: constants.VALUE_TYPE_CURRENCY,
  features: [{ name: appConstants.FEATURES_PAY_WITH_BUDGET }],
  operators: schemaOperators.currencyOperators,
  dataValueType: constants.DATA_VALUE_TYPE_INTEGER
};

export const benefit = {
  key: constants.CONTEXT_ASSET_TEMPLATE,
  valueType: constants.VALUE_TYPE_BENEFITS_LIST,
  operators: schemaOperators.stringOperatorsEnum,
  dataValueType: constants.DATA_VALUE_TYPE_STRING,
  usedOnceInFilterUserAction: true
};

export const redeemBenefit = {
  key: constants.CONTEXT_REDEEM_ASSET_TEMPLATE,
  valueType: constants.VALUE_TYPE_BENEFITS_LIST,
  operators: schemaOperators.stringOperatorsEnum,
  dataValueType: constants.DATA_VALUE_TYPE_STRING,
  usedOnceInFilterUserAction: true
};

export const punchBenefit = {
  key: constants.CONTEXT_PUNCH_ASSET_TEMPLATE,
  valueType: constants.VALUE_TYPE_BENEFITS_LIST,
  operators: schemaOperators.stringOperatorsEnum,
  dataValueType: constants.DATA_VALUE_TYPE_STRING,
  usedOnceInFilterUserAction: true
};
export const joinClubQuickRegistrationCode = {
  key: constants.JOIN_CLUB_FROM_QUICK_REGISTER_CODE,
  valueType: constants.VALUE_TYPE_STRING,
  operators: schemaOperators.booleanOperators,
  dataValueType: constants.DATA_VALUE_TYPE_STRING,
  usedOnceInFilterUserAction: true
};

export const benefitSource = {
  key: constants.CONTEXT_ASSET_DATA_SOURCE,
  valueType: constants.VALUE_TYPE_ASSET_SOURCE_LIST,
  operators: schemaOperators.arrayOperators,
  dataValueType: constants.DATA_VALUE_TYPE_STRING_ARRAY
};

export const TotalPunchesAfterOperation = {
  key: constants.CONTEXT_TOTAL_PUNCHES_AFTER_OPERATION,
  valueType: constants.VALUE_TYPE_NUMBER,
  operators: schemaOperators.numberOperators,
  dataValueType: constants.DATA_VALUE_TYPE_INTEGER
};

export const lastPunch = {
  key: constants.CONTEXT_LAST_PUNCH,
  valueType: constants.VALUE_TYPE_BOOLEAN,
  operators: schemaOperators.booleanOperators,
  dataValueType: constants.DATA_VALUE_TYPE_BOOLEAN
};

export const usedReferralCode = {
  key: constants.CONTEXT_USED_REFERRAL_CODE,
  valueType: constants.VALUE_TYPE_BOOLEAN,
  operators: schemaOperators.booleanOperators,
  dataValueType: constants.DATA_VALUE_TYPE_BOOLEAN
};

export const referralData = {
  key: constants.CONTEXT_REFERRAL_DATA,
  valueType: constants.VALUE_TYPE_REFERRAL_DATA,
  operators: schemaOperators.arrayOperators,
  dataValueType: constants.DATA_VALUE_BOOL_STRING_ARRAY
};

export const joinClubSource = {
  key: constants.CONTEXT_JOIN_SOURCE,
  valueType: constants.VALUE_TYPE_JOINING_SOURCE,
  operators: schemaOperators.arrayOperators,
  dataValueType: constants.DATA_VALUE_TYPE_STRING_ARRAY
};

export const numOfPoints = {
  key: constants.CONTEXT_DATA_AMOUNTS_POINTS,
  valueType: constants.VALUE_TYPE_POINTS,
  operators: schemaOperators.numberOperators,
  dataValueType: constants.DATA_VALUE_TYPE_INTEGER
};

export const creditUsed = {
  key: constants.CONTEXT_DATA_AMOUNTS_BUDGET_WEIGHTED,
  valueType: constants.VALUE_TYPE_POINTS,
  operators: schemaOperators.numberOperators,
  dataValueType: constants.DATA_VALUE_TYPE_INTEGER
};

export const pointsTotalSum = {
  key: constants.CONTEXT_DATA_NEW_POINTS_SET_POINTS,
  valueType: constants.VALUE_TYPE_POINTS,
  operators: schemaOperators.numberOperators,
  dataValueType: constants.DATA_VALUE_TYPE_INTEGER
};

export const totalCredit = {
  key: constants.CONTEXT_DATA_NEW_POINTS_SET_BUDGET_WEIGHTED,
  valueType: constants.VALUE_TYPE_POINTS,
  operators: schemaOperators.numberOperators,
  dataValueType: constants.DATA_VALUE_TYPE_INTEGER,
  features: [appConstants.FEATURE_ESSENTIALS_OFF]
};

export const catalogItems = {
  key: constants.CONTEXT_CATALOG_ITEM,
  valueType: constants.VALUE_TYPE_CATALOG_ITEMS_LIST,
  operators: schemaOperators.stringOperatorsEnum,
  dataValueType: constants.DATA_VALUE_TYPE_STRING
};

export const tag = {
  key: constants.CONTEXT_TAG,
  valueType: constants.VALUE_TYPE_ARRAY,
  operators: schemaOperators.arrayOperators,
  dataValueType: constants.DATA_VALUE_TYPE_STRING_ARRAY,
  autoCompleteOptions: appConstants.BUSINESS_TAGS
};

export const forms = {
  key: constants.CONTEXT_FORM_ID,
  valueType: constants.VALUE_TYPE_FORMS_LIST,
  operators: schemaOperators.stringOperatorsEnum,
  dataValueType: constants.DATA_VALUE_TYPE_INTEGER
};

export const beaconType = {
  key: constants.CONTEXT_DATA_SUB_TYPE,
  valueType: constants.VALUE_TYPE_BEACON_SIGNALS_LIST,
  operators: schemaOperators.stringOperatorsEnum,
  dataValueType: constants.DATA_VALUE_TYPE_STRING
};

export const uid = {
  key: constants.CONTEXT_DATA_DATA_UUID,
  valueType: constants.VALUE_TYPE_ARRAY,
  operators: schemaOperators.arrayOperators,
  dataValueType: constants.DATA_VALUE_TYPE_STRING_ARRAY
};

export const major = {
  key: constants.CONTEXT_DATA_DATA_MAJOR,
  valueType: constants.VALUE_TYPE_ARRAY,
  operators: schemaOperators.arrayOperators,
  dataValueType: constants.DATA_VALUE_TYPE_STRING_ARRAY
};

export const minor = {
  key: constants.CONTEXT_DATA_DATA_MINOR,
  valueType: constants.VALUE_TYPE_ARRAY,
  operators: schemaOperators.arrayOperators,
  dataValueType: constants.DATA_VALUE_TYPE_STRING_ARRAY
};

export const externalEventType = {
  key: constants.CONTEXT_DATA_TYPE,
  valueType: constants.VALUE_TYPE_ARRAY,
  operators: schemaOperators.arrayOperators,
  dataValueType: constants.DATA_VALUE_TYPE_STRING_ARRAY
};

export const externalEventSubtype = {
  key: constants.CONTEXT_DATA_SUB_TYPE,
  valueType: constants.VALUE_TYPE_ARRAY,
  operators: schemaOperators.arrayOperators,
  dataValueType: constants.DATA_VALUE_TYPE_STRING_ARRAY
};

export const externalEventString1 = {
  key: constants.CONTEXT_DATA_DATA_STRING_VALUE_1,
  valueType: constants.VALUE_TYPE_STRING,
  operators: schemaOperators.arrayOperators,
  dataValueType: constants.DATA_VALUE_TYPE_STRING_ARRAY
};

export const externalEventString2 = {
  key: constants.CONTEXT_DATA_DATA_STRING_VALUE_2,
  valueType: constants.VALUE_TYPE_ARRAY,
  operators: schemaOperators.arrayOperators,
  dataValueType: constants.DATA_VALUE_TYPE_STRING_ARRAY
};
export const externalEventString3 = {
  key: constants.CONTEXT_DATA_DATA_STRING_VALUE_3,
  valueType: constants.VALUE_TYPE_ARRAY,
  operators: schemaOperators.arrayOperators,
  dataValueType: constants.DATA_VALUE_TYPE_STRING_ARRAY
};
export const externalEventString4 = {
  key: constants.CONTEXT_DATA_DATA_STRING_VALUE_4,
  valueType: constants.VALUE_TYPE_ARRAY,
  operators: schemaOperators.arrayOperators,
  dataValueType: constants.DATA_VALUE_TYPE_STRING_ARRAY
};
export const externalEventString5 = {
  key: constants.CONTEXT_DATA_DATA_STRING_VALUE_5,
  valueType: constants.VALUE_TYPE_ARRAY,
  operators: schemaOperators.arrayOperators,
  dataValueType: constants.DATA_VALUE_TYPE_STRING_ARRAY
};
export const externalEventString6 = {
  key: constants.CONTEXT_DATA_DATA_STRING_VALUE_6,
  valueType: constants.VALUE_TYPE_ARRAY,
  operators: schemaOperators.arrayOperators,
  dataValueType: constants.DATA_VALUE_TYPE_STRING_ARRAY
};
export const externalEventString7 = {
  key: constants.CONTEXT_DATA_DATA_STRING_VALUE_7,
  valueType: constants.VALUE_TYPE_ARRAY,
  operators: schemaOperators.arrayOperators,
  dataValueType: constants.DATA_VALUE_TYPE_STRING_ARRAY
};
export const externalEventString8 = {
  key: constants.CONTEXT_DATA_DATA_STRING_VALUE_8,
  valueType: constants.VALUE_TYPE_ARRAY,
  operators: schemaOperators.arrayOperators,
  dataValueType: constants.DATA_VALUE_TYPE_STRING_ARRAY
};
export const externalEventString9 = {
  key: constants.CONTEXT_DATA_DATA_STRING_VALUE_9,
  valueType: constants.VALUE_TYPE_ARRAY,
  operators: schemaOperators.arrayOperators,
  dataValueType: constants.DATA_VALUE_TYPE_STRING_ARRAY
};
export const externalEventString10 = {
  key: constants.CONTEXT_DATA_DATA_STRING_VALUE_10,
  valueType: constants.VALUE_TYPE_ARRAY,
  operators: schemaOperators.arrayOperators,
  dataValueType: constants.DATA_VALUE_TYPE_STRING_ARRAY
};

export const externalEventNumber1 = {
  key: constants.CONTEXT_DATA_DATA_NUMERIC_VALUE_1,
  valueType: constants.VALUE_TYPE_NUMBER,
  operators: schemaOperators.numberOperators,
  dataValueType: constants.DATA_VALUE_TYPE_INTEGER
};

export const externalEventNumber2 = {
  key: constants.CONTEXT_DATA_DATA_NUMERIC_VALUE_2,
  valueType: constants.VALUE_TYPE_NUMBER,
  operators: schemaOperators.numberOperators,
  dataValueType: constants.DATA_VALUE_TYPE_INTEGER
};

export const externalEventNumber3 = {
  key: constants.CONTEXT_DATA_DATA_NUMERIC_VALUE_3,
  valueType: constants.VALUE_TYPE_NUMBER,
  operators: schemaOperators.numberOperators,
  dataValueType: constants.DATA_VALUE_TYPE_INTEGER
};

export const externalEventNumber4 = {
  key: constants.CONTEXT_DATA_DATA_NUMERIC_VALUE_4,
  valueType: constants.VALUE_TYPE_NUMBER,
  operators: schemaOperators.numberOperators,
  dataValueType: constants.DATA_VALUE_TYPE_INTEGER
};

export const externalEventNumber5 = {
  key: constants.CONTEXT_DATA_DATA_NUMERIC_VALUE_5,
  valueType: constants.VALUE_TYPE_NUMBER,
  operators: schemaOperators.numberOperators,
  dataValueType: constants.DATA_VALUE_TYPE_INTEGER
};

export const externalEventNumber6 = {
  key: constants.CONTEXT_DATA_DATA_NUMERIC_VALUE_6,
  valueType: constants.VALUE_TYPE_NUMBER,
  operators: schemaOperators.numberOperators,
  dataValueType: constants.DATA_VALUE_TYPE_INTEGER
};

export const externalEventNumber7 = {
  key: constants.CONTEXT_DATA_DATA_NUMERIC_VALUE_7,
  valueType: constants.VALUE_TYPE_NUMBER,
  operators: schemaOperators.numberOperators,
  dataValueType: constants.DATA_VALUE_TYPE_INTEGER
};

export const externalEventNumber8 = {
  key: constants.CONTEXT_DATA_DATA_NUMERIC_VALUE_8,
  valueType: constants.VALUE_TYPE_NUMBER,
  operators: schemaOperators.numberOperators,
  dataValueType: constants.DATA_VALUE_TYPE_INTEGER
};

export const externalEventNumber9 = {
  key: constants.CONTEXT_DATA_DATA_NUMERIC_VALUE_9,
  valueType: constants.VALUE_TYPE_NUMBER,
  operators: schemaOperators.numberOperators,
  dataValueType: constants.DATA_VALUE_TYPE_INTEGER
};

export const externalEventNumber10 = {
  key: constants.CONTEXT_DATA_DATA_NUMERIC_VALUE_10,
  valueType: constants.VALUE_TYPE_NUMBER,
  operators: schemaOperators.numberOperators,
  dataValueType: constants.DATA_VALUE_TYPE_INTEGER
};

export const externalEventDate1 = {
  key: constants.CONTEXT_DATA_DATA_DATE_VALUE_1,
  valueType: constants.VALUE_TYPE_DATE,
  operators: schemaOperators.dateOperators,
  dataValueType: constants.DATA_VALUE_TYPE_DATE
};

export const externalEventDate2 = {
  key: constants.CONTEXT_DATA_DATA_DATE_VALUE_2,
  valueType: constants.VALUE_TYPE_DATE,
  operators: schemaOperators.dateOperators,
  dataValueType: constants.DATA_VALUE_TYPE_DATE
};

export const externalEventDate3 = {
  key: constants.CONTEXT_DATA_DATA_DATE_VALUE_3,
  valueType: constants.VALUE_TYPE_DATE,
  operators: schemaOperators.dateOperators,
  dataValueType: constants.DATA_VALUE_TYPE_DATE
};

export const externalEventDate4 = {
  key: constants.CONTEXT_DATA_DATA_DATE_VALUE_4,
  valueType: constants.VALUE_TYPE_DATE,
  operators: schemaOperators.dateOperators,
  dataValueType: constants.DATA_VALUE_TYPE_DATE
};

export const externalEventDate5 = {
  key: constants.CONTEXT_DATA_DATA_DATE_VALUE_5,
  valueType: constants.VALUE_TYPE_DATE,
  operators: schemaOperators.dateOperators,
  dataValueType: constants.DATA_VALUE_TYPE_DATE
};

export const externalEventDate6 = {
  key: constants.CONTEXT_DATA_DATA_DATE_VALUE_6,
  valueType: constants.VALUE_TYPE_DATE,
  operators: schemaOperators.dateOperators,
  dataValueType: constants.DATA_VALUE_TYPE_DATE
};

export const externalEventDate7 = {
  key: constants.CONTEXT_DATA_DATA_DATE_VALUE_7,
  valueType: constants.VALUE_TYPE_DATE,
  operators: schemaOperators.dateOperators,
  dataValueType: constants.DATA_VALUE_TYPE_DATE
};

export const externalEventDate8 = {
  key: constants.CONTEXT_DATA_DATA_DATE_VALUE_8,
  valueType: constants.VALUE_TYPE_DATE,
  operators: schemaOperators.dateOperators,
  dataValueType: constants.DATA_VALUE_TYPE_DATE
};

export const externalEventDate9 = {
  key: constants.CONTEXT_DATA_DATA_DATE_VALUE_9,
  valueType: constants.VALUE_TYPE_DATE,
  operators: schemaOperators.dateOperators,
  dataValueType: constants.DATA_VALUE_TYPE_DATE
};

export const externalEventDate10 = {
  key: constants.CONTEXT_DATA_DATA_DATE_VALUE_10,
  valueType: constants.VALUE_TYPE_DATE,
  operators: schemaOperators.dateOperators,
  dataValueType: constants.DATA_VALUE_TYPE_DATE
};

export const externalEventBool1 = {
  key: constants.CONTEXT_DATA_DATA_BOOLEAN_VALUE_1,
  valueType: constants.VALUE_TYPE_BOOLEAN,
  operators: schemaOperators.booleanOperators,
  dataValueType: constants.DATA_VALUE_TYPE_BOOLEAN
};

export const externalEventBool2 = {
  key: constants.CONTEXT_DATA_DATA_BOOLEAN_VALUE_2,
  valueType: constants.VALUE_TYPE_BOOLEAN,
  operators: schemaOperators.booleanOperators,
  dataValueType: constants.DATA_VALUE_TYPE_BOOLEAN
};

export const externalEventBool3 = {
  key: constants.CONTEXT_DATA_DATA_BOOLEAN_VALUE_3,
  valueType: constants.VALUE_TYPE_BOOLEAN,
  operators: schemaOperators.booleanOperators,
  dataValueType: constants.DATA_VALUE_TYPE_BOOLEAN
};

export const externalEventBool4 = {
  key: constants.CONTEXT_DATA_DATA_BOOLEAN_VALUE_4,
  valueType: constants.VALUE_TYPE_BOOLEAN,
  operators: schemaOperators.booleanOperators,
  dataValueType: constants.DATA_VALUE_TYPE_BOOLEAN
};

export const externalEventBool5 = {
  key: constants.CONTEXT_DATA_DATA_BOOLEAN_VALUE_5,
  valueType: constants.VALUE_TYPE_BOOLEAN,
  operators: schemaOperators.booleanOperators,
  dataValueType: constants.DATA_VALUE_TYPE_BOOLEAN
};

export const externalEventBool6 = {
  key: constants.CONTEXT_DATA_DATA_BOOLEAN_VALUE_6,
  valueType: constants.VALUE_TYPE_BOOLEAN,
  operators: schemaOperators.booleanOperators,
  dataValueType: constants.DATA_VALUE_TYPE_BOOLEAN
};

export const externalEventBool7 = {
  key: constants.CONTEXT_DATA_DATA_BOOLEAN_VALUE_7,
  valueType: constants.VALUE_TYPE_BOOLEAN,
  operators: schemaOperators.booleanOperators,
  dataValueType: constants.DATA_VALUE_TYPE_BOOLEAN
};

export const externalEventBool8 = {
  key: constants.CONTEXT_DATA_DATA_BOOLEAN_VALUE_8,
  valueType: constants.VALUE_TYPE_BOOLEAN,
  operators: schemaOperators.booleanOperators,
  dataValueType: constants.DATA_VALUE_TYPE_BOOLEAN
};

export const externalEventBool9 = {
  key: constants.CONTEXT_DATA_DATA_BOOLEAN_VALUE_9,
  valueType: constants.VALUE_TYPE_BOOLEAN,
  operators: schemaOperators.booleanOperators,
  dataValueType: constants.DATA_VALUE_TYPE_BOOLEAN
};

export const externalEventBool10 = {
  key: constants.CONTEXT_DATA_DATA_BOOLEAN_VALUE_10,
  valueType: constants.VALUE_TYPE_BOOLEAN,
  operators: schemaOperators.booleanOperators,
  dataValueType: constants.DATA_VALUE_TYPE_BOOLEAN
};

export const shoppingCartQuantity = {
  key: constants.CONTEXT_SHOPPING_CART_TOTAL_QUANTITY,
  valueType: constants.VALUE_TYPE_NUMBER,
  operators: schemaOperators.shoppingCartOperators,
  features: [{ name: appConstants.FEATURES_SHOPPING_CART_SUBMIT_PURCHASE }],
  dataValueType: constants.DATA_VALUE_TYPE_INTEGER,
};

export const shoppingCartPositiveQuantity = {
  key: constants.CONTEXT_SHOPPING_CART_TOTAL_QUANTITY,
  valueType: constants.VALUE_TYPE_NUMBER,
  operators: schemaOperators.shoppingCartPositiveOperators,
  features: [{ name: appConstants.FEATURES_SHOPPING_CART_SUBMIT_PURCHASE }],
  dataValueType: constants.DATA_VALUE_TYPE_INTEGER,
};

/* export const friendShoppingCartPositiveQuantity = {
  key: constants.CONTEXT_FRIEND_SHOPPING_CART_TOTAL_QUANTITY,
  valueType: constants.VALUE_TYPE_NUMBER,
  operators: schemaOperators.shoppingCartPositiveOperators,
  features: [{ name: appConstants.FEATURES_SHOPPING_CART_SUBMIT_PURCHASE }],
  dataValueType: constants.DATA_VALUE_TYPE_INTEGER,
}; */

export const certainItemsExist = {
  key: constants.CONDITION_KEY_SHOPPING_CART_CERTAIN_ITEMS,
  valueType: constants.VALUE_TYPE_DEFAULT_FIXED_HIDDEN_VALUE,
  operators: [schemaOperators.greaterThanOrEqualTo],
  features: [{ name: appConstants.FEATURES_SHOPPING_CART_SUBMIT_PURCHASE }],
  dataValueType: constants.DATA_VALUE_TYPE_INTEGER,
  usedOnceInFilterUserAction: true
};

export const shoppingCartQuantityForBenefit = {
  key: constants.CONTEXT_SHOPPING_CART_TOTAL_QUANTITY,
  valueType: constants.VALUE_TYPE_NUMBER,
  operators: schemaOperators.shoppingCartOperators,
  features: [{ name: appConstants.FEATURES_SHOPPING_CART_GET_MEMBER_BENEFITS_AND_REDEEM }],
  dataValueType: constants.DATA_VALUE_TYPE_INTEGER,
};

export const meanOfPayment = {
  key: constants.CONTEXT_PAYMENT_TYPES,
  valueType: constants.VALUE_TYPE_ARRAY,
  operators: schemaOperators.arrayOperators,
  features: [{ name: appConstants.FEATURES_SHOPPING_CART_SUBMIT_PURCHASE }],
  dataValueType: constants.DATA_VALUE_TYPE_STRING_ARRAY,
};

export const shoppingCartPrice = {
  key: constants.CONTEXT_SHOPPING_CART_TOTAL_PRICE,
  valueType: constants.VALUE_TYPE_CURRENCY,
  operators: schemaOperators.currencyOperators,
  features: [{ name: appConstants.FEATURES_SHOPPING_CART_SUBMIT_PURCHASE }],
  dataValueType: constants.DATA_VALUE_TYPE_INTEGER,
};

export const shoppingCartPriceForBenefit = {
  key: constants.CONTEXT_SHOPPING_CART_TOTAL_PRICE,
  valueType: constants.VALUE_TYPE_CURRENCY,
  operators: schemaOperators.currencyOperators,
  features: [{ name: appConstants.FEATURES_SHOPPING_CART_GET_MEMBER_BENEFITS_AND_REDEEM }],
  dataValueType: constants.DATA_VALUE_TYPE_INTEGER,
};

export const itemCode = {
  key: constants.ITEM_CODE,
  valueType: constants.VALUE_TYPE_ARRAY,
  operators: schemaOperators.arrayOperators,
  features: [{ name: appConstants.FEATURES_SHOPPING_CART_SUBMIT_PURCHASE }],
  dataValueType: constants.DATA_VALUE_TYPE_STRING_ARRAY
};

export const price = {
  key: constants.ITEM_PRICE,
  valueType: constants.VALUE_TYPE_CURRENCY,
  operators: schemaOperators.currencyOperators,
  dataValueType: constants.DATA_VALUE_TYPE_INTEGER
};

export const departmentCode = {
  key: constants.ITEM_DEPARTMENT_CODE,
  valueType: constants.VALUE_TYPE_ARRAY,
  operators: schemaOperators.arrayOperators,
  dataValueType: constants.DATA_VALUE_TYPE_STRING_ARRAY
};

export const departmentName = {
  key: constants.ITEM_DEPARTMENT_NAME,
  valueType: constants.VALUE_TYPE_ARRAY,
  operators: schemaOperators.arrayOperators,
  dataValueType: constants.DATA_VALUE_TYPE_STRING_ARRAY
};

export const itemName = {
  key: constants.ITEM_NAME,
  valueType: constants.VALUE_TYPE_ARRAY,
  operators: schemaOperators.arrayOperators,
  dataValueType: constants.DATA_VALUE_TYPE_STRING_ARRAY
};

export const itemTag = {
  key: constants.ITEM_TAGS,
  valueType: constants.VALUE_TYPE_ARRAY,
  operators: schemaOperators.arrayOperators,
  dataValueType: constants.DATA_VALUE_TYPE_STRING_ARRAY
};

export const itemsGroup = {
  key: constants.ITEMS_GROUP,
  valueType: constants.VALUE_TYPE_ARRAY,
  operators: schemaOperators.arrayOperators,
  dataValueType: constants.DATA_VALUE_TYPE_STRING_ARRAY
};

export const benefitPrice = {
  key: constants.CONTEXT_SOURCE_DATA_BENEFITS_PRICE,
  valueType: constants.VALUE_TYPE_POINTS,
  operators: schemaOperators.numberOperators,
  dataValueType: constants.DATA_VALUE_TYPE_INTEGER
};

export const orderType = {
  key: constants.CONTEXT_PURCHASE_ORDER_TYPE,
  valueType: constants.VALUE_TYPE_ARRAY,
  operators: schemaOperators.arrayOperators,
  dataValueType: constants.DATA_VALUE_TYPE_STRING_ARRAY
};

export const codeTag = {
  key: constants.CODE_TAG,
  valueType: constants.VALUE_TYPE_ARRAY,
  operators: schemaOperators.arrayOperators,
  dataValueType: constants.DATA_VALUE_TYPE_STRING_ARRAY
};

export const operation = {
  key: constants.OPERATION,
  valueType: constants.VALUE_TYPE_UPDATE_MEMBERSHIP_OPERATION,
  operators: schemaOperators.booleanOperators,
  dataValueType: constants.DATA_VALUE_TYPE_STRING
};

export const itemCodeIpn = {
  key: constants.ITEM_CODE_IPN,
  valueType: constants.VALUE_TYPE_ARRAY,
  operators: schemaOperators.arrayOperators,
  features: [{ name: appConstants.FEATURES_SHOPPING_CART_SUBMIT_PURCHASE }],
  dataValueType: constants.DATA_VALUE_TYPE_STRING_ARRAY
};

export const tcConsentSource = {
  key: constants.CONTEXT_GAVE_CONSENT_SOURCE,
  valueType: constants.VALUE_TYPE_GAVE_CONSENT_SOURCE,
  operators: schemaOperators.arrayOperators,
  dataValueType: constants.DATA_VALUE_TYPE_STRING_ARRAY
};
export const gaveConsentSource = {
  key: constants.CONTEXT_GAVE_CONSENT_SOURCE,
  valueType: constants.VALUE_TYPE_GAVE_CONSENT_SOURCE,
  operators: schemaOperators.arrayOperators,
  dataValueType: constants.DATA_VALUE_TYPE_STRING_ARRAY
};

// membership conditions
export const membership_gender = {
  key: constants.CONTEXT_MEMBERSHIP_GENDER,
  valueType: constants.VALUE_TYPE_ARRAY,
  operators: schemaOperators.arrayOperators,
  group: constants.MEMBERSHIP_GROUP,
  dataValueType: constants.DATA_VALUE_TYPE_STRING_ARRAY,
  features: [appConstants.FEATURE_BASIC_PLAN_EXCLUDE]
};

export const membership_genericString1 = {
  key: constants.CONTEXT_MEMBERSHIP_GENERIC_STRING_1,
  valueType: constants.VALUE_TYPE_ARRAY,
  operators: schemaOperators.arrayOperators,
  group: constants.MEMBERSHIP_GROUP,
  dataValueType: constants.DATA_VALUE_TYPE_STRING_ARRAY,
  features: [appConstants.FEATURE_BASIC_PLAN_EXCLUDE]
};

export const membership_genericString2 = {
  key: constants.CONTEXT_MEMBERSHIP_GENERIC_STRING_2,
  valueType: constants.VALUE_TYPE_ARRAY,
  operators: schemaOperators.arrayOperators,
  group: constants.MEMBERSHIP_GROUP,
  dataValueType: constants.DATA_VALUE_TYPE_STRING_ARRAY,
  features: [appConstants.FEATURE_BASIC_PLAN_EXCLUDE]
};

export const membership_genericString3 = {
  key: constants.CONTEXT_MEMBERSHIP_GENERIC_STRING_3,
  valueType: constants.VALUE_TYPE_ARRAY,
  operators: schemaOperators.arrayOperators,
  group: constants.MEMBERSHIP_GROUP,
  dataValueType: constants.DATA_VALUE_TYPE_STRING_ARRAY,
  features: [appConstants.FEATURE_BASIC_PLAN_EXCLUDE]
};

export const membership_genericString4 = {
  key: constants.CONTEXT_MEMBERSHIP_GENERIC_STRING_4,
  valueType: constants.VALUE_TYPE_ARRAY,
  operators: schemaOperators.arrayOperators,
  group: constants.MEMBERSHIP_GROUP,
  dataValueType: constants.DATA_VALUE_TYPE_STRING_ARRAY,
  features: [appConstants.FEATURE_BASIC_PLAN_EXCLUDE]
};

export const membership_genericString5 = {
  key: constants.CONTEXT_MEMBERSHIP_GENERIC_STRING_5,
  valueType: constants.VALUE_TYPE_ARRAY,
  operators: schemaOperators.arrayOperators,
  group: constants.MEMBERSHIP_GROUP,
  dataValueType: constants.DATA_VALUE_TYPE_STRING_ARRAY,
  features: [appConstants.FEATURE_BASIC_PLAN_EXCLUDE]
};

export const membership_genericString6 = {
  key: constants.CONTEXT_MEMBERSHIP_GENERIC_STRING_6,
  valueType: constants.VALUE_TYPE_ARRAY,
  operators: schemaOperators.arrayOperators,
  group: constants.MEMBERSHIP_GROUP,
  dataValueType: constants.DATA_VALUE_TYPE_STRING_ARRAY,
  features: [appConstants.FEATURE_BASIC_PLAN_EXCLUDE]
};

export const membership_genericString7 = {
  key: constants.CONTEXT_MEMBERSHIP_GENERIC_STRING_7,
  valueType: constants.VALUE_TYPE_ARRAY,
  operators: schemaOperators.arrayOperators,
  group: constants.MEMBERSHIP_GROUP,
  dataValueType: constants.DATA_VALUE_TYPE_STRING_ARRAY,
  features: [appConstants.FEATURE_BASIC_PLAN_EXCLUDE]
};

export const membership_genericString8 = {
  key: constants.CONTEXT_MEMBERSHIP_GENERIC_STRING_8,
  valueType: constants.VALUE_TYPE_ARRAY,
  operators: schemaOperators.arrayOperators,
  group: constants.MEMBERSHIP_GROUP,
  dataValueType: constants.DATA_VALUE_TYPE_STRING_ARRAY,
  features: [appConstants.FEATURE_BASIC_PLAN_EXCLUDE]
};

export const membership_genericString9 = {
  key: constants.CONTEXT_MEMBERSHIP_GENERIC_STRING_9,
  valueType: constants.VALUE_TYPE_ARRAY,
  operators: schemaOperators.arrayOperators,
  group: constants.MEMBERSHIP_GROUP,
  dataValueType: constants.DATA_VALUE_TYPE_STRING_ARRAY,
  features: [appConstants.FEATURE_BASIC_PLAN_EXCLUDE]
};

export const membership_genericString10 = {
  key: constants.CONTEXT_MEMBERSHIP_GENERIC_STRING_10,
  valueType: constants.VALUE_TYPE_ARRAY,
  operators: schemaOperators.arrayOperators,
  group: constants.MEMBERSHIP_GROUP,
  dataValueType: constants.DATA_VALUE_TYPE_STRING_ARRAY,
  features: [appConstants.FEATURE_BASIC_PLAN_EXCLUDE]
};

export const membership_genericInteger1 = {
  key: constants.CONTEXT_MEMBERSHIP_GENERIC_INTEGER_1,
  valueType: constants.VALUE_TYPE_NUMBER,
  operators: schemaOperators.numberOperators,
  group: constants.MEMBERSHIP_GROUP,
  dataValueType: constants.DATA_VALUE_TYPE_INTEGER,
  features: [appConstants.FEATURE_BASIC_PLAN_EXCLUDE]
};

export const membership_genericInteger2 = {
  key: constants.CONTEXT_MEMBERSHIP_GENERIC_INTEGER_2,
  valueType: constants.VALUE_TYPE_NUMBER,
  operators: schemaOperators.numberOperators,
  group: constants.MEMBERSHIP_GROUP,
  dataValueType: constants.DATA_VALUE_TYPE_INTEGER,
  features: [appConstants.FEATURE_BASIC_PLAN_EXCLUDE]
};

export const membership_genericInteger3 = {
  key: constants.CONTEXT_MEMBERSHIP_GENERIC_INTEGER_3,
  valueType: constants.VALUE_TYPE_NUMBER,
  operators: schemaOperators.numberOperators,
  group: constants.MEMBERSHIP_GROUP,
  dataValueType: constants.DATA_VALUE_TYPE_INTEGER,
  features: [appConstants.FEATURE_BASIC_PLAN_EXCLUDE]
};

export const membership_genericInteger4 = {
  key: constants.CONTEXT_MEMBERSHIP_GENERIC_INTEGER_4,
  valueType: constants.VALUE_TYPE_NUMBER,
  operators: schemaOperators.numberOperators,
  group: constants.MEMBERSHIP_GROUP,
  dataValueType: constants.DATA_VALUE_TYPE_INTEGER,
  features: [appConstants.FEATURE_BASIC_PLAN_EXCLUDE]
};

export const membership_genericInteger5 = {
  key: constants.CONTEXT_MEMBERSHIP_GENERIC_INTEGER_5,
  valueType: constants.VALUE_TYPE_NUMBER,
  operators: schemaOperators.numberOperators,
  group: constants.MEMBERSHIP_GROUP,
  dataValueType: constants.DATA_VALUE_TYPE_INTEGER,
  features: [appConstants.FEATURE_BASIC_PLAN_EXCLUDE]
};

export const membership_genericInteger6 = {
  key: constants.CONTEXT_MEMBERSHIP_GENERIC_INTEGER_6,
  valueType: constants.VALUE_TYPE_NUMBER,
  operators: schemaOperators.numberOperators,
  group: constants.MEMBERSHIP_GROUP,
  dataValueType: constants.DATA_VALUE_TYPE_INTEGER,
  features: [appConstants.FEATURE_BASIC_PLAN_EXCLUDE]
};

export const membership_genericInteger7 = {
  key: constants.CONTEXT_MEMBERSHIP_GENERIC_INTEGER_7,
  valueType: constants.VALUE_TYPE_NUMBER,
  operators: schemaOperators.numberOperators,
  group: constants.MEMBERSHIP_GROUP,
  dataValueType: constants.DATA_VALUE_TYPE_INTEGER,
  features: [appConstants.FEATURE_BASIC_PLAN_EXCLUDE]
};

export const membership_genericInteger8 = {
  key: constants.CONTEXT_MEMBERSHIP_GENERIC_INTEGER_8,
  valueType: constants.VALUE_TYPE_NUMBER,
  operators: schemaOperators.numberOperators,
  group: constants.MEMBERSHIP_GROUP,
  dataValueType: constants.DATA_VALUE_TYPE_INTEGER,
  features: [appConstants.FEATURE_BASIC_PLAN_EXCLUDE]
};

export const membership_genericInteger9 = {
  key: constants.CONTEXT_MEMBERSHIP_GENERIC_INTEGER_9,
  valueType: constants.VALUE_TYPE_NUMBER,
  operators: schemaOperators.numberOperators,
  group: constants.MEMBERSHIP_GROUP,
  dataValueType: constants.DATA_VALUE_TYPE_INTEGER,
  features: [appConstants.FEATURE_BASIC_PLAN_EXCLUDE]
};

export const membership_genericInteger10 = {
  key: constants.CONTEXT_MEMBERSHIP_GENERIC_INTEGER_10,
  valueType: constants.VALUE_TYPE_NUMBER,
  operators: schemaOperators.numberOperators,
  group: constants.MEMBERSHIP_GROUP,
  dataValueType: constants.DATA_VALUE_TYPE_INTEGER,
  features: [appConstants.FEATURE_BASIC_PLAN_EXCLUDE]
};

export const membership_allowSMS = {
  key: constants.CONTEXT_MEMBERSHIP_ALLOW_SMS,
  valueType: constants.VALUE_TYPE_BOOLEAN,
  operators: schemaOperators.booleanOperators,
  group: constants.MEMBERSHIP_GROUP,
  dataValueType: constants.DATA_VALUE_TYPE_BOOLEAN
};

export const membership_homeBranchID = {
  key: constants.CONTEXT_MEMBERSHIP_HOME_BRANCH_ID,
  valueType: constants.VALUE_TYPE_ARRAY,
  operators: schemaOperators.arrayOperators,
  group: constants.MEMBERSHIP_GROUP,
  dataValueType: constants.DATA_VALUE_TYPE_STRING_ARRAY,
  features: [appConstants.FEATURE_BASIC_PLAN_EXCLUDE]
};

export const membership_genericCheckBox1 = {
  key: constants.CONTEXT_MEMBERSHIP_GENERIC_CHECKBOX_1,
  valueType: constants.VALUE_TYPE_BOOLEAN,
  operators: schemaOperators.booleanOperators,
  group: constants.MEMBERSHIP_GROUP,
  dataValueType: constants.DATA_VALUE_TYPE_BOOLEAN,
  features: [appConstants.FEATURE_BASIC_PLAN_EXCLUDE]
};

export const membership_genericCheckBox2 = {
  key: constants.CONTEXT_MEMBERSHIP_GENERIC_CHECKBOX_2,
  valueType: constants.VALUE_TYPE_BOOLEAN,
  operators: schemaOperators.booleanOperators,
  group: constants.MEMBERSHIP_GROUP,
  dataValueType: constants.DATA_VALUE_TYPE_BOOLEAN,
  features: [appConstants.FEATURE_BASIC_PLAN_EXCLUDE]
};

export const membership_genericCheckBox3 = {
  key: constants.CONTEXT_MEMBERSHIP_GENERIC_CHECKBOX_3,
  valueType: constants.VALUE_TYPE_BOOLEAN,
  operators: schemaOperators.booleanOperators,
  group: constants.MEMBERSHIP_GROUP,
  dataValueType: constants.DATA_VALUE_TYPE_BOOLEAN,
  features: [appConstants.FEATURE_BASIC_PLAN_EXCLUDE]
};

export const membership_genericCheckBox4 = {
  key: constants.CONTEXT_MEMBERSHIP_GENERIC_CHECKBOX_4,
  valueType: constants.VALUE_TYPE_BOOLEAN,
  operators: schemaOperators.booleanOperators,
  group: constants.MEMBERSHIP_GROUP,
  dataValueType: constants.DATA_VALUE_TYPE_BOOLEAN,
  features: [appConstants.FEATURE_BASIC_PLAN_EXCLUDE]
};

export const membership_genericCheckBox5 = {
  key: constants.CONTEXT_MEMBERSHIP_GENERIC_CHECKBOX_5,
  valueType: constants.VALUE_TYPE_BOOLEAN,
  operators: schemaOperators.booleanOperators,
  group: constants.MEMBERSHIP_GROUP,
  dataValueType: constants.DATA_VALUE_TYPE_BOOLEAN,
  features: [appConstants.FEATURE_BASIC_PLAN_EXCLUDE]
};

export const membership_genericCheckBox6 = {
  key: constants.CONTEXT_MEMBERSHIP_GENERIC_CHECKBOX_6,
  valueType: constants.VALUE_TYPE_BOOLEAN,
  operators: schemaOperators.booleanOperators,
  group: constants.MEMBERSHIP_GROUP,
  dataValueType: constants.DATA_VALUE_TYPE_BOOLEAN,
  features: [appConstants.FEATURE_BASIC_PLAN_EXCLUDE]
};

export const membership_genericCheckBox7 = {
  key: constants.CONTEXT_MEMBERSHIP_GENERIC_CHECKBOX_7,
  valueType: constants.VALUE_TYPE_BOOLEAN,
  operators: schemaOperators.booleanOperators,
  group: constants.MEMBERSHIP_GROUP,
  dataValueType: constants.DATA_VALUE_TYPE_BOOLEAN,
  features: [appConstants.FEATURE_BASIC_PLAN_EXCLUDE]
};

export const membership_genericCheckBox8 = {
  key: constants.CONTEXT_MEMBERSHIP_GENERIC_CHECKBOX_8,
  valueType: constants.VALUE_TYPE_BOOLEAN,
  operators: schemaOperators.booleanOperators,
  group: constants.MEMBERSHIP_GROUP,
  dataValueType: constants.DATA_VALUE_TYPE_BOOLEAN,
  features: [appConstants.FEATURE_BASIC_PLAN_EXCLUDE]
};

export const membership_genericCheckBox9 = {
  key: constants.CONTEXT_MEMBERSHIP_GENERIC_CHECKBOX_9,
  valueType: constants.VALUE_TYPE_BOOLEAN,
  operators: schemaOperators.booleanOperators,
  group: constants.MEMBERSHIP_GROUP,
  dataValueType: constants.DATA_VALUE_TYPE_BOOLEAN,
  features: [appConstants.FEATURE_BASIC_PLAN_EXCLUDE]
};

export const membership_genericCheckBox10 = {
  key: constants.CONTEXT_MEMBERSHIP_GENERIC_CHECKBOX_10,
  valueType: constants.VALUE_TYPE_BOOLEAN,
  operators: schemaOperators.booleanOperators,
  group: constants.MEMBERSHIP_GROUP,
  dataValueType: constants.DATA_VALUE_TYPE_BOOLEAN,
  features: [appConstants.FEATURE_BASIC_PLAN_EXCLUDE]
};

export const membership_points = {
  key: constants.CONTEXT_MEMBERSHIP_POINTS,
  valueType: constants.VALUE_TYPE_POINTS,
  operators: schemaOperators.numberOperators,
  group: constants.MEMBERSHIP_GROUP,
  dataValueType: constants.DATA_VALUE_TYPE_INTEGER
};

export const membership_budgetWeighted = {
  key: constants.CONTEXT_MEMBERSHIP_BUDGETWEIGHTED,
  valueType: constants.VALUE_TYPE_POINTS,
  operators: schemaOperators.numberOperators,
  group: constants.MEMBERSHIP_GROUP,
  dataValueType: constants.DATA_VALUE_TYPE_INTEGER,
  features: [appConstants.FEATURE_ESSENTIALS_OFF]
};

export const membership_accumulated_points = {
  key: constants.CONTEXT_MEMBERSHIP_ACCUMULATED_POINTS,
  valueType: constants.VALUE_TYPE_POINTS,
  operators: schemaOperators.numberOperators,
  group: constants.MEMBERSHIP_GROUP,
  dataValueType: constants.DATA_VALUE_TYPE_INTEGER
};

export const membership_accumulated_budgetWeighted = {
  key: constants.CONTEXT_MEMBERSHIP_ACCUMULATED_BUDGET_WEIGHTED,
  valueType: constants.VALUE_TYPE_POINTS,
  operators: schemaOperators.numberOperators,
  group: constants.MEMBERSHIP_GROUP,
  dataValueType: constants.DATA_VALUE_TYPE_INTEGER,
  features: [appConstants.FEATURE_ESSENTIALS_OFF]
};

export const membership_birthdayMonth = {
  key: constants.CONTEXT_MEMBERSHIP_BIRTHDAY_MONTH,
  valueType: constants.VALUE_TYPE_MONTHS_LIST,
  operators: schemaOperators.arrayOperators,
  group: constants.MEMBERSHIP_GROUP,
  dataValueType: constants.DATA_VALUE_TYPE_STRING_ARRAY,
  features: [appConstants.FEATURE_BASIC_PLAN_EXCLUDE]
};

export const membership_birthdayMonthAndDay = {
  key: constants.CONTEXT_MEMBERSHIP_BIRTHDAY_MONTH_AND_DAY,
  valueType: constants.VALUE_TYPE_MONTHS_AND_DAYS_LIST,
  operators: schemaOperators.booleanOperators,
  group: constants.MEMBERSHIP_GROUP,
  dataValueType: constants.MONTH_AND_DAY,
  features: [appConstants.FEATURE_BASIC_PLAN_EXCLUDE]
};

export const membership_anniversaryMonth = {
  key: constants.CONTEXT_MEMBERSHIP_ANNIVERSARY_MONTH,
  valueType: constants.VALUE_TYPE_MONTHS_LIST,
  operators: schemaOperators.arrayOperators,
  group: constants.MEMBERSHIP_GROUP,
  dataValueType: constants.DATA_VALUE_TYPE_STRING_ARRAY,
  features: [appConstants.FEATURE_ESSENTIALS_OFF, appConstants.FEATURE_BASIC_PLAN_EXCLUDE]
};

export const membership_tag = {
  key: constants.CONTEXT_MEMBERSHIP_TAG,
  valueType: constants.VALUE_TYPE_ARRAY,
  operators: schemaOperators.arrayOperators,
  group: constants.MEMBERSHIP_GROUP,
  dataValueType: constants.DATA_VALUE_TYPE_STRING_ARRAY,
  autoCompleteOptions: appConstants.BUSINESS_TAGS,
  features: [appConstants.FEATURE_ESSENTIALS_OFF]
};

export const membership_storedCreditCard = {
  key: constants.CONTEXT_MEMBERSHIP_STORED_CREDIT_CARD,
  valueType: constants.VALUE_TYPE_BOOLEAN,
  operators: schemaOperators.booleanOperators,
  group: constants.MEMBERSHIP_GROUP,
  dataValueType: constants.DATA_VALUE_TYPE_BOOLEAN,
  features: [{ name: appConstants.FEATURES_ZOOZ_SERVICES }]
};

export const membership_membershipStatus = {
  key: constants.CONTEXT_MEMBERSHIP_STATUS,
  valueType: constants.VALUE_TYPE_MEMBERSHIP_STATUS_LIST,
  operators: schemaOperators.arrayOperators,
  group: constants.MEMBERSHIP_GROUP,
  dataValueType: constants.DATA_VALUE_TYPE_STRING_ARRAY
};

export const membership_pushNotificationEnabled = {
  key: constants.CONTEXT_MEMBERSHIP_PUSH_NOTIFICATION_ENABLED,
  valueType: constants.VALUE_TYPE_BOOLEAN,
  operators: schemaOperators.booleanOperators,
  group: constants.MEMBERSHIP_GROUP,
  dataValueType: constants.DATA_VALUE_TYPE_BOOLEAN,
  features: [appConstants.FEATURE_ESSENTIALS_OFF, appConstants.FEATURE_BASIC_PLAN_EXCLUDE]
};

export const membership_locationEnabled = {
  key: constants.CONTEXT_MEMBERSHIP_LOCATION_ENABLED,
  valueType: constants.VALUE_TYPE_BOOLEAN,
  operators: schemaOperators.booleanOperators,
  group: constants.MEMBERSHIP_GROUP,
  dataValueType: constants.DATA_VALUE_TYPE_BOOLEAN,
  features: [appConstants.FEATURE_ESSENTIALS_OFF, appConstants.FEATURE_BASIC_PLAN_EXCLUDE]
};

export const membership_mobileAppUsed = {
  key: constants.CONTEXT_MEMBERSHIP_MOBILE_APP_USED,
  valueType: constants.VALUE_TYPE_BOOLEAN,
  operators: schemaOperators.booleanOperators,
  group: constants.MEMBERSHIP_GROUP,
  dataValueType: constants.DATA_VALUE_TYPE_BOOLEAN,
  features: [appConstants.FEATURE_ESSENTIALS_OFF, appConstants.FEATURE_BASIC_PLAN_EXCLUDE],
};

export const membership_allowEmail = {
  key: constants.CONTEXT_MEMBERSHIP_ALLOW_EMAIL,
  valueType: constants.VALUE_TYPE_BOOLEAN,
  operators: schemaOperators.booleanOperators,
  group: constants.MEMBERSHIP_GROUP,
  dataValueType: constants.DATA_VALUE_TYPE_BOOLEAN
};

export const membership_favoriteBranchID = {
  key: constants.CONTEXT_MEMBERSHIP_FAVORITE_BRANCH_ID,
  valueType: constants.VALUE_TYPE_ARRAY,
  operators: schemaOperators.arrayOperators,
  group: constants.MEMBERSHIP_GROUP,
  dataValueType: constants.DATA_VALUE_TYPE_STRING_ARRAY,
  features: [appConstants.FEATURE_BASIC_PLAN_EXCLUDE]
};

export const membership_email = {
  key: constants.CONTEXT_MEMBERSHIP_EMAIL,
  valueType: constants.VALUE_TYPE_STRING,
  operators: schemaOperators.stringOperatorsEmailEnum,
  group: constants.MEMBERSHIP_GROUP,
  dataValueType: constants.DATA_VALUE_TYPE_STRING
};

export const membership_firstName = {
  key: constants.CONTEXT_MEMBERSHIP_FIRST_NAME,
  valueType: constants.VALUE_TYPE_STRING,
  operators: schemaOperators.stringOperatorsEnum,
  group: constants.MEMBERSHIP_GROUP,
  dataValueType: constants.DATA_VALUE_TYPE_STRING
};

export const membership_lastName = {
  key: constants.CONTEXT_MEMBERSHIP_LAST_NAME,
  valueType: constants.VALUE_TYPE_STRING,
  operators: schemaOperators.stringOperatorsEnum,
  group: constants.MEMBERSHIP_GROUP,
  dataValueType: constants.DATA_VALUE_TYPE_STRING
};

export const membership_caseInsensitive_firstName = {
  key: constants.CONTEXT_MEMBERSHIP_FIRST_NAME_CI,
  valueType: constants.VALUE_TYPE_STRING,
  operators: schemaOperators.stringOperatorsEnum,
  group: constants.MEMBERSHIP_GROUP,
  dataValueType: constants.DATA_VALUE_TYPE_STRING
};

export const membership_caseInsensitive_lastName = {
  key: constants.CONTEXT_MEMBERSHIP_LAST_NAME_CI,
  valueType: constants.VALUE_TYPE_STRING,
  operators: schemaOperators.stringOperatorsEnum,
  group: constants.MEMBERSHIP_GROUP,
  dataValueType: constants.DATA_VALUE_TYPE_STRING
};

export const membership_global_condition_genericDate1 = {
  key: constants.CONTEXT_MEMBERSHIP_GENERIC_DATE_1,
  valueType: constants.VALUE_TYPE_DATE,
  operators: schemaOperators.dateOperators,
  group: constants.MEMBERSHIP_GROUP,
  dataValueType: constants.DATA_VALUE_TYPE_DATE,
  features: [appConstants.FEATURE_BASIC_PLAN_EXCLUDE]
};

export const membership_global_condition_genericDate2 = {
  key: constants.CONTEXT_MEMBERSHIP_GENERIC_DATE_2,
  valueType: constants.VALUE_TYPE_DATE,
  operators: schemaOperators.dateOperators,
  group: constants.MEMBERSHIP_GROUP,
  dataValueType: constants.DATA_VALUE_TYPE_DATE,
  features: [appConstants.FEATURE_BASIC_PLAN_EXCLUDE]
};

export const membership_global_condition_genericDate3 = {
  key: constants.CONTEXT_MEMBERSHIP_GENERIC_DATE_3,
  valueType: constants.VALUE_TYPE_DATE,
  operators: schemaOperators.dateOperators,
  group: constants.MEMBERSHIP_GROUP,
  dataValueType: constants.DATA_VALUE_TYPE_DATE,
  features: [appConstants.FEATURE_BASIC_PLAN_EXCLUDE]
};

export const membership_global_condition_genericDate4 = {
  key: constants.CONTEXT_MEMBERSHIP_GENERIC_DATE_4,
  valueType: constants.VALUE_TYPE_DATE,
  operators: schemaOperators.dateOperators,
  group: constants.MEMBERSHIP_GROUP,
  dataValueType: constants.DATA_VALUE_TYPE_DATE,
  features: [appConstants.FEATURE_BASIC_PLAN_EXCLUDE]
};

export const membership_global_condition_genericDate5 = {
  key: constants.CONTEXT_MEMBERSHIP_GENERIC_DATE_5,
  valueType: constants.VALUE_TYPE_DATE,
  operators: schemaOperators.dateOperators,
  group: constants.MEMBERSHIP_GROUP,
  dataValueType: constants.DATA_VALUE_TYPE_DATE,
  features: [appConstants.FEATURE_BASIC_PLAN_EXCLUDE]
};

export const membership_global_condition_genericDate6 = {
  key: constants.CONTEXT_MEMBERSHIP_GENERIC_DATE_6,
  valueType: constants.VALUE_TYPE_DATE,
  operators: schemaOperators.dateOperators,
  group: constants.MEMBERSHIP_GROUP,
  dataValueType: constants.DATA_VALUE_TYPE_DATE,
  features: [appConstants.FEATURE_BASIC_PLAN_EXCLUDE]
};

export const membership_global_condition_genericDate7 = {
  key: constants.CONTEXT_MEMBERSHIP_GENERIC_DATE_7,
  valueType: constants.VALUE_TYPE_DATE,
  operators: schemaOperators.dateOperators,
  group: constants.MEMBERSHIP_GROUP,
  dataValueType: constants.DATA_VALUE_TYPE_DATE,
  features: [appConstants.FEATURE_BASIC_PLAN_EXCLUDE]
};

export const membership_global_condition_genericDate8 = {
  key: constants.CONTEXT_MEMBERSHIP_GENERIC_DATE_8,
  valueType: constants.VALUE_TYPE_DATE,
  operators: schemaOperators.dateOperators,
  group: constants.MEMBERSHIP_GROUP,
  dataValueType: constants.DATA_VALUE_TYPE_DATE,
  features: [appConstants.FEATURE_BASIC_PLAN_EXCLUDE]
};

export const membership_global_condition_genericDate9 = {
  key: constants.CONTEXT_MEMBERSHIP_GENERIC_DATE_9,
  valueType: constants.VALUE_TYPE_DATE,
  operators: schemaOperators.dateOperators,
  group: constants.MEMBERSHIP_GROUP,
  dataValueType: constants.DATA_VALUE_TYPE_DATE,
  features: [appConstants.FEATURE_BASIC_PLAN_EXCLUDE]
};

export const membership_global_condition_genericDate10 = {
  key: constants.CONTEXT_MEMBERSHIP_GENERIC_DATE_10,
  valueType: constants.VALUE_TYPE_DATE,
  operators: schemaOperators.dateOperators,
  group: constants.MEMBERSHIP_GROUP,
  dataValueType: constants.DATA_VALUE_TYPE_DATE,
  features: [appConstants.FEATURE_BASIC_PLAN_EXCLUDE]
};

export const membership_genericDate1 = {
  key: constants.CONTEXT_MEMBERSHIP_GENERIC_DATE_1,
  valueType: constants.VALUE_TYPE_DATE_TIME,
  operators: schemaOperators.dateOperators,
  group: constants.MEMBERSHIP_GROUP,
  dataValueType: constants.DATA_VALUE_TYPE_DATE_TIME
};

export const membership_genericDate2 = {
  key: constants.CONTEXT_MEMBERSHIP_GENERIC_DATE_2,
  valueType: constants.VALUE_TYPE_DATE_TIME,
  operators: schemaOperators.dateOperators,
  group: constants.MEMBERSHIP_GROUP,
  dataValueType: constants.DATA_VALUE_TYPE_DATE_TIME
};
export const membership_genericDate3 = {
  key: constants.CONTEXT_MEMBERSHIP_GENERIC_DATE_3,
  valueType: constants.VALUE_TYPE_DATE_TIME,
  operators: schemaOperators.dateOperators,
  group: constants.MEMBERSHIP_GROUP,
  dataValueType: constants.DATA_VALUE_TYPE_DATE_TIME
};
export const membership_genericDate4 = {
  key: constants.CONTEXT_MEMBERSHIP_GENERIC_DATE_4,
  valueType: constants.VALUE_TYPE_DATE_TIME,
  operators: schemaOperators.dateOperators,
  group: constants.MEMBERSHIP_GROUP,
  dataValueType: constants.DATA_VALUE_TYPE_DATE_TIME
};
export const membership_genericDate5 = {
  key: constants.CONTEXT_MEMBERSHIP_GENERIC_DATE_5,
  valueType: constants.VALUE_TYPE_DATE_TIME,
  operators: schemaOperators.dateOperators,
  group: constants.MEMBERSHIP_GROUP,
  dataValueType: constants.DATA_VALUE_TYPE_DATE_TIME
};
export const membership_genericDate6 = {
  key: constants.CONTEXT_MEMBERSHIP_GENERIC_DATE_6,
  valueType: constants.VALUE_TYPE_DATE_TIME,
  operators: schemaOperators.dateOperators,
  group: constants.MEMBERSHIP_GROUP,
  dataValueType: constants.DATA_VALUE_TYPE_DATE_TIME
};
export const membership_genericDate7 = {
  key: constants.CONTEXT_MEMBERSHIP_GENERIC_DATE_7,
  valueType: constants.VALUE_TYPE_DATE_TIME,
  operators: schemaOperators.dateOperators,
  group: constants.MEMBERSHIP_GROUP,
  dataValueType: constants.DATA_VALUE_TYPE_DATE_TIME
};
export const membership_genericDate8 = {
  key: constants.CONTEXT_MEMBERSHIP_GENERIC_DATE_8,
  valueType: constants.VALUE_TYPE_DATE_TIME,
  operators: schemaOperators.dateOperators,
  group: constants.MEMBERSHIP_GROUP,
  dataValueType: constants.DATA_VALUE_TYPE_DATE_TIME
};
export const membership_genericDate9 = {
  key: constants.CONTEXT_MEMBERSHIP_GENERIC_DATE_9,
  valueType: constants.VALUE_TYPE_DATE_TIME,
  operators: schemaOperators.dateOperators,
  group: constants.MEMBERSHIP_GROUP,
  dataValueType: constants.DATA_VALUE_TYPE_DATE_TIME
};
export const membership_genericDate10 = {
  key: constants.CONTEXT_MEMBERSHIP_GENERIC_DATE_10,
  valueType: constants.VALUE_TYPE_DATE_TIME,
  operators: schemaOperators.dateOperators,
  group: constants.MEMBERSHIP_GROUP,
  dataValueType: constants.DATA_VALUE_TYPE_DATE_TIME
};

export const membership_addressCity = {
  key: constants.CONTEXT_MEMBERSHIP_ADDRESS_CITY,
  valueType: constants.VALUE_TYPE_ARRAY,
  operators: schemaOperators.arrayOperators,
  group: constants.MEMBERSHIP_GROUP,
  dataValueType: constants.DATA_VALUE_TYPE_STRING_ARRAY,
  features: [appConstants.FEATURE_BASIC_PLAN_EXCLUDE]
};

export const membership_addressCountry = {
  key: constants.CONTEXT_MEMBERSHIP_ADDRESS_COUNTRY,
  valueType: constants.VALUE_TYPE_ARRAY,
  operators: schemaOperators.arrayOperators,
  group: constants.MEMBERSHIP_GROUP,
  dataValueType: constants.DATA_VALUE_TYPE_STRING_ARRAY,
  features: [appConstants.FEATURE_BASIC_PLAN_EXCLUDE]
};

export const membership_addressFloor = {
  key: constants.CONTEXT_MEMBERSHIP_ADDRESS_FLOOR,
  valueType: constants.VALUE_TYPE_STRING,
  operators: schemaOperators.stringOperatorsEnum,
  group: constants.MEMBERSHIP_GROUP,
  dataValueType: constants.DATA_VALUE_TYPE_STRING
};

export const membership_addressHome = {
  key: constants.CONTEXT_MEMBERSHIP_ADDRESS_HOME,
  valueType: constants.VALUE_TYPE_ARRAY,
  operators: schemaOperators.arrayOperators,
  group: constants.MEMBERSHIP_GROUP,
  dataValueType: constants.DATA_VALUE_TYPE_STRING_ARRAY,
  features: [appConstants.FEATURE_BASIC_PLAN_EXCLUDE]
};

export const membership_addressLine1 = {
  key: constants.CONTEXT_MEMBERSHIP_ADDRESS_LINE_1,
  valueType: constants.VALUE_TYPE_STRING,
  operators: schemaOperators.stringOperatorsEnum,
  group: constants.MEMBERSHIP_GROUP,
  dataValueType: constants.DATA_VALUE_TYPE_STRING
};

export const membership_addressLine2 = {
  key: constants.CONTEXT_MEMBERSHIP_ADDRESS_LINE_2,
  valueType: constants.VALUE_TYPE_STRING,
  operators: schemaOperators.stringOperatorsEnum,
  group: constants.MEMBERSHIP_GROUP,
  dataValueType: constants.DATA_VALUE_TYPE_STRING
};

export const membership_addressState = {
  key: constants.CONTEXT_MEMBERSHIP_ADDRESS_STATE,
  valueType: constants.VALUE_TYPE_ARRAY,
  operators: schemaOperators.arrayOperators,
  group: constants.MEMBERSHIP_GROUP,
  dataValueType: constants.DATA_VALUE_TYPE_STRING_ARRAY,
  features: [appConstants.FEATURE_BASIC_PLAN_EXCLUDE]
};

export const membership_addressStreet = {
  key: constants.CONTEXT_MEMBERSHIP_ADDRESS_STREET,
  valueType: constants.VALUE_TYPE_STRING,
  operators: schemaOperators.stringOperatorsEnum,
  group: constants.MEMBERSHIP_GROUP,
  dataValueType: constants.DATA_VALUE_TYPE_STRING
};

export const membership_addressZipCode = {
  key: constants.CONTEXT_MEMBERSHIP_ADDRESS_ZIP_CODE,
  valueType: constants.VALUE_TYPE_ARRAY,
  operators: schemaOperators.arrayOperators,
  group: constants.MEMBERSHIP_GROUP,
  dataValueType: constants.DATA_VALUE_TYPE_STRING_ARRAY
};

export const membership_memberID = {
  key: constants.CONTEXT_MEMBERSHIP_MEMBER_ID,
  valueType: constants.VALUE_TYPE_STRING,
  operators: schemaOperators.stringOperatorsEnum,
  group: constants.MEMBERSHIP_GROUP,
  dataValueType: constants.DATA_VALUE_TYPE_STRING
};

export const membership_phoneNumber = {
  key: constants.CONTEXT_MEMBERSHIP_PHONE_NUMBER,
  valueType: constants.VALUE_TYPE_STRING,
  operators: schemaOperators.stringOperatorsEnum,
  group: constants.MEMBERSHIP_GROUP,
  dataValueType: constants.DATA_VALUE_TYPE_STRING
};

export const membership_govId = {
  key: constants.CONTEXT_MEMBERSHIP_GOV_ID,
  valueType: constants.VALUE_TYPE_STRING,
  operators: schemaOperators.stringOperatorsEnum,
  group: constants.MEMBERSHIP_GROUP,
  dataValueType: constants.DATA_VALUE_TYPE_STRING
};

export const membership_extClubMemberID = {
  key: constants.CONTEXT_MEMBERSHIP_EXT_CLUB_MEMBER_ID,
  valueType: constants.VALUE_TYPE_STRING,
  operators: schemaOperators.stringOperatorsEnum,
  group: constants.MEMBERSHIP_GROUP,
  dataValueType: constants.DATA_VALUE_TYPE_STRING
};

export const membership_birthday = {
  key: constants.CONTEXT_MEMBERSHIP_BIRTHDAY,
  valueType: constants.VALUE_TYPE_DATE_TIME,
  operators: schemaOperators.dateOperators,
  group: constants.MEMBERSHIP_GROUP,
  dataValueType: constants.DATA_VALUE_TYPE_DATE_TIME
};

export const membership_anniversary = {
  key: constants.CONTEXT_MEMBERSHIP_ANNIVERSARY,
  valueType: constants.VALUE_TYPE_DATE_TIME,
  operators: schemaOperators.dateOperators,
  group: constants.MEMBERSHIP_GROUP,
  dataValueType: constants.DATA_VALUE_TYPE_DATE_TIME,
  features: [appConstants.FEATURE_ESSENTIALS_OFF]
};

export const membership_expirationDate = {
  key: constants.CONTEXT_MEMBERSHIP_EXPIRATION_DATE,
  valueType: constants.VALUE_TYPE_DATE,
  operators: [schemaOperators.dateBefore, schemaOperators.dateIsOnOrAfter, schemaOperators.dateIs, schemaOperators.dateIsNot],
  group: constants.MEMBERSHIP_GROUP,
  dataValueType: constants.DATA_VALUE_TYPE_DATE,
  features: [appConstants.FEATURE_BASIC_PLAN_EXCLUDE]
};

export const membership_hasConsent = {
  key: constants.CONTEXT_MEMBERSHIP_HAS_CONSENT,
  valueType: constants.VALUE_TYPE_MEMBERSHIP_CONSENT_LIST,
  operators: schemaOperators.arrayOperators,
  group: constants.MEMBERSHIP_GROUP,
  dataValueType: constants.DATA_VALUE_TYPE_STRING_ARRAY
};

export const membership_rfm = {
  key: constants.CONTEXT_MEMBERSHIP_RFM,
  valueType: constants.VALUE_TYPE_MEMBERSHIP_RFM_LIST,
  operators: schemaOperators.arrayOperators,
  group: constants.MEMBERSHIP_GROUP,
  dataValueType: constants.DATA_VALUE_TYPE_STRING_ARRAY,
  isAutoTag: true,
  features: [appConstants.FEATURE_ESSENTIALS_OFF]
};

export const membership_lastVisit = {
  key: constants.CONTEXT_MEMBERSHIP_LAST_VISIT,
  valueType: constants.VALUE_TYPE_MEMBERSHIP_LAST_VISIT_LIST,
  operators: schemaOperators.arrayOperators,
  group: constants.MEMBERSHIP_GROUP,
  dataValueType: constants.DATA_VALUE_TYPE_STRING_ARRAY,
  isAutoTag: true
};

export const membership_secondPreferredLocation = {
  key: constants.CONTEXT_MEMBERSHIP_SECOND_PREFERRED_LOCATION,
  valueType: constants.VALUE_TYPE_LOCATIONS,
  operators: schemaOperators.arrayOperators,
  group: constants.MEMBERSHIP_GROUP,
  dataValueType: constants.DATA_VALUE_TYPE_STRING_ARRAY,
  isAutoTag: true
};

export const datesRange = {
  key: constants.CONTEXT_TIMESTAMP,
  valueType: constants.VALUE_TYPE_DATE_TIME,
  operators: [schemaOperators.dateTimeIsBetween, schemaOperators.dateTimeIsAfter, schemaOperators.dateTimeIsBefore],
  group: constants.DATES_AND_TIMES_GROUP,
  dataValueType: constants.DATA_VALUE_TYPE_DATE_TIME_BETWEEN
};

export const timeRangeInDaysOfWeek = {
  key: constants.CONTEXT_TIMESTAMP_DAYS,
  valueType: constants.VALUE_TYPE_DAYS_TIMES,
  operators: [schemaOperators.dayOfWeek],
  group: constants.DATES_AND_TIMES_GROUP,
  dataValueType: constants.DATA_VALUE_TYPE_DAYS_OF_WEEK_RANGE_CONDITION
};

export const totalSumOfAllPurchases = {
  key: constants.TOTAL_SUM_OF_ALL_PURCHASES,
  valueType: constants.VALUE_TYPE_POINTS,
  operators: schemaOperators.numberOperators,
  dataValueType: constants.DATA_VALUE_TYPE_INTEGER,
};

export const numberOfUserActions = {
  key: constants.USER_ACTIONS_QUANTITY,
  valueType: constants.VALUE_TYPE_NUMBER,
  operators: schemaOperators.numberOperators,
  dataValueType: constants.DATA_VALUE_TYPE_INTEGER,
};

export const numberOfPurchases = {
  key: constants.USER_ACTIONS_NUM_OF_PURCHASES,
  valueType: constants.VALUE_TYPE_NUMBER,
  operators: schemaOperators.numberOperators,
  dataValueType: constants.DATA_VALUE_TYPE_INTEGER,
};

export const emailSentFrom = {
  key: constants.EMAIL_SENT_FROM,
  valueType: constants.VALUE_TYPE_SENT_FROM,
  operators: schemaOperators.arrayOperators,
  dataValueType: constants.DATA_VALUE_TYPE_STRING_ARRAY,
};

export const emailTemplate = {
  key: constants.EMAIL_TEMPLATE,
  valueType: constants.VALUE_TYPE_EMAIL_TEMPLATE,
  operators: schemaOperators.arrayOperators,
  dataValueType: constants.DATA_VALUE_TYPE_STRING_ARRAY,
};

export const probability = {
  key: constants.PROBABILITY,
  valueType: constants.VALUE_TYPE_PERCENTAGE_INTEGER,
  operators: schemaOperators.booleanPositiveOnlyOperator,
  dataValueType: constants.DATA_VALUE_TYPE_INTEGER,
  casesOnlyCondition: true
};

export const providerType = {
  key: constants.PROVIDER_TYPE,
  valueType: constants.VALUE_TYPE_STRING,
  operators: schemaOperators.stringOperatorsEnum,
  dataValueType: constants.DATA_VALUE_TYPE_STRING
};
export const activities = {
  key: constants.ACTIVITIES,
  valueType: constants.VALUE_TYPE_ACTIVITIES,
  operators: schemaOperators.arrayOperators,
  dataValueType: constants.DATA_VALUE_TYPE_STRING_ARRAY
};
