import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Grid, Icon } from 'semantic-ui-react';
import { injectIntl } from 'react-intl';
import { fromJS } from 'immutable';
import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';

import ActionTemplate from './ActionTemplate';
import PopupWrapper from '../../../../common/components/PopupWrapper/PopupWrapper';
import DelayContent from './DelayContent/DelayContent';
import ActionsPopupContent from './actionsPopupContent';

import * as constants from '../../activity.constants';
import * as selectors from '../../activity.selectors';
import * as schemaConstants from '../../activitySchema/activitySchema.constants';
import Limit from './Limit/Limit';
import tracker from '../../../../common/utils/tracking/tracker';
import * as trackerConstants from '../../../../common/utils/tracking/tracking.consts';
import PopupTrigger from '../../../../common/components/PopupTrigger/PopupTrigger';
import * as dateConstants from '../../../../common/utils/dateTime.constants';
import ComilliaHint from '../../../ComilliaHint/ComilliaHint.container';
import * as comilliaHintConstants from '../../../ComilliaHint/comilliaHint.constants';
import * as memberActions from '../../../Members/members.actions';
import * as actions from '../../activity.actions';
import * as memberSelectors from '../../../Members/members.selectors';
import * as defaults from '../../reducers/activity.reducer.defaults';
import injectSaga from '../../../../utils/injectSaga';
import * as reducerConstants from '../../../../constants/reducer.constants';
import membersSaga from '../../../Members/members.saga';
import * as comilliaHintSelectors from '../../../ComilliaHint/comilliaHint.selectors';


class Action extends React.PureComponent {

  static propTypes = {
    action: PropTypes.object,
    actionPlaceholder: PropTypes.string.isRequired,
    actionsMode: PropTypes.string,
    actionsPopupContent: PropTypes.object,
    activityType: PropTypes.string.isRequired,
    addPointsOnChangeType: PropTypes.func,
    automationIdPrefix: PropTypes.string,
    bundle: PropTypes.object,
    createGiftFromActivity: PropTypes.func,
    createEmailFromActivity: PropTypes.func,
    createTempApplyOnModel: PropTypes.func,
    deleteAction: PropTypes.func.isRequired,
    features: PropTypes.object,
    index: PropTypes.number,
    intl: PropTypes.object,
    limit: PropTypes.bool,
    mentionsList: PropTypes.object,
    saveApplyOnData: PropTypes.func,
    trigger: PropTypes.string,
    updateActionField: PropTypes.func.isRequired,
    updateActionType: PropTypes.func.isRequired,
    getBusinessBundlePlan: PropTypes.func.isRequired,
    validationErrors: PropTypes.object,
    allowCreateEmail: PropTypes.bool,
    showSimpleDropdown: PropTypes.bool,
    actionsWrapperIndex: PropTypes.number,
    submitActionToCustomer: PropTypes.func,
    hasFullPermission: PropTypes.func,
    memberActions: PropTypes.object,
    actions: PropTypes.object,
    membersFilteredByCommunicationActions: PropTypes.number,
    membersTouchedLoaded: PropTypes.bool,
    filteredPopulation: PropTypes.object,
    isActivityDisabled: PropTypes.bool,
    comilliaHintObj: PropTypes.object,
    caseIndex: PropTypes.number,
    comilliaHintOpenInAction: PropTypes.object,
    membersFilteredByCommunicationActionsQuery: PropTypes.string,
    isOneTimeActivityDisabled: PropTypes.bool,
    isSimpleMode: PropTypes.bool,
  };


  static defaultProps = {
    allowCreateEmail: true,
    showSimpleDropdown: false
  };


  constructor(props) {
    super(props);

    this.state = {
      isPopupOpen: false,
      shouldRenderComilliaHint: false
    };
    this.transformActionsToOptions = this.transformActionsToOptions.bind(this);
  }

  componentWillMount() {
    const shouldRender = this.shouldRender(this.props);
    this.setState({
      shouldRenderComilliaHint: shouldRender
    });
    if (shouldRender) {
      this.props.actions.setComilliaHintInAction(this.props.caseIndex, this.props.index);
    }
  }

  componentWillReceiveProps(nextProps) {
    const shouldRender = this.shouldRender(nextProps);
    this.setState({
      shouldRenderComilliaHint: shouldRender
    });
    if (shouldRender) {
      this.props.actions.setComilliaHintInAction(nextProps.caseIndex, nextProps.index);
    }

  }

  shouldRender = (props) =>
            // action is of type communication
     defaults.comilliaHintActions.includes(props.action.get(constants.ACTION_TYPE))
          // there is filter population data and filter population adjusted by communication data and activityType is onetime
      && props.activityType === constants.ACTIVITY_TYPE_ONE_TIME && this.props.membersFilteredByCommunicationActions && props.filteredPopulation && !props.isActivityDisabled
          // comillia hint can be open only for one action
      && (!props.comilliaHintOpenInAction || (props.comilliaHintOpenInAction.get(constants.CASE) === props.caseIndex && props.comilliaHintOpenInAction.get(constants.ACTION) === props.index))
          // population count minimums are matched
      && this.props.membersFilteredByCommunicationActions > comilliaHintConstants.TOUCHED_BY_COMMUNICATION_MIN_NUMBER
      && this.props.membersFilteredByCommunicationActions > this.props.filteredPopulation.get(constants.FILTERED_POPULATION_COUNT) * comilliaHintConstants.TOUCHED_BY_COMMUNICATION_MIN_PERCENT
        // comillia hint has relevant data to render
      && this.props.comilliaHintObj && this.props.comilliaHintObj.get(comilliaHintConstants.TOUCHED_COUNT) && this.props.comilliaHintObj.get(comilliaHintConstants.TOUCHED_COUNT) > 0;

  getActionTemplate = () => (
    <ActionTemplate
      action={this.props.action}
      actionsMode={this.props.actionsMode}
      addPointsOnChangeType={this.props.addPointsOnChangeType}
      automationIdPrefix={this.props.automationIdPrefix}
      bundle={this.props.bundle}
      createGiftFromActivity={this.props.createGiftFromActivity}
      createEmailFromActivity={this.props.createEmailFromActivity}
      createTempApplyOnModel={this.props.createTempApplyOnModel}
      features={this.props.features}
      index={this.props.index}
      limit={this.props.limit}
      mentionsList={this.props.mentionsList}
      saveApplyOnData={this.props.saveApplyOnData}
      trigger={this.props.trigger}
      updateActionField={this.props.updateActionField}
      validationErrors={this.props.validationErrors}
      allowCreateEmail={this.props.allowCreateEmail}
      actionsWrapperIndex={this.props.actionsWrapperIndex}
      submitActionToCustomer={this.props.submitActionToCustomer}
      caseIndex={this.props.caseIndex}
      isSimpleMode={this.props.isSimpleMode}
    />);

  getLimitTemplate = () => {
    const { limit, updateActionField, index, action, validationErrors, automationIdPrefix, activityType } = this.props;
    if (!limit) {
      return null;
    }
    let timesLimit = null;
    let amountLimit = null;
    let limitDisabled = false;
    const showLimits = { times: false, amount: false };
    const actionType = action.get(constants.ACTION_TYPE);
    switch (actionType) {
      case schemaConstants.SEND_MEMBER_EMAIL_ACTION:
      case schemaConstants.UPDATE_MEMBERSHIP_EXPIRATION_ACTION:
      case schemaConstants.EXPORT_EVENT_ACTION:
      case schemaConstants.WALLET_PASS_PUSH_NOTIFICATION_ACTION:
      case schemaConstants.WALLET_PASS_SYNC_ACTION:
      case schemaConstants.SEND_MEMBER_SMS_ACTION:
      case schemaConstants.SEND_PERSONAL_PUSH_ACTION:
      case schemaConstants.SEND_PERSONAL_WEB_PUSH_ACTION:
      case schemaConstants.PUNCH_EXCEEDED_ACTION:
      case schemaConstants.TAG_MEMBERSHIP_ACTION:
      case schemaConstants.UN_TAG_MEMBERSHIP_ACTION:
      case schemaConstants.SEND_MESSAGE_ACTION:
        timesLimit = fromJS({
          [constants.LIMIT_VALUE]: constants.LIMIT_NONE_REPETITIVE_ACTION[constants.LIMIT_VALUE],
          [constants.LIMIT_ACTIVE]: constants.LIMIT_NONE_REPETITIVE_ACTION[constants.LIMIT_ACTIVE]
        });
        limitDisabled = true;
        showLimits.times = true;
        break;
      case schemaConstants.ASSIGN_ASSET_ACTION:
      case schemaConstants.AUTO_PUNCH_ACTION:
        timesLimit = action.get(constants.LIMIT);
        showLimits.times = true;
        break;
      case schemaConstants.DISCOUNT_AMOUNT_OFF_ON_SPECIFIC_ITEMS_ACTION:
      case schemaConstants.DISCOUNT_PERCENT_OFF_ON_SPECIFIC_ITEMS_ACTION:
      case schemaConstants.DISCOUNT_AMOUNT_OFF_ON_SPECIFIC_ITEMS_ADVANCED_ACTION:
      case schemaConstants.DISCOUNT_PERCENT_OFF_ON_SPECIFIC_ITEMS_ADVANCED_ACTION:
      case schemaConstants.DISCOUNT_FREE_ITEMS_ADVANCED_ACTION:
      case schemaConstants.DISCOUNT_SPECIAL_PRICE_ADVANCED_ACTION:
      case schemaConstants.DISCOUNT_SPECIAL_PRICE_ACTION:
      case schemaConstants.DISCOUNT_FREE_ITEMS_ACTION:
        timesLimit = action.get(constants.LIMIT);
        amountLimit = action.get(constants.AMOUNT_LIMIT);
        showLimits.times = true;
        showLimits.amount = true;
        break;
      case schemaConstants.DISCOUNT_PERCENT_OFF_ON_ENTIRE_TICKET_ACTION:
        amountLimit = action.get(constants.AMOUNT_LIMIT);
        showLimits.amount = true;
        break;
      default:
        return null;
    }
    return (
      <Limit
        activityType={activityType}
        automationIdPrefix={`${automationIdPrefix}.${actionType}.${index}`}
        onActionFieldUpdate={updateActionField}
        limit={timesLimit}
        amountLimit={amountLimit}
        showLimits={showLimits}
        index={index}
        disabled={limitDisabled}
        errors={validationErrors ? fromJS({
          [constants.LIMIT]: validationErrors.get(constants.LIMIT),
          [constants.AMOUNT_LIMIT]: validationErrors.get(constants.AMOUNT_LIMIT)
        }) : null}
        actionsWrapperIndex={this.props.actionsWrapperIndex}
      />
    );
  };

  getDelayParam(paramName) {
    let paramValue;
    if (paramName === constants.DELAY_UNITS) {
      paramValue = dateConstants.MINUTE;
    } else if (paramName === constants.DELAY_NUMBER) {
      paramValue = constants.DEFAULT_DELAY_NUMBER_FOR_PUNCH;
    } else {
      return null;
    }
    const defaultValue = this.props.trigger === schemaConstants.TRIGGER_EXCEEDED_PUNCH && this.props.action.get(constants.ACTION_TYPE) === schemaConstants.PUNCH_EXCEEDED_ACTION
      ? paramValue
      : undefined;
    return this.props.action.get(constants.DELAY) ? this.props.action.getIn([constants.DELAY, paramName]) : defaultValue;
  }

  getActionTriggerText = () => {
    const { formatMessage } = this.props.intl;
    let actionTriggerText = this.props.actionPlaceholder;
    if (this.props.index) {
      actionTriggerText = formatMessage({ id: `${this.props.activityType}.actions.dropdown.default.rest` });
    }
    if (this.props.action.get('actionType')) {
      actionTriggerText = formatMessage({ id: `activity.action.${this.props.action.get('actionType')}` });
    }
    if (this.props.actionsMode === constants.ADVANCED_DISCOUNT) {
      actionTriggerText = formatMessage({ id: `activity.action.${this.props.action.get('actionType')}.items` });
    }
    return actionTriggerText;
  };

  selectAction = (actionKey) => {
    this.props.updateActionType(actionKey, this.props.index, this.props.actionsWrapperIndex, this.props.actionsMode, this.props.trigger, this.props.action.get(constants.ACTION_TYPE));
    tracker.onEvent(trackerConstants.EVENT_TYPE_ACTION_SELECTED, {
      [trackerConstants.ACTION_SELECTED_ARGS_ACTION_KEY]: actionKey
    });
    this.handlePopupClose();
  };

  handlePopupOpen = () => {
    if (typeof this.props.actionsPopupContent !== 'undefined') {
      this.setState({ isPopupOpen: true });
    }
  };

  handlePopupClose = () => {
    this.setState({ isPopupOpen: false });
  };

  transformActionsToOptions() {
    const { formatMessage } = this.props.intl;
    const options = this.props.actionsPopupContent.get(constants.ACTION_FIELDS);

    return options.map((option) => {
      const features = option.get(constants.FEATURES);
      return {
        key: option.get(constants.KEY),
        text: formatMessage({ id: `activity.action.${option.get(constants.KEY)}` }),
        value: option.get(constants.KEY),
        features: features ? features.toJS() : null,
        category: option.get(schemaConstants.ACTION_SUB_CATEGORY),
      };
    }).toJS();
  }

  get additionalNote() {
    const { formatMessage } = this.props.intl;
    if (!this.props.action.get(constants.ACTION_META)) {
      return null;
    }
    return formatMessage({ id: `${this.props.action.get(constants.ACTION_META)}.note` });
  }
  get canDeleteAction() {
    if (!this.props.action.get(constants.ACTION_TYPE) || this.props.actionsMode === constants.ADVANCED_DISCOUNT) {
      return false;
    }
    return true;
  }

  onHintConfirm = () => {
    this.props.actions.updateFilteredPopulation(
      this.props.membersFilteredByCommunicationActions,
      null,
      null,
      true,
      this.props.membersFilteredByCommunicationActionsQuery
    );
  };

  render() {
    const actionName = this.props.action.get('actionType') ? this.props.action.get(constants.ACTION_TYPE) : this.props.actionPlaceholder;
    const dropDownFieldError = this.props.validationErrors && this.props.validationErrors.get(constants.VALIDATION_ERRORS_NO_ACTION);
    const popupActionStyle = classNames('selected-option', {
      disabled: typeof this.props.actionsPopupContent === 'undefined',
      placeholder: actionName === this.props.actionPlaceholder,
      dropdownFieldError: Boolean(dropDownFieldError)
    });
    const actionTemplateClass = `action-template-wrapper ${this.props.action.get(constants.ACTION_TYPE)} `;
    const delayUnits = this.getDelayParam(constants.DELAY_UNITS);
    const delayValue = this.getDelayParam(constants.DELAY_NUMBER);
    const actionTemplate = this.getActionTemplate();
    const limitTemplate = this.getLimitTemplate();

    const triggerMessage = this.getActionTriggerText();

    return (
      <div>
        <div className={actionTemplateClass}>
          <div>
            <span>
              <PopupWrapper
                basic
                className={`actions-popup ${this.props.showSimpleDropdown ? ' simple-dropdown' : ' with-top-border'}`}
                hideTrigger={!this.props.showSimpleDropdown}
                on="click"
                onOpen={this.handlePopupOpen}
                onClose={this.handlePopupClose}
                automationId={`${this.props.automationIdPrefix}.action-type.${this.props.index}.popup`}
                open={this.state.isPopupOpen}
                position={'bottom left'}
                trigger={
                  <div className="inline-div">
                    <PopupTrigger
                      className={popupActionStyle}
                      automationId={`${this.props.automationIdPrefix}.action-type.${this.props.index}`}
                      message={triggerMessage}
                    />
                  </div>
                  }
                disabled={this.props.isOneTimeActivityDisabled}
                wide="very"
              >
                <ActionsPopupContent
                  automationIdPrefix={`${this.props.automationIdPrefix}.action-type.${this.props.index}`}
                  options={this.props.actionsPopupContent}
                  onSelectOption={this.selectAction}
                  showSimpleDropdown={this.props.showSimpleDropdown}
                  getBusinessBundlePlan={this.props.getBusinessBundlePlan}
                  activityType={this.props.activityType}
                  hasFullPermission={this.props.hasFullPermission}
                />
              </PopupWrapper>
              { this.additionalNote &&
              <span className="additional-note">{this.additionalNote}</span> }
            </span>
            {actionTemplate}
            {
              this.canDeleteAction
                ? (
                  !this.props.isOneTimeActivityDisabled && <Grid.Column width={1} className="remove-action-container" verticalAlign="top">
                    <div
                      role="button"
                      className="action-link"
                      data-automation-id={`${this.props.automationIdPrefix}.${this.props.index}.delete`}
                      onClick={() => this.props.deleteAction(this.props.index, this.props.actionsWrapperIndex)}
                    >
                      <Icon className="como-ic-delete" />
                    </div>
                  </Grid.Column>
                )
                : null
            }
          </div>
          <div className="action-limit">
            {limitTemplate}
          </div>
          {
            this.state.shouldRenderComilliaHint &&
            <ComilliaHint
              contentTextKey={'comillia.hint.content.communication.touched'}
              contentTextParam={comilliaHintConstants.TOUCHED_COUNT}
              confirmButtonKey={'comillia.hint.confirm.communication.touched'}
              confirmButtonParam={comilliaHintConstants.TOUCHED_COUNT}
              cancelButtonKey={'comillia.hint.cancel.communication.touched'}
              onHintConfirm={this.onHintConfirm}
              index={this.props.index}
              caseIndex={this.props.caseIndex}
            />

          }
        </div>
        {
          actionTemplate && this.props.activityType === constants.ACTIVITY_TYPE_RULE &&
          <div className="delay-wrapper">
            <DelayContent
              automationIdPrefix={`${this.props.automationIdPrefix}.${this.props.index}`}
              units={delayUnits}
              value={delayValue}
              onActionFieldUpdate={this.props.updateActionField}
              index={this.props.index}
              actionsWrapperIndex={this.props.actionsWrapperIndex}
            />
          </div>
        }
      </div>
    );
  }
}


const mapStateToProps = (state) => ({
  membersFilteredByCommunicationActions: memberSelectors.getMembersFilteredByCommunicationActions(state),
  membersFilteredByCommunicationActionsQuery: memberSelectors.getMembersFilteredByCommunicationActionsQuery(state),
  membersTouchedLoaded: memberSelectors.getMembesTouchedLoadedFlag(state),
  filteredPopulation: selectors.getOneTimeActionFilteredPopulation(state),
  isActivityDisabled: selectors.isActivityDisabled(state),
  comilliaHintObj: comilliaHintSelectors.selectComilliaHintDomain(state),
  comilliaHintOpenInAction: selectors.getComilliaHintOpenInAction(state),
  isSimpleMode: selectors.getActivitySimpleMode(state)
});

function mapDispatchToProps(dispatch) {
  return {
    memberActions: bindActionCreators(memberActions, dispatch),
    actions: bindActionCreators(actions, dispatch)
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);
const withSaga = injectSaga({ key: reducerConstants.CUSTOMER_BRANCH, saga: membersSaga });

export default compose(
  withConnect,
  withSaga,
  injectIntl,
)(Action);
