import { fromJS, List, Map } from 'immutable';
import * as constants from '../../activity.constants';
import * as defaults from '../activity.reducer.defaults';
import { initializeAccumulatePointsAction, initializeAddPointsAction } from './actions/addPoints.reducer.utils';
import * as schemaConstants from '../../activitySchema/activitySchema.constants';
import { createRandomBundleLineId } from './activity.reducer.utils';
import * as staticSchema from '../../activitySchema/activitySchema';
import * as dateConstants from '../../../../common/utils/dateTime.constants';
import * as actionDefaults from '../activity.action.defaults';

export function initializeActionByType(actionType, actionsMode, trigger, duplicateFromAction, actionMetaData) {

  let actionObject = Map().set(constants.ACTION_TYPE, actionType);

  if (actionMetaData) {
    actionObject = actionObject.set(constants.ACTION_META, actionMetaData).set(constants.STATUS, constants.ACTIVITY_STATUS_ACTIVE);
  }

  switch (actionType) {
    case schemaConstants.DEDUCT_POINTS_ACTION:
    case schemaConstants.ACCUMULATE_POINTS_ACTION: {
      return initializeAccumulatePointsAction(actionsMode, trigger, duplicateFromAction, actionType);
    }
    case schemaConstants.ADD_POINTS_ACTION: {
      return initializeAddPointsAction();
    }
    case schemaConstants.DEDUCT_DYNAMIC_POINTS_ACTION: {
      return actionObject
        .set(constants.POINTS_TYPE, null)
        .set(constants.POINTS, null)
        .set(constants.DYNAMIC_POINTS_VALUE, null)
        .set(constants.BUDGET_TYPE, null);
    }

    case schemaConstants.TOP_UP_POINTS_ACTION: {
      return actionObject
        .set(constants.POINTS_TYPE, null)
        .set(constants.POINTS, null)
        .set(constants.POINTS_TOP_UP_PERCENTS, null)
        .set(constants.BUDGET_TYPE, null);
    }

    case constants.SEND_CODES_TO_POS: {
      return actionObject
        .set(constants.POS_CODE_TYPE,
          duplicateFromAction ? duplicateFromAction.get(constants.POS_CODE_TYPE) : constants.ITEM_CODE)
        .set(constants.CODES, List());
    }

    case schemaConstants.ASSIGN_ASSET_ACTION: {
      return actionObject
        .set(schemaConstants.ASSIGN_ASSET_BENEFIT_KEY_FIELD, '')
        .set(schemaConstants.ASSIGN_ASSET_QUANTITY_FIELD, 1);
    }

    case schemaConstants.REMOVE_ASSET_ACTION: {
      return actionObject
        .set(schemaConstants.ASSIGN_ASSET_BENEFIT_KEY_FIELD, '');
    }

    case schemaConstants.AUTO_PUNCH_ACTION: {
      return actionObject
        .set(schemaConstants.AUTO_PUNCH_NUM_OF_PUNCHES, 1)
        .set(constants.PUNCH_CARD_KEY, '');
    }

    case schemaConstants.PUNCH_EXCEEDED_ACTION: {
      actionObject = actionObject
        .set(constants.PUNCH_CARD_KEY, '')
        .set(schemaConstants.PUNCH_EXCEEDED_NUMBER_OF_PUNCHES_FIELD, schemaConstants.CONTEXT_NUM_OF_PUNCHES);
      if (trigger === schemaConstants.TRIGGER_EXCEEDED_PUNCH) {
        actionObject = actionObject.set(constants.DELAY, fromJS(defaults.defaultDelay));
      }
      return actionObject;
    }

    case schemaConstants.UPDATE_MEMBERSHIP_EXPIRATION_ACTION: {
      return actionObject
        .set(schemaConstants.UPDATE_MEMBERSHIP_EXPIRATION_DURATION_VALUE_FIELD, 1)
        .set(schemaConstants.UPDATE_MEMBERSHIP_EXPIRATION_DURATION_TYPE_FIELD, dateConstants.YEARS);
    }

    case schemaConstants.UPDATE_MEMBERSHIP_SENSITIVE_DATA_ACTION: {
      return actionObject.set(schemaConstants.UPDATE_MEMBERSHIP_SENSITIVE_DATA_FIELD_TYPE, 'sms')
        .set(schemaConstants.UPDATE_MEMBERSHIP_SENSITIVE_DATA_ALLOW_SMS_TYPE_FIELD, schemaConstants.TRUE)
        .set(schemaConstants.UPDATE_MEMBERSHIP_SENSITIVE_DATA_ALLOW_EMAIL_TYPE_FIELD, schemaConstants.TRUE)
        .set(schemaConstants.UPDATE_MEMBERSHIP_SENSITIVE_DATA_GIVE_CONSENT_TYPE_FIELD, schemaConstants.TRUE);
    }

    case schemaConstants.EXPORT_EVENT_ACTION: {
      return actionObject
        .set(schemaConstants.EXPORT_EVENT_NAME_FIELD, '')
        .set(schemaConstants.EXPORT_EVENT_DESTINATION_FIELD, '')
        .set(schemaConstants.EXPORT_EVENT_EXTEND_MEMBERSHIP_DATA_FIELD, false)
        .set(schemaConstants.EXPORT_EVENT_CUSTOM_HEADERS, fromJS([{ key: '', value: '' }]))
        .set(schemaConstants.EXPORT_EVENT_CUSTOM_BODY_FIELDS, fromJS([{ key: '', value: '' }]));
    }
    case schemaConstants.UPDATE_MEMBERSHIP_FIELDS_ACTION: {
      return actionObject
        .set(schemaConstants.UPDATE_MEMBERSHIP_FIELD_LISTS, fromJS([{ key: schemaConstants.GENERIC_CHECKBOX_1, value: schemaConstants.CONTEXT_DATA_DATA_BOOLEAN_VALUE_1 }]));
    }
    case schemaConstants.WALLET_PASS_PUSH_NOTIFICATION_ACTION: {
      return actionObject
        .set(schemaConstants.EXPORT_EVENT_CUSTOM_BODY_FIELDS, fromJS([{ key: schemaConstants.WALLET_PASS_NOTIFICATION_MESSAGE, value: '' }]));
    }

    case schemaConstants.WALLET_PASS_SYNC_ACTION: {
      return actionObject;
    }

    case schemaConstants.SEND_MEMBER_SMS_ACTION: {
      return actionObject.merge(defaults.smsDefaults);
    }
    case schemaConstants.SEND_TC_CONSENT_SMS_OR_EMAIL_ACTION: {
      return actionObject.set(constants.CONSENT_TYPE, '')
        .set(constants.SMS_MESSAGE, '')
        .set(constants.EMAIL_MESSAGE, '')
        .set(constants.EMAIL_SUBJECT, '');
    }

    case schemaConstants.SEND_PERSONAL_PUSH_ACTION:
    case schemaConstants.SEND_PERSONAL_WEB_PUSH_ACTION: {
      return actionObject.merge(defaults.pushMessageDefaults)
        .set(constants.EXTRA_DATA, fromJS([{ key: '', value: '' }]));
    }

    case schemaConstants.SEND_MESSAGE_ACTION: {
      return actionObject.merge(defaults.inAppMessageDefaults);
    }
    case schemaConstants.UN_TAG_MEMBERSHIP_ACTION:
    case schemaConstants.TAG_MEMBERSHIP_ACTION: {
      return actionObject.set(schemaConstants.TAG_MEMBERSHIP_TAG_FIELD, '');
    }
    case schemaConstants.DYNAMIC_UN_TAG_MEMBERSHIP_ACTION:
    case schemaConstants.DYNAMIC_TAG_MEMBERSHIP_ACTION: {
      return actionObject.set(schemaConstants.TAG_MEMBERSHIP_TAG_FIELD, '')
        .set(schemaConstants.TAGS_TYPE, schemaConstants.TAGS_TYPE_FIXED);
    }

    case schemaConstants.DISCOUNT_AMOUNT_OFF_ON_ENTIRE_TICKET_ACTION:
    case schemaConstants.DISCOUNT_PERCENT_OFF_ON_ENTIRE_TICKET_ACTION: {
      return actionObject
        .set(constants.DISCOUNT_VALUE, null)
        .set(constants.EXCLUDE_ITEMS_POPULATION, fromJS(defaults.emptyItemsPopulation))
        .set(constants.EXCLUDE_ITEMS_POPULATION_STATUS, false);
    }

    case schemaConstants.DISCOUNT_PERCENT_OFF_ON_SPECIFIC_ITEMS_ADVANCED_ACTION:
    case schemaConstants.DISCOUNT_AMOUNT_OFF_ON_SPECIFIC_ITEMS_ADVANCED_ACTION: {
      return actionObject.set(constants.DISCOUNT_VALUE, null);
    }

    case schemaConstants.DISCOUNT_AMOUNT_OFF_ON_SPECIFIC_ITEMS_ACTION:
    case schemaConstants.DISCOUNT_PERCENT_OFF_ON_SPECIFIC_ITEMS_ACTION:
    case schemaConstants.DISCOUNT_SPECIAL_PRICE_ACTION:
    case schemaConstants.DISCOUNT_FREE_ITEMS_ACTION: {
      return actionObject
        .set(constants.DISCOUNT_VALUE, null)
        .set(constants.DISCOUNT_ON, fromJS(defaults.emptyItemsPopulation));
    }
    case schemaConstants.DISCOUNT_SPECIAL_PRICE_ADVANCED_ACTION: {
      return actionObject.set(constants.DISCOUNT_VALUE, null);
    }

    // todo: remove this
    case schemaConstants.SEND_MEMBER_EMAIL_ACTION: {
      return actionObject
        .set(schemaConstants.SEND_MEMBER_EMAIL_SELECTED_MEMBERS_COUNT, 0)
        .set(schemaConstants.SEND_MEMBER_EMAIL_KEY_FIELD, '')
        .set(schemaConstants.SEND_MEMBER_EMAIL_TEMPLATE_NAME, '');
    }

    default:
      return actionObject;
  }
}

export function changeActionsWrapperMode(actionsWrapper, mode) {
  let newActionsWrapper;
  if (mode === constants.ACTIONS_SIMPLE_MODE || mode === constants.SIMPLE_SPECIFIC_ITEMS_DISCOUNT || mode === constants.SIMPLE_ENTIRE_TICKET_DISCOUNT) {
    newActionsWrapper = actionsWrapper || fromJS(defaults.emptyActionsWrapper);
  } else if (mode === constants.ACTIONS_OCCURRENCES_MODE || mode === constants.ADVANCED_DISCOUNT) {
    newActionsWrapper = actionsWrapper || fromJS(defaults.emptyActionsWrapperWithBundle);
    newActionsWrapper = newActionsWrapper.setIn(
      [constants.BUNDLE, constants.VALUE, constants.CONDITIONS_LIST, 0, constants.CONDITION_ID],
      createRandomBundleLineId());
  } else {
    throw new Error('unknown action mode specified!');
  }
  return newActionsWrapper.set(constants.ACTIONS_MODE, mode);
}

export function addBundleLine(bundleLinesList) {
  const newBundleLine = fromJS(defaults.defaultBundleLine).set(constants.CONDITION_ID, createRandomBundleLineId());
  return bundleLinesList.push(newBundleLine);
}

export function removeBundleLine(bundleLines, bundleLineIndex) {
  return bundleLines.filter((_, index) => index !== bundleLineIndex);
}

export function updateActionsWrapperBundleLineField(actionsWrapper, bundleConditionIndex, fieldName, fieldValue, activityType) {
  let bundleLine = actionsWrapper.getIn([constants.BUNDLE, constants.VALUE, constants.CONDITIONS_LIST, bundleConditionIndex]);
  // if conditionKey is changed, conditionValue should be zeroed// ex: changed from quantity to spend => value is 0
  if (fieldName === constants.CONDITION_KEY && fieldValue !== bundleLine.get(fieldName)) {
    bundleLine = bundleLine.set(constants.CONDITION_VALUE, 0);
  }
  bundleLine = bundleLine.set(fieldName, fromJS(fieldValue));
  let result = actionsWrapper.setIn([constants.BUNDLE, constants.VALUE, constants.CONDITIONS_LIST, bundleConditionIndex], bundleLine);
  if (staticSchema.redeemableActivities.includes(activityType)) {
    result = result.setIn([constants.ACTIONS_LIST, 0, constants.APPLY_ON], List())
                  .setIn([constants.ACTIONS_LIST, 0, constants.BUNDLE_ITEMS_MODE], null);
  }
  return result;
}

export function removeActionsListAction(actionsList, actionIndex) {
  const result = actionsList.filter((_, index) => index !== actionIndex);
  // if the deleted action is the last one on the list, add a default empty action:
  if (result.size === 0) {
    return result.push(fromJS(defaults.emptyAction));
  }
  return result;
}

export function addEmptyActionIfNeeded(actionsWrappers, activityType) {

  let lastActionsWrapper = actionsWrappers.last();
  const lastActionInTheActionList = lastActionsWrapper.get(constants.ACTIONS_LIST).last();
  let newActionsWrappers = actionsWrappers;

  if (lastActionInTheActionList && lastActionInTheActionList.get(constants.ACTION_TYPE) !== '') {
    // for discount actions add the empty action inside a new actionsWrapper
    if (staticSchema.redeemableActivities.includes(activityType)) {
      const newActionsWrapper = fromJS(defaults.defaultActionsWrapperBenefit);
      newActionsWrappers = newActionsWrappers
        .push(newActionsWrapper);
    } else {
      let actionsList = lastActionsWrapper.get(constants.ACTIONS_LIST);
      actionsList = actionsList.push(fromJS(defaults.emptyAction));
      lastActionsWrapper = lastActionsWrapper.set(constants.ACTIONS_LIST, actionsList);
      newActionsWrappers = newActionsWrappers.set(actionsWrappers.size - 1, lastActionsWrapper);
    }
  }
  return newActionsWrappers;
}

export function createTempApplyOnBundleData(bundleConditions, applyOnData, bundleItemsMode) {
  const defaultApplyOnQuantity = 1;
  let tempApplyOnArr = List();
  if (bundleConditions && bundleConditions.size) {
    bundleConditions.forEach((bundleCondition) => {
      let applyOnObj = Map();
      const applyOnCondition = applyOnData.find((applyOnDataLine) => applyOnDataLine.get(constants.BUNDLE_CONDITION_ID) === bundleCondition.get(constants.CONDITION_ID));
      applyOnObj = applyOnObj
        .set(constants.BUNDLE_CONDITION_ID, fromJS(bundleCondition.get(constants.CONDITION_ID)))
        .set(constants.QUANTITY, applyOnCondition ? applyOnCondition.get(constants.QUANTITY) : fromJS(defaultApplyOnQuantity))
        .set(constants.CONDITION_SELECTED, fromJS(!!applyOnCondition));
      tempApplyOnArr = tempApplyOnArr.push(applyOnObj);
    });
  }
  return fromJS({
    [constants.BUNDLE_ITEMS_MODE]: bundleItemsMode,
    [constants.APPLY_ON]: tempApplyOnArr
  });
}

export function applyApplyOnDataFromTempModel(action, bundleItemsMode, applyOnTempModel) {
  const newAction = action.set(constants.BUNDLE_ITEMS_MODE, bundleItemsMode);
  if (bundleItemsMode === constants.BUNDLE_ITEMS_MODE_SPECIFIC) {
    const applyOnConditions = getApplyOnConditionsFromTempModel(applyOnTempModel);
    return newAction.set(constants.APPLY_ON, applyOnConditions);
  } else if (bundleItemsMode === constants.BUNDLE_ITEMS_MODE_ENTIRE) {
    return newAction.delete(constants.APPLY_ON);
  }
  return newAction;
}

export function getDefaultActionWrapperForActivity(activityType) {
  const result = fromJS(defaults.emptyActionsWrapper);
  if (staticSchema.redeemableActivities.includes(activityType)) {
    return result.set(constants.ACTIONS_MODE, constants.SIMPLE_ENTIRE_TICKET_DISCOUNT);
  }
  return result;
}

function getApplyOnConditionsFromTempModel(applyOnConditions) {
  let newApplyOnConditions = List();
  if (applyOnConditions && applyOnConditions.size > 0) {
    let condition = Map();
    applyOnConditions.forEach((applyOnCondition) => {
      if (applyOnCondition.get(constants.CONDITION_SELECTED) || applyOnConditions.size === 1) {
        condition = condition
          .set(constants.BUNDLE_CONDITION_ID, applyOnCondition.get(constants.BUNDLE_CONDITION_ID))
          .set(constants.QUANTITY, applyOnCondition.get(constants.QUANTITY));
        newApplyOnConditions = newApplyOnConditions.push(condition);
      }
    });
  }
  return newApplyOnConditions;
}

export function getDefaultBundle(bundleSize) {
  let bundle = fromJS(defaults.defaultBundle).setIn([constants.VALUE, constants.CONDITIONS_LIST, 0, constants.CONDITION_ID], createRandomBundleLineId());
  let bundleConditions = List();
  if (bundleSize && bundleSize > 1) {
    for (let i = 0; i < bundleSize; i++) {
      bundleConditions = bundleConditions.push(fromJS(defaults.defaultBundleLine).set(constants.CONDITION_ID, createRandomBundleLineId()));
    }
    bundle = bundle.setIn([constants.VALUE, constants.CONDITIONS_LIST], bundleConditions);
  }
  return bundle;
}

export function setDefaultLimit(action) {
  return action.set(constants.LIMIT, fromJS(defaults.timesLimit));
}

export function defineActionsModeFromActionType(actionType) {
  if (staticSchema.simpleEntireTicketDiscountGift.actionFields.find((field) => field.key === actionType)) {
    return constants.SIMPLE_ENTIRE_TICKET_DISCOUNT;
  }
  if (staticSchema.simpleSpecificItemsDiscount.actionFields.find((field) => field.key === actionType)) {
    return constants.SIMPLE_SPECIFIC_ITEMS_DISCOUNT;
  }
  if (staticSchema.advancedDiscount.actionFields.find((field) => field.key === actionType)) {
    return constants.ADVANCED_DISCOUNT;
  }
  return null;
}

export function getFieldValueListBasedOnSelectedMembershipField(membershipFieldKey) {
  let fieldValueList = [];
  if (membershipFieldKey.includes('GenericCheckBox')) {
    fieldValueList = actionDefaults.defaultUpdateMembershipFieldValueOptions.booleanValues;
  }
  else if (membershipFieldKey.includes('GenericString')) {
    fieldValueList = actionDefaults.defaultUpdateMembershipFieldValueOptions.stringValues;
  }
  else if (membershipFieldKey.includes('GenericInteger')) {
    fieldValueList = actionDefaults.defaultUpdateMembershipFieldValueOptions.numericValues;
  }
  else if (membershipFieldKey.includes('GenericDate')) {
    fieldValueList = actionDefaults.defaultUpdateMembershipFieldValueOptions.dataValues;
  }
  return fieldValueList;
}
