/**
 *
 * ActionTemplate
 *
 */

import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import * as constants from '../../activity.constants';
import * as schemaConstants from '../../activitySchema/activitySchema.constants';
import SendEmail from './ruleActions/sendEmail/sendEmail';
import UpdateMembershipExpiration from './ruleActions/updateMembership/UpdateMembershipExpiration';
import UpdateMembershipSensitiveData from './ruleActions/updateMembershipSensitiveData/UpdateMembershipSensitiveData';
import AssignAssetForm from './ruleActions/assignAsset/assignAsset';
import RemoveAssetForm from './ruleActions/removeAsset/removeAsset';
import AccumulatePoints from './ruleActions/addPoints/accumulatePoints';
import AddPoints from './ruleActions/addPoints/addPoints';
import DeductDynamicPoints from './ruleActions/deductDynamicPoints';
import AddDynamicPoints from './ruleActions/addPoints/accumulateDynamicPoints';
import ExportEvent from './ruleActions/exportEvent/exportEvent';
import UpdateMembershipFields from './ruleActions/updateMembershipFields/updateMembershipFields';
import WalletPass from './ruleActions/walletPass/walletPass';
import SendSMS from './ruleActions/sendSMS/SendSMS';
import SendTcConsent from './ruleActions/sendTcConsent/SendTcConsent';
import UnsubscribeEmailOrSms from './ruleActions/unsubscribeEmailOrSms/UnsubscribeEmailOrSms';
import SendPush from './ruleActions/sendPush/SendPush';
import PunchThePunchCard from './ruleActions/PunchThePunchCard';
import PunchExceeded from './ruleActions/punchExceeded/punchExceeded';
import TagMembershipForm from './ruleActions/tagMembership/tagMembership';
import DynamicTagMembershipForm from './ruleActions/tagMembership/dynamicTagMembership';
import DiscountOnEntireTicket from './discountActions/DiscountOnEntireTicket';
import DiscountOnSpecificItemsSimple from './discountActions/DiscountOnSpecificItemsSimple';
import AdvancedDiscountActions from './discountActions/advancedDiscountAction/AdvancedDiscountAction';
import NewPriceToSpecificItems from './discountActions/NewPriceToSpecificItems';
import SendCodesToPos from './discountActions/SendCodesToPos';
import RegisterMember from './ruleActions/RegisterMember';
import PromptInAppMessage from './ruleActions/promptInAppMessage/promptInAppMessage';
import NoPosGift from './discountActions/NoPosGift';
import TopUpPoints from './ruleActions/addPoints/topUpPoints';

class ActionTemplate extends React.PureComponent {

  static propTypes = {
    action: PropTypes.object,
    actionsMode: PropTypes.string,
    addPointsOnChangeType: PropTypes.func,
    assetContext: PropTypes.string,
    automationIdPrefix: PropTypes.string,
    bundle: PropTypes.object,
    createGiftFromActivity: PropTypes.func,
    createEmailFromActivity: PropTypes.func,
    createTempApplyOnModel: PropTypes.func,
    features: PropTypes.object,
    index: PropTypes.number,
    limit: PropTypes.bool,
    mentionsList: PropTypes.object,
    saveApplyOnData: PropTypes.func,
    trigger: PropTypes.string,
    updateActionField: PropTypes.func.isRequired,
    validationErrors: PropTypes.object,
    allowCreateEmail: PropTypes.bool,
    actionsWrapperIndex: PropTypes.number,
    submitActionToCustomer: PropTypes.func,
    caseIndex: PropTypes.number,
    isSimpleMode: PropTypes.bool,
  };

  updateActionField = (fieldName, fieldValue, index) => {
    this.props.updateActionField(fieldName, fieldValue, index, this.props.actionsWrapperIndex);
  };
  render() {

    const { action, actionsMode, assetContext, automationIdPrefix, bundle, createTempApplyOnModel, features, index, caseIndex, limit, mentionsList, saveApplyOnData, trigger, updateActionField, validationErrors, isSimpleMode } = this.props;
    let actionTemplate = null;
    const actionType = this.props.action.get(constants.ACTION_TYPE);
    let automationIdActionKey = actionType;

    switch (actionType) {
      case schemaConstants.SEND_MEMBER_EMAIL_ACTION:
        actionTemplate = (
          // todo: when implemented please do not forget to initialize it's state in reducer (inside function initializeActionByType())
          <SendEmail
            action={action}
            index={index}
            limit={limit}
            createEmailFromActivity={this.props.createEmailFromActivity}
            allowCreateEmail={this.props.allowCreateEmail}
            actionsWrapperIndex={this.props.actionsWrapperIndex}
            submitActionToCustomer={this.props.submitActionToCustomer}
          />
        );
        break;
      case schemaConstants.UPDATE_MEMBERSHIP_EXPIRATION_ACTION:
        actionTemplate = (
          <UpdateMembershipExpiration
            action={action}
            index={index}
            limit={limit}
          />
        );
        break;
      case schemaConstants.UPDATE_MEMBERSHIP_SENSITIVE_DATA_ACTION:
        actionTemplate = (
          <UpdateMembershipSensitiveData
            action={action}
            index={index}
            limit={limit}
          />
        );
        break;
      case schemaConstants.ASSIGN_ASSET_ACTION:
        actionTemplate = (
          <AssignAssetForm
            action={action}
            index={index}
            limit={limit}
            createGiftFromActivity={this.props.createGiftFromActivity}
            actionsWrapperIndex={this.props.actionsWrapperIndex}
          />
        );
        break;
      case schemaConstants.REMOVE_ASSET_ACTION:
        actionTemplate = (
          <RemoveAssetForm
            action={action}
            index={index}
            limit={limit}
          />
        );
        break;
      case schemaConstants.DEDUCT_POINTS_ACTION:
        if (action.get(schemaConstants.POINTS_TYPE)) {
          actionTemplate = (<AccumulatePoints
            action={action}
            index={index}
            limit={limit}
            trigger={trigger}
            addPointsOnChangeType={this.props.addPointsOnChangeType}
            features={features}
            actionsMode={actionsMode}
            actionsWrapperIndex={this.props.actionsWrapperIndex}
            isNegative={actionType === schemaConstants.DEDUCT_POINTS_ACTION}
          />);
        } else {
          actionTemplate = (<AddPoints
            action={action}
            index={index}
            limit={limit}
            features={features}
            isNegative={actionType === schemaConstants.DEDUCT_POINTS_ACTION}
          />);
        }
        automationIdActionKey = schemaConstants.REDEEM_POINTS_ACTION;
        break;
      case schemaConstants.ACCUMULATE_POINTS_ACTION:
        actionTemplate = (<AccumulatePoints
          action={action}
          index={index}
          limit={limit}
          trigger={trigger}
          addPointsOnChangeType={this.props.addPointsOnChangeType}
          features={features}
          actionsMode={actionsMode}
          actionsWrapperIndex={this.props.actionsWrapperIndex}
          isNegative={actionType === schemaConstants.DEDUCT_POINTS_ACTION}
        />);
        automationIdActionKey = schemaConstants.ADD_POINTS_ACTION;
        break;
      case schemaConstants.REDEEM_POINTS_ACTION:
      case schemaConstants.ADD_POINTS_ACTION:
        actionTemplate = (<AddPoints
          action={action}
          index={index}
          limit={limit}
          features={features}
          isNegative={actionType === schemaConstants.REDEEM_POINTS_ACTION}
        />);
        break;
      case schemaConstants.TOP_UP_POINTS_ACTION:
        actionTemplate = (<TopUpPoints
          action={action}
          index={index}
          limit={limit}
          features={features}
        />);
        break;
      case schemaConstants.DEDUCT_DYNAMIC_POINTS_ACTION:
        actionTemplate = (<DeductDynamicPoints
          action={action}
          index={index}
          limit={limit}
          features={features}
        />);
        break;
      case schemaConstants.ADD_DYNAMIC_POINTS_ACTION:
        actionTemplate = (<AddDynamicPoints
          action={action}
          index={index}
          limit={limit}
          features={features}
        />);
        break;
      case schemaConstants.UPDATE_MEMBERSHIP_FIELDS_ACTION:
        actionTemplate = (
          <UpdateMembershipFields
            action={action}
            index={index}
            caseIndex={caseIndex}
          />
        );
        break;
      case schemaConstants.EXPORT_EVENT_ACTION:
        actionTemplate = (
          <ExportEvent
            action={action}
            index={index}
            limit={limit}
            mentionsList={mentionsList}
          />
        );
        break;
      case schemaConstants.WALLET_PASS_PUSH_NOTIFICATION_ACTION:
        actionTemplate = (
          <WalletPass
            action={action}
            index={index}
            limit={limit}
            withMessage
            mentionsList={mentionsList}
          />
        );
        break;
      case schemaConstants.WALLET_PASS_SYNC_ACTION:
        actionTemplate = (
          <WalletPass
            action={action}
            index={index}
            limit={limit}
            withMessage={false}
            mentionsList={mentionsList}
          />
        );
        break;
      case schemaConstants.SEND_MEMBER_SMS_ACTION:
        actionTemplate = (
          <SendSMS
            smsMessage={action.get(constants.SMS_MESSAGE)}
            index={index}
            mentionsList={mentionsList}
            limit={limit}
            actionsWrapperIndex={this.props.actionsWrapperIndex}
            submitActionToCustomer={this.props.submitActionToCustomer}
            includeImage={action.get(constants.SEND_SMS_WITH_IMAGE)}
            imageId={action.get(constants.MMS_IMAGE)}
            trigger={trigger}
          />
        );
        break;
      case schemaConstants.SEND_TC_CONSENT_SMS_OR_EMAIL_ACTION:
        actionTemplate = (
          <SendTcConsent
            action={action}
            index={index}
            mentionsList={mentionsList}
            limit={limit}
            actionsWrapperIndex={this.props.actionsWrapperIndex}
          />
        );
        break;
      case schemaConstants.SEND_PERSONAL_PUSH_ACTION:
      case schemaConstants.SEND_PERSONAL_WEB_PUSH_ACTION:
        actionTemplate = (
          <SendPush
            action={action}
            assetContext={assetContext}
            index={index}
            mentionsList={mentionsList}
            onActionFieldUpdate={updateActionField}
            actionsWrapperIndex={this.props.actionsWrapperIndex}
            submitActionToCustomer={this.props.submitActionToCustomer}
            isWeb={actionType === schemaConstants.SEND_PERSONAL_WEB_PUSH_ACTION}
          />
        );
        break;
      case schemaConstants.AUTO_PUNCH_ACTION:
        actionTemplate = (
          <PunchThePunchCard
            action={action}
            index={index}
            limit={limit}
          />
        );
        break;
      case schemaConstants.PUNCH_EXCEEDED_ACTION:
        actionTemplate = (
          <PunchExceeded
            action={action}
            index={index}
            limit={limit}
          />
        );
        break;
      case schemaConstants.TAG_MEMBERSHIP_ACTION:
      case schemaConstants.UN_TAG_MEMBERSHIP_ACTION:
        actionTemplate = (
          <TagMembershipForm
            action={action}
            index={index}
            limit={limit}
          />
        );
        break;
      case schemaConstants.DYNAMIC_TAG_MEMBERSHIP_ACTION:
      case schemaConstants.DYNAMIC_UN_TAG_MEMBERSHIP_ACTION:
        actionTemplate = (
          <DynamicTagMembershipForm
            action={action}
            index={index}
            limit={limit}
          />
        );
        break;
      case schemaConstants.DISCOUNT_AMOUNT_OFF_ON_ENTIRE_TICKET_ACTION:
      case schemaConstants.DISCOUNT_PERCENT_OFF_ON_ENTIRE_TICKET_ACTION:
        actionTemplate = (
          <DiscountOnEntireTicket
            action={action}
            index={index}
            limit={limit}
            features={features}
          />
        );
        break;
      case schemaConstants.DISCOUNT_AMOUNT_OFF_ON_SPECIFIC_ITEMS_ACTION:
      case schemaConstants.DISCOUNT_PERCENT_OFF_ON_SPECIFIC_ITEMS_ACTION: {
        actionTemplate = (<DiscountOnSpecificItemsSimple
          action={action}
          index={index}
          limit={limit}
          features={features}
          isSimpleMode={isSimpleMode}
        />);
        break;
      }
      case schemaConstants.DISCOUNT_AMOUNT_OFF_ON_SPECIFIC_ITEMS_ADVANCED_ACTION:
      case schemaConstants.DISCOUNT_PERCENT_OFF_ON_SPECIFIC_ITEMS_ADVANCED_ACTION:
      case schemaConstants.DISCOUNT_FREE_ITEMS_ADVANCED_ACTION:
      case schemaConstants.DISCOUNT_SPECIAL_PRICE_ADVANCED_ACTION: {
        actionTemplate = (<AdvancedDiscountActions
          action={action}
          index={index}
          limit={limit}
          bundle={bundle}
          saveApplyOnData={saveApplyOnData}
          createTempApplyOnModel={createTempApplyOnModel}
          actionsWrapperIndex={this.props.actionsWrapperIndex}
        />);
        break;
      }
      case schemaConstants.DISCOUNT_SPECIAL_PRICE_ACTION:
      case schemaConstants.DISCOUNT_FREE_ITEMS_ACTION:
        actionTemplate = (<NewPriceToSpecificItems
          action={action}
          index={index}
          features={features}
          limit={limit}
          isSimpleMode={isSimpleMode}
        />);
        break;
      case constants.SEND_CODES_TO_POS:
        actionTemplate = (
          <SendCodesToPos
            action={action}
            index={index}
          />
        );
        break;
      case constants.NO_POS_GIFT:
        actionTemplate = (
          <NoPosGift />
        );
        break;
      case schemaConstants.REGISTER_MEMBERSHIP_ACTION:
        actionTemplate = (
          <RegisterMember />
        );
        break;
      case schemaConstants.SEND_MESSAGE_ACTION:
        actionTemplate = (
          <PromptInAppMessage
            action={action}
            assetContext={assetContext}
            index={index}
            limit={limit}
            mentionsList={mentionsList}
            onActionFieldUpdate={updateActionField}
          />
        );
        break;

      case schemaConstants.UNSUBSCRIBE_EMAIL_OR_SMS:
        actionTemplate = (
          <UnsubscribeEmailOrSms
            automationIdPrefix={schemaConstants.UNSUBSCRIBE_EMAIL_OR_SMS}
            action={action}
            index={index}
            limit={limit}
          />
        );
        break;
      default:
        return null;
    }

    return React.cloneElement(actionTemplate, {
      automationIdPrefix: `${automationIdPrefix}.${automationIdActionKey}.${index}`,
      validationErrors: validationErrors || null,
      onActionFieldUpdate: this.updateActionField
    });

  }
}

export default injectIntl(ActionTemplate);

