import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { Map } from 'immutable';
import { bindActionCreators, compose } from 'redux';
import { Form, Grid } from 'semantic-ui-react';
import { injectIntl } from 'react-intl';
import moment from 'moment';
import _ from 'lodash';
import injectSaga from '../../utils/injectSaga';
import templatesSaga from '../Templates/templates.saga';
import saga from './activity.saga';
import formsSaga from '../Forms/forms.saga';
import businessSaga from '../BusinessCenter/businessCenter.saga';
import giftsSaga from '../Gifts/gifts.saga';
import promoCodesSaga from '../PromoCodes/promoCodes.saga';
import './activity.styles.less';
import ActivityHeader from './views/activityHeader/activityHeader';
import HeaderNotification from './views/activityHeader/HeaderNotification';
import * as casesActions from './views/cases/cases.actions';
import ChooseOption from '../../common/components/ChooseOptionsDropdown/ChooseOption';
import * as selectors from './activity.selectors';
import * as activityConstants from './activity.constants';
import * as activityTemplateActions from '../Templates/templates.actions';
import * as businessActions from '../BusinessCenter/businessCenter.actions';
import * as giftsActions from '../Gifts/gifts.actions';
import * as activityFeatureActions from './activity.actions';
import * as formsActions from '../Forms/forms.actions';
import * as appActions from '../App/app.actions';
import * as promoCodesActions from '../PromoCodes/promoCodes.actions';
import withAuthorization from '../../common/helpers/authorization';
import * as businessCenterSelectors from '../BusinessCenter/businessCenter.selectors';
import tracker from '../../common/utils/tracking/tracker';
import * as trackerConstants from '../../common/utils/tracking/tracking.consts';
import * as appSelectors from '../App/selectors';
import * as schemaSelectors from './activitySchema/activitySchema.selectors';
import * as formsSelectors from '../Forms/forms.selectors';
import * as mentionsSelectors from './activitySchema/mentions.selectos';
import * as routeConstants from '../../constants/route.contants';
import ActivityBody from './views/ActivityBody';
import TabbedActivityContent from './views/tabbedContent/TabbedContent.container';
import * as globalConditionsActions from './views/conditions/globalConditions/globalConditions.actions';
import ConfirmationDialog from '../../common/components/ConfirmationDialog/ConfirmationDialog';
import TemplatesMenu from '../Templates/templatesMenu/TemplatesMenu';
import ActivityTags from './views/activityTags/activityTags';
import * as reducerConstants from '../../constants/reducer.constants';
import * as queryStringUtils from '../../utils/queryStringUtils';
import emailsSaga from '../Emails/emails.saga';
import LandingPageModal from './views/landingPageModal/LandingPageModal';
import * as filterMemberActions from '../FilterMembers/filterMembers.actions';
import filterMembersSaga from '../FilterMembers/filterMembers.saga';
import * as membersActions from '../Members/members.actions';
import membersSaga from '../Members/members.saga';
import * as activitySelectorsUtils from '../Activity/activity.selectors.utils';
import * as appConstants from '../App/app.constants';
import * as giftsSelectors from '../Gifts/gifts.selectors';

export class Activity extends React.PureComponent {

  // eslint-disable-next-line no-undef
  static propTypes = {
    actions: PropTypes.object.isRequired,
    activityDataLoaded: PropTypes.bool,
    activityTabs: PropTypes.array,
    appActions: PropTypes.object.isRequired,
    businessName: PropTypes.string,
    casesActions: PropTypes.object.isRequired,
    campaigns: PropTypes.object,
    campaignsLoaded: PropTypes.bool.isRequired,
    draftOldFilteredPopulation: PropTypes.bool,
    features: PropTypes.object,
    hasFullPermission: PropTypes.func,
    getBusinessBundlePlan: PropTypes.func,
    filteredPopulation: PropTypes.object,
    intl: PropTypes.object.isRequired,
    locationId: PropTypes.number,
    match: PropTypes.object.isRequired,
    mentions: PropTypes.object,
    activity: PropTypes.object.isRequired,
    registrationFormFields: PropTypes.object,
    schema: PropTypes.object.isRequired,
    disabledGlobalConditions: PropTypes.object,
    schemaMembershipConditions: PropTypes.object,
    conditionsPerTrigger: PropTypes.object,
    actionsPerTrigger: PropTypes.object,
    template: PropTypes.object,
    activityValidationErrors: PropTypes.object,
    displayTabValidationErrors: PropTypes.object,
    getPermissionLevel: PropTypes.func.isRequired,
    schemaDateTimeGlobalConditions: PropTypes.object,
    tempOriginActivity: PropTypes.object,
    pendingTrigger: PropTypes.object,
    backToActivityInState: PropTypes.bool,
    confirmDeleteActionDialogOpen: PropTypes.bool,
    blockSendSmsActionDialogOpen: PropTypes.bool,
    reFilterDialogOpen: PropTypes.bool,
    landingPageModalData: PropTypes.object,
    location: PropTypes.object,
    adjustFilterByMemberProperties: PropTypes.bool,
    membersActions: PropTypes.object,
    lookerQueryFetched: PropTypes.bool,
    abTestMode: PropTypes.bool,
    activityTags: PropTypes.object,
    activitiesTags: PropTypes.array,
    giftsTags: PropTypes.array,
    gifts: PropTypes.array,
    showBasicPlan: PropTypes.bool,
  };

  constructor(props) {
    super(props);
    this.publishActivity = this.publishActivity.bind(this);
    this.createOneTimeAction = this.createOneTimeAction.bind(this);
    this.saveActivityAsDraft = this.saveActivityAsDraft.bind(this);
    this.updateName = this.updateName.bind(this);
    this.forceUpdateName = this.forceUpdateName.bind(this);
    this.onChooseCampaign = this.onChooseCampaign.bind(this);
    this.createNewDefaultCampaign = this.createNewDefaultCampaign.bind(this);
    this.handleActivityCancelOrBack = this.handleActivityCancelOrBack.bind(this);
    this.isOneTimeActionFilteredExternally = this.isOneTimeActionFilteredExternally.bind(this);
    this.getInitialDataByType = this.getInitialDataByType.bind(this);
    this.updateOneTimeActionParams = this.updateOneTimeActionParams.bind(this);
    this.reFilterPopulation = this.reFilterPopulation.bind(this);
    this.cancelReFilterPopulation = this.cancelReFilterPopulation.bind(this);
    this.handleScrollView = this.handleScrollView.bind(this);
    this.getActivityTypeFromPath = this.getActivityTypeFromPath.bind(this);
    this.getActivityHeaderAndPrefix = this.getActivityHeaderAndPrefix.bind(this);
    this.getMainContent = this.getMainContent.bind(this);
    this.isABTestingAllowed = this.isABTestingAllowed.bind(this);
    this.openOneTimeActionDialogForReFilteringCommunicationActions = this.openOneTimeActionDialogForReFilteringCommunicationActions.bind(this);
    this.state = {
      isHeaderSticky: false,
      needToChooseCampaign: true,
      refocusOnName: false,
      isHeaderShadowed: false,
      activityHasMigrated: false,
      onetimeNoFile: false,
      showSplitCases: true

    };
  }

  componentWillMount() {
    if (this.props.backToActivityInState) {
      this.props.actions.removeBackToActivityFlag();
      return;
    }
    if (!this.props.registrationFormFields) {
      // noinspection JSUnresolvedFunction
      this.props.actions.getRegistrationFormFields();
    }
    if (!this.props.features) {
      // noinspection JSUnresolvedFunction
      this.props.actions.getFeatures();
    }
    // get activity data or initialize an empty model
    // this.props.match is injected by react-router:
    // https://github.com/ReactTraining/react-router/blob/master/packages/react-router/docs/api/match.md
    if (this.props.activity.get(activityConstants.TYPE)) {
      this.getInitialDataByType(this.props.activity.get(activityConstants.TYPE));
      if (this.props.activity.get(activityConstants.TYPE) !== activityConstants.ACTIVITY_TYPE_ONE_TIME && !this.state.activityHasMigrated) {
        this.setState({ needToChooseCampaign: false });
      }
    } else if (this.props.match.path) {
      const activityTypeFromPath = this.getActivityTypeFromPath();
      if (activityTypeFromPath === activityConstants.ACTIVITY_TYPE_ONE_TIME) {
        this.updateOneTimeActionParams(this.props.location.search); // eslint-disable-line react/prop-types
      }
      // noinspection JSUnresolvedFunction
      this.props.actions.setActivityTypeData(activityTypeFromPath);
      this.getInitialDataByType(activityTypeFromPath);
    }
    if (_.isNil(this.props.gifts) || this.props.gifts.isEmpty()) {
      this.props.actions.getGifts();
    }
    if (_.isNil(this.props.campaigns) || this.props.campaigns.isEmpty()) {
      this.props.actions.getCampaigns();
    }
    if (this.props.activity.get(activityConstants.TYPE) === activityConstants.ACTIVITY_TYPE_ONE_TIME
      && this.props.adjustFilterByMemberProperties
      && this.props.activity.getIn([activityConstants.ONE_TIME_ACTION_FILTERED_POPULATION, activityConstants.FILTERED_POPULATION_CONDITIONS])
      && this.props.locationId) {
      this.props.membersActions.getMembersFilteredByCommunicationActions(this.props.activity.getIn([activityConstants.ONE_TIME_ACTION_FILTERED_POPULATION, activityConstants.FILTERED_POPULATION_CONDITIONS]), this.props.locationId);
    }

  }

  componentDidMount() {
    tracker.onPageLoaded(trackerConstants.PAGE_TYPE_ACTIVITY);
    window.addEventListener('scroll', this.handleScrollView);
    const parsedQueryParams = queryStringUtils.parse(this.props.location.search); // eslint-disable-line react/prop-types
    const activitySource = parsedQueryParams[activityConstants.ACTIVITY_SOURCE];
    const fileName = parsedQueryParams.file_name;
    const bucketName = parsedQueryParams.uri_base;
    if (activitySource === 'LE_1' && fileName && bucketName && !this.props.lookerQueryFetched) {
      this.props.actions.getLookerQuery(fileName, bucketName);
    }
  }

  handleActivityTagChange = (event, target) => {
    if (this.props.activityTags !== target.value) {
      this.props.actions.addActivityTags(target.value);
    }
  };

  componentWillReceiveProps(nextProps) {
    if (!this.props.activityDataLoaded && nextProps.activityDataLoaded) {
      this.props.appActions.setLoadingState(false);
    }
    if (nextProps.locationId && this.props.activity.get(activityConstants.LOCATION_ID) === '') {
      this.props.actions.updateMetadata(activityConstants.LOCATION_ID, nextProps.locationId);
    }
    if (this.props.locationId !== nextProps.locationId && nextProps.campaigns.isEmpty()) {
      nextProps.actions.getCampaigns();
    }
    if (nextProps.activity.get(activityConstants.CAMPAIGN_KEY) != '' || (!this.isOneTimeActionFilteredExternally(nextProps) && !this.state.activityHasMigrated)) {
      this.setState({ needToChooseCampaign: false });
    } else if (nextProps.activity.get(activityConstants.CAMPAIGN_KEY) === '' &&
      (this.isOneTimeActionFilteredExternally(nextProps) || this.state.activityHasMigrated) && !this.isExistingActivity() && nextProps.campaignsLoaded) {
      this.assignActivityToCampaign(nextProps.campaigns, nextProps.activity.get(activityConstants.ACTIVITY_SOURCE));
    } else if (
      !_.isEmpty(this.props.activity.get(activityConstants.TRIGGER)) &&
      nextProps.activity.get(activityConstants.TRIGGER) !== this.props.activity.get(activityConstants.TRIGGER) &&
      !this.props.tempOriginActivity
    ) {
      const allowedOccurrences = this.checkAllowedOccurrences(nextProps.activity.get(activityConstants.TRIGGER));
      if (allowedOccurrences) {
        this.props.actions.changeActivityActionMode(activityConstants.ACTIONS_SIMPLE_MODE, 0);
      }
    }
    if (this.state.onetimeNoFile && !this.state.needToChooseCampaign && this.isOneTimeActionFilteredExternally(nextProps) && !this.isExistingActivity()) {
      this.props.actions.setFilteredPopulationError();
    }
  }

  componentWillUnmount() {
    const { actions } = this.props;
    // noinspection JSUnresolvedFunction
    actions.clearDataOnUnmount();
    // noinspection JSUnresolvedFunction
    actions.markActivityClean();
    window.removeEventListener('scroll', this.handleScrollView);
  }

  assignActivityToCampaign = async (campaigns, source) => {
    if (source === activityConstants.FILTER_POPULATION_NAME) {
      const name = this.props.intl.formatMessage({ id: activityConstants.DEFAULT_CAMPAIGN_NAME_FROM_FILTER });
      const filterCampaign = campaigns.toJS().find((c) => c.text === name);
      if (!filterCampaign) {
        await this.createNewDefaultCampaign(name);
      } else {
        this.props.actions.updateMetadata(activityConstants.CAMPAIGN_KEY, filterCampaign.value);
      }
      this.forceUpdateName(activityConstants.ACTIVITY_FROM_FILTER_PREFIX + moment().format('L'));
      this.setState({ needToChooseCampaign: false });
    } else if (campaigns.isEmpty()) {
      this.setState({ needToChooseCampaign: false });
      this.createNewDefaultCampaign();
    } else if (campaigns.size === 1) {
      // If there is exactly 1 campaign, choose it
      this.setState({ needToChooseCampaign: false });
      const campaignKey = campaigns.getIn([0, 'value']);
      this.props.actions.updateMetadata(activityConstants.CAMPAIGN_KEY, campaignKey);
    }
  };

  onChooseCampaign(campaignKey) {
    this.props.actions.updateMetadata(activityConstants.CAMPAIGN_KEY, campaignKey);
    this.setState({
      refocusOnName: true,
      needToChooseCampaign: false
    });
  }

  getInitialDataByType(activityType) {
    const parsedQueryParams = queryStringUtils.parse(this.props.location.search); // eslint-disable-line react/prop-types
    const migrationId = parsedQueryParams[activityConstants.MIGRATION_ID];
    const activitySource = parsedQueryParams[activityConstants.ACTIVITY_SOURCE];
    this.props.actions.setActivitySource(activitySource || activityConstants.CAMPAIGN_CENTER_SOURCE);
    if (this.isExistingActivity()) {
      if (!this.props.activityDataLoaded) {
        this.props.appActions.setLoadingState(true);
      }
      // noinspection JSUnresolvedFunction
      this.props.actions.getActivity(this.props.match.params.id, activityType);
    } else if (activityType === activityConstants.ACTIVITY_TYPE_RULE ||
      activityType === activityConstants.ACTIVITY_TYPE_DEAL ||
      (activityType === activityConstants.ACTIVITY_TYPE_ONE_TIME && _.isNil(parsedQueryParams[activityConstants.FILTERED_POPULATION_SOURCE])) ||
      activityType === activityConstants.ACTIVITY_TYPE_PUNCH_CARD) {
      // If in rule or deal, we do not have a campaign set, we cannot move on. redirecting to campaign center
      if (this.props.activity.get(activityConstants.CAMPAIGN_KEY) === '' && !migrationId) {
        this.props.actions.goToBusinessCenter();
      }
    } else if (activityType === activityConstants.ACTIVITY_TYPE_GIFT && !this.props.activity.get(activityConstants.TRIGGER) && !migrationId) {
      this.props.actions.goToGiftsList();
    } else if (activityType === activityConstants.ACTIVITY_TYPE_PROMO_CODE && !this.props.activity.get(activityConstants.TRIGGER)) {
      this.props.actions.goToPromoCodesList();
    }
    if (migrationId) {
      // noinspection JSUnresolvedFunction
      this.props.actions.getMigratedActivityData(activityType, migrationId);
      if (activityType === activityConstants.ACTIVITY_TYPE_GIFT) {
        this.props.actions.getGifts();
      }
      this.setState({ activityHasMigrated: true });
    }
  }

  getActivityTypeFromPath() {
    switch (this.props.match.path) {
      case routeConstants.RULES_CREATE_ROUTE:
      case routeConstants.RULES_SPECIFIC_ROUTE: {
        return activityConstants.ACTIVITY_TYPE_RULE;
      }
      case routeConstants.DEALS_CREATE_ROUTE:
      case routeConstants.DEALS_SPECIFIC_ROUTE: {
        return activityConstants.ACTIVITY_TYPE_DEAL;
      }
      case routeConstants.ONETIME_CREATE_ROUTE:
      case routeConstants.BASIC_ONETIME_CREATE_ROUTE:
        tracker.onEvent(trackerConstants.EVENT_TYPE_CREATING_ONE_TIME_ACTION);
        return activityConstants.ACTIVITY_TYPE_ONE_TIME;
      case routeConstants.BASIC_ONETIME_SPECIFIC_ROUTE:
      case routeConstants.ONETIME_SPECIFIC_ROUTE: {
        return activityConstants.ACTIVITY_TYPE_ONE_TIME;
      }
      case routeConstants.PUNCH_CARDS_CREATE_ROUTE:
      case routeConstants.PUNCH_CARDS_SPECIFIC_ROUTE: {
        return activityConstants.ACTIVITY_TYPE_PUNCH_CARD;
      }
      case routeConstants.GIFTS_CREATE_ROUTE:
      case routeConstants.GIFTS_SPECIFIC_ROUTE: {
        return activityConstants.ACTIVITY_TYPE_GIFT;
      }
      case routeConstants.PROMO_CODES_CREATE_ROUTE:
      case routeConstants.PROMO_CODES_SPECIFIC_ROUTE: {
        return activityConstants.ACTIVITY_TYPE_PROMO_CODE;
      }
      case routeConstants.MICRO_CAMPAIGN_CREATE_ROUTE:
      case routeConstants.MICRO_CAMPAIGN_SPECIFIC_ROUTE: {
        return activityConstants.ACTIVITY_TYPE_MICRO_CAMPAIGN;
      }
      default:
        throw new Error('unknown activity type');
    }
  }

  getActivityHeaderAndPrefix() {
    switch (this.props.activity.get(activityConstants.TYPE)) {
      case activityConstants.ACTIVITY_TYPE_RULE: {
        return { activityPrefix: activityConstants.RULE_PREFIX, headerMsgId: 'rule.header' };
      }
      case activityConstants.ACTIVITY_TYPE_DEAL: {
        return { activityPrefix: activityConstants.DEAL_PREFIX, headerMsgId: 'dealsActivity.header' };
      }
      case activityConstants.ACTIVITY_TYPE_ONE_TIME: {
        return { activityPrefix: activityConstants.ONE_TIME_PREFIX, headerMsgId: 'onetime.when.label' };
      }
      case activityConstants.ACTIVITY_TYPE_PUNCH_CARD: {
        return { activityPrefix: activityConstants.PUNCH_CARD_PREFIX, headerMsgId: 'punchCardActivity.header' };
      }
      case activityConstants.ACTIVITY_TYPE_GIFT: {
        return { activityPrefix: activityConstants.GIFT_PREFIX, headerMsgId: 'gifts.header' };
      }
      case activityConstants.ACTIVITY_TYPE_PROMO_CODE: {
        return { activityPrefix: activityConstants.PROMO_CODE_PREFIX, headerMsgId: 'promoCodes.header' };
      }
      case activityConstants.ACTIVITY_TYPE_MICRO_CAMPAIGN: {
        return { activityPrefix: activityConstants.MICRO_CAMPAIGN_PREFIX, headerMsgId: 'microCampaigns.header' };
      }
      default: {
        throw new Error('unknown activity type');
      }
    }
  }

  /**
   * allow ab testing if one-time, min population filtered, and not inside usual split cases
   * @returns {boolean|(boolean|Boolean)}
   */
  isABTestingAllowed() {
    const activityType = this.props.activity.get(activityConstants.TYPE);
    const cases = this.props.activity.get(activityConstants.CASES);
    const casesMode = Boolean(cases);
    return activityType === activityConstants.ACTIVITY_TYPE_ONE_TIME
      && parseInt(this.props.filteredPopulation.get(activityConstants.FILTERED_POPULATION_COUNT), 10) >= activityConstants.MINIMUM_FILTERED_POPULATION_COUNT_FOR__TESTING
      && (!casesMode || this.props.abTestMode);
  }

  isExistingActivity() {
    return this.props.match.params.id;
  }

  updateOneTimeActionParams(queryParams) {
    const parsedQueryParams = queryStringUtils.parse(queryParams);
    // noinspection JSUnresolvedFunction, JSUnresolvedVariable
    this.props.actions.setFilteredPopulationData(
      parsedQueryParams.member_count,
      parsedQueryParams.filter,
      parsedQueryParams.file_name,
      parsedQueryParams.uri_base,
      parsedQueryParams.source);
    this.setState({
      onetimeNoFile: !parsedQueryParams.uri_base || !parsedQueryParams.file_name
    });
  }

  publishActivity() {
    let disableReFilteringFlag = this.props.hasFullPermission(appConstants.DISABLE_RE_FILTERING_FLAG);
    const nonMemberCommunicationFlag = this.props.hasFullPermission(appConstants.FEATURE_NON_MEMBER_COMMUNICATION);

    // skip re-filtering if non-member communication is enabled
    if(nonMemberCommunicationFlag) {
      disableReFilteringFlag = true;
    }
    this.props.actions.saveActivity({ isDraft: false, handleDeleteMemberAction: true, disableReFilteringFlag });
  }

  openOneTimeActionDialogForReFilteringCommunicationActions() {
    if (this.props.reFilterDialogOpen) {
      this.props.actions.closeReFilterDialog();
    }
    this.props.actions.openFilterModalAndApplyCommunicationActionFilter();
  }

  saveActivityAsDraft() {
    if (this.props.blockSendSmsActionDialogOpen) {
      this.props.actions.closeBlockSmsActionDialog();
    }
    this.props.actions.saveActivity({ isDraft: true });
  }

  // TODO: try to export to common utils on schema - use also in trigger.js
  checkAllowedOccurrences(selectedTriggerName) {
    let allowOccurrences = false;
    if (selectedTriggerName) {
      const triggerGroups = this.props.schema.get(activityConstants.TRIGGER_GROUPS);
      const selectedTriggerObj = triggerGroups.reduce((selected, triggerGroup) => {
        const selectedTrigger = triggerGroup.get(activityConstants.VALUES).find((trigger) => trigger.get(activityConstants.NAME) === selectedTriggerName);
        return selectedTrigger || selected;
      }, Map());
      allowOccurrences = selectedTriggerObj.get(activityConstants.ALLOW_OCCURRENCES) ? selectedTriggerObj.get(activityConstants.ALLOW_OCCURRENCES) : false;
    }
    return allowOccurrences;
  }

  forceUpdateName(name) {
    const nameTrimmed = name.trim();
    this.props.actions.forceUpdateName(this.props.locationId, this.props.activity, nameTrimmed);
  }

  updateName(name) {
    const activity = this.props.activity;
    const nameTrimmed = name.trim();
    this.props.actions.updateMetadata(activityConstants.NAME, nameTrimmed);
    if (nameTrimmed && this.props.activityValidationErrors) {
      // noinspection JSUnresolvedFunction
      this.props.actions.checkActivityNameAvailability(
        activity.get(activityConstants.LOCATION_ID),
        activity.get(activityConstants.HUB_ID),
        name, activity.get(activityConstants.TYPE));
    }
  }

  cancelChooseCampaign() {
    this.setState({ needToChooseCampaign: false });
    this.props.actions.goToBusinessCenter();
  }

  closeLandingPageModal() {
    this.props.actions.clearLandingPageActivityModalData();
    this.props.actions.goToGiftsList();
  }

  handleActivityCancelOrBack() {
    switch (this.props.activity.get(activityConstants.TYPE)) {
      case activityConstants.ACTIVITY_TYPE_GIFT: {
        if (this.props.tempOriginActivity) {
          // go back to origin activity
          // noinspection JSUnresolvedFunction
          this.props.actions.cancelCreateGiftFromActivity();
        } else {
          // go to gifts list
          this.props.actions.goToGiftsList();
        }
        break;
      }
      case activityConstants.ACTIVITY_TYPE_PROMO_CODE: {
        this.props.actions.goToPromoCodesList();
        break;
      }
      case activityConstants.ACTIVITY_TYPE_ONE_TIME: {
        const parsedQueryParams = queryStringUtils.parse(this.props.location.search);
        const activitySource = parsedQueryParams[activityConstants.ACTIVITY_SOURCE];
        if (activitySource && activitySource === activityConstants.FILTER_POPULATION_NAME && this.props.filteredPopulation.get(activityConstants.FILTERED_POPULATION_SOURCE) && this.props.filteredPopulation.get(activityConstants.FILTERED_POPULATION_SOURCE) === 'filter') {
          this.props.actions.goToFilterMembers();
        } else {
          this.props.actions.goToBusinessCenter();
        }
        break;
      }
      default: {
        // go to campaign center
        this.props.actions.goToBusinessCenter();
      }
    }
  }

  isOneTimeActionFilteredExternally(overrideProps) {
    if (overrideProps) {
      return overrideProps.activity.get(activityConstants.TYPE) === activityConstants.ACTIVITY_TYPE_ONE_TIME &&
        overrideProps.filteredPopulation &&
        !_.isNil(overrideProps.filteredPopulation.get(activityConstants.FILTERED_POPULATION_SOURCE));
    }
    return this.props.activity.get(activityConstants.TYPE) === activityConstants.ACTIVITY_TYPE_ONE_TIME &&
      this.props.filteredPopulation &&
      !_.isNil(this.props.filteredPopulation.get(activityConstants.FILTERED_POPULATION_SOURCE));
  }

  get isCreatingGiftFromActivity() {
    return this.props.tempOriginActivity;
  }

  get getActivityViewStatus() {
    return activitySelectorsUtils.calculateActivityViewStatus(this.props.activity);
  }

  reFilterPopulation() {
    this.props.actions.openFilterModalAndApplyFilter(true);
    // noinspection JSUnresolvedFunction
    this.props.actions.resetDraftOneTimeActionWithFilteredPopulationFlag();
  }

  cancelReFilterPopulation() {
    // noinspection JSUnresolvedFunction
    this.props.actions.resetDraftOneTimeActionWithFilteredPopulationFlag();
  }

  handleScrollView() {
    this.setState({
      isHeaderShadowed: window.scrollY > 0 && !this.props.activityValidationErrors
    });
  }

  get showTemplatesMenu() {
    return !(
      this.props.activity.get(activityConstants.TYPE) === activityConstants.ACTIVITY_TYPE_ONE_TIME
      && this.props.filteredPopulation.get(activityConstants.FILTERED_POPULATION_COUNT)
    );
  }

  createNewDefaultCampaign(name) {
    this.props.actions.setCampaignsLoaded(false);
    if (!name) {
      this.props.actions.createCampaign({
        name: this.props.intl.formatMessage({ id: 'business-center.campaign.toolbar.create.campaign.untitled' }),
        status: activityConstants.ACTIVITY_STATUS_ACTIVE
      });
    } else {
      this.props.actions.createCampaign({
        name,
        status: activityConstants.ACTIVITY_STATUS_ACTIVE
      });
    }
  }

  createOneTimeAction(confirmDeleteAction) {
    if (confirmDeleteAction) {
      let disableReFilteringFlag = this.props.hasFullPermission(appConstants.DISABLE_RE_FILTERING_FLAG);
      const nonMemberCommunicationFlag = this.props.hasFullPermission(appConstants.FEATURE_NON_MEMBER_COMMUNICATION);

      // skip re-filtering if non-member communication is enabled
      if(nonMemberCommunicationFlag) {
        disableReFilteringFlag = true;
      }
      this.props.actions.saveActivity({ isDraft: false, handleDeleteMemberAction: false, disableReFilteringFlag });
    }
    this.props.actions.closeConfirmDeleteActionDialog();
  }

  getMainContent(activityType, filters) {
    const { activityPrefix, headerMsgId } = this.getActivityHeaderAndPrefix();
    const cases = this.props.activity.get(activityConstants.CASES);
    const hasCases = Boolean(cases);
    const allowOccurrences = this.checkAllowedOccurrences(this.props.activity.get(activityConstants.TRIGGER));
    const actionsWrappers = this.props.activity.get(activityConstants.ACTIONS);
    const activity = this.props.activity;

    if (this.props.activityTabs.length) {
      return (
        <TabbedActivityContent
          activityPrefix={activityPrefix}
          activityTrigger={activity.get(activityConstants.TRIGGER)}
          activityType={activityType}
          headerMsgId={headerMsgId}
          activityValidationErrors={this.props.activityValidationErrors}
          displayTabValidationErrors={this.props.displayTabValidationErrors}
          activityConditions={activity.getIn([activityConstants.CONDITIONS, activityConstants.CONDITIONS_LIST])}
          schemaConditionsPerTrigger={this.props.conditionsPerTrigger}
          schemaMembershipConditions={this.props.schemaMembershipConditions}
          schemaDateTimeConditions={this.props.schemaDateTimeGlobalConditions}
          activityDateTimeGlobalConditions={activity.get(activityConstants.DATETIME_GLOBAL_CONDITIONS)}
          casesMode={hasCases}
          schemaActionsPerTrigger={this.props.actionsPerTrigger}
          allowOccurrences={allowOccurrences}
          cases={cases}
          casesActions={this.props.casesActions}
          activityActionsWrappers={actionsWrappers}
          features={this.props.features}
          activityName={activity.get(activityConstants.NAME)}
          activityDescription={activity.get(activityConstants.DESCRIPTION)}
          landingPageData={activity.get(activityConstants.LANDING_PAGE_DATA)}
          location={this.props.location}
          getBusinessBundlePlan={this.props.getBusinessBundlePlan}
          showSplit={this.state.showSplitCases}
        />
      );
    }
    return (
      <ActivityBody
        actions={this.props.actions}
        activityActionsWrappers={actionsWrappers}
        activityConditions={activity.getIn([activityConstants.CONDITIONS, activityConstants.CONDITIONS_LIST])}
        activityDateTimeGlobalConditions={activity.get(activityConstants.DATETIME_GLOBAL_CONDITIONS)}
        activityTrigger={activity.get(activityConstants.TRIGGER)}
        activityType={activityType}
        allowOccurrences={allowOccurrences}
        cases={cases}
        casesActions={this.props.casesActions}
        casesMode={hasCases}
        clearTempTrigger={this.props.actions.clearTempTrigger}
        features={this.props.features}
        filteredPopulation={this.props.filteredPopulation}
        filters={filters}
        headerMsgId={headerMsgId}
        disabledGlobalConditions={this.props.disabledGlobalConditions}
        isOneTimeActionFilteredExternally={this.isOneTimeActionFilteredExternally}
        mentionsList={this.props.mentions}
        onSelectTrigger={this.props.actions.updateTrigger}
        pendingTrigger={this.props.pendingTrigger}
        schemaActionsPerTrigger={this.props.actionsPerTrigger}
        schemaConditionsPerTrigger={this.props.conditionsPerTrigger}
        schemaDateTimeConditions={this.props.schemaDateTimeGlobalConditions}
        schemaMembershipConditions={this.props.schemaMembershipConditions}
        triggerGroups={this.props.schema.get(activityConstants.TRIGGER_GROUPS)}
        updateTriggerPreValidation={this.props.actions.updateTriggerPreValidation}
        validationErrors={this.props.activityValidationErrors}
        activityStatus={this.props.activity.get(activityConstants.STATUS)}
        activityViewStatus={this.getActivityViewStatus}
        abTestMode={this.props.abTestMode}
        isABTestingAllowed={this.isABTestingAllowed()}
        getBusinessBundlePlan={this.props.getBusinessBundlePlan}
        showBasicPlan={this.props.showBasicPlan}
        hasFullPermission={this.props.hasFullPermission}
      />
    );
  }


  render() {
    const { formatMessage } = this.props.intl;
    const { getPermissionLevel, actions, giftsTags, activitiesTags } = this.props;
    const activityType = this.props.activity.get(activityConstants.TYPE);
    if (!activityType) {
      return null;
    }

    const originActivityType = this.props.tempOriginActivity ? this.props.tempOriginActivity.getIn([activityConstants.DATA, activityConstants.TYPE]) : '';
    const { activityPrefix } = this.getActivityHeaderAndPrefix();
    const permissionLevel = getPermissionLevel('Product Templates');
    let filters = [];
    const filterPopulation = this.props.filteredPopulation ? this.props.filteredPopulation.get(activityConstants.FILTER_POPULATION_NAME) : null;
    if (filterPopulation) {
      filters = filterPopulation.split(activityConstants.POPULATION_FILTER_DELIMITER);
    }
    // Since some of the filters get here corrupted (in the format =if...) we need to do the following workaround
    let numberOfComplexFilters = 0;
    filters = filters.filter((filter) => {
      if (filter.startsWith('=')) {
        numberOfComplexFilters += 1;
        return false;
      }
      return true;
    });
    if (numberOfComplexFilters > 0 && filters.length > 0) {
      const complexFiltersLabel = numberOfComplexFilters > 1 ?
        formatMessage({ id: 'onetime.complex.additional.filters.label' }, { numOfFilters: numberOfComplexFilters }) :
        formatMessage({ id: 'onetime.complex.additional.filter.label' });
      filters.push(complexFiltersLabel);
    }

    const hasTopNotification = this.isCreatingGiftFromActivity || this.state.activityHasMigrated;
    const hasTopTabs = activityType === activityConstants.ACTIVITY_TYPE_GIFT || activityType === activityConstants.ACTIVITY_TYPE_PUNCH_CARD || activityType === activityConstants.ACTIVITY_TYPE_PROMO_CODE;
    const shouldActivityTagBeShown = this.props.hasFullPermission(appConstants.FEATURE_ACTIVITY_TAGS);
    const allTags = [...giftsTags, ...activitiesTags];
    const cases = this.props.activity.get(activityConstants.CASES);
    const simpleModeIsOn = this.props.activity.get(activityConstants.GIFT_SIMPLE_MODE);
    const hasCases = Boolean(cases);
    return (
      <div className={activityType}>
        <div className="activity">
          <Helmet>
            <title>{formatMessage({
              id: `${activityType}.browser.title`
            }, { businessName: this.props.businessName })}</title>
            <meta name="description" content="Description of Activity" />
          </Helmet>
          {
            this.isCreatingGiftFromActivity &&
            <HeaderNotification
              activityName={this.props.tempOriginActivity.getIn([activityConstants.DATA, activityConstants.NAME])}
              activityType={originActivityType}
              automationIdPrefix={activityPrefix}
              createdEntityType={this.props.tempOriginActivity.get(activityConstants.TYPE)}
            />
          }
          {
            this.state.activityHasMigrated &&
              <HeaderNotification
                messageKey="activity.migration.process.hub1"
                automationIdPrefix={activityPrefix}
              />
          }
          <ActivityHeader
            isHeaderSticky={this.state.isHeaderSticky}
            refocusOnName={this.state.refocusOnName}
            saveActivity={this.publishActivity}
            saveActivityAsDraft={this.saveActivityAsDraft}
            updateMetadata={actions.updateMetadata}
            updateName={this.updateName}
            onCancelOrBackClick={this.handleActivityCancelOrBack}
            isHeaderShadowed={this.state.isHeaderShadowed}
            activityHasMigrated={this.state.activityHasMigrated}
            hasFullPermission={this.props.hasFullPermission}
            getBusinessBundlePlan={this.props.getBusinessBundlePlan}
          />
          <ChooseOption
            isOpen={this.state.needToChooseCampaign}
            onClose={() => this.cancelChooseCampaign()}
            onConfirm={this.onChooseCampaign}
            options={this.props.campaigns}
            optionType="campaign"
          />
          <ConfirmationDialog
            automationIdPrefix={'draft.oneTimeAction.old.population.note.confirmation'}
            cancelAction={this.cancelReFilterPopulation}
            cancelButtonKey="general.button.cancel"
            contentMessageKey="activity.note.oneTime.draft.constant.population.message"
            confirmAction={this.reFilterPopulation}
            confirmButtonKey="general.button.filter"
            open={this.props.draftOldFilteredPopulation}
            titleKey="activity.note.oneTime.draft.constant.population.header"
          />
          <ConfirmationDialog
            automationIdPrefix={'oneTimeAction.delete.members.note.confirmation'}
            cancelAction={() => this.createOneTimeAction(false)}
            cancelButtonKey="general.button.cancel"
            contentMessageKey="oneTimeAction.delete.members.note.message"
            confirmAction={() => this.createOneTimeAction(true)}
            confirmButtonKey="oneTimeAction.delete.members.note.confirm"
            open={this.props.confirmDeleteActionDialogOpen}
            titleKey="oneTimeAction.delete.members.note.header"
          />
          <ConfirmationDialog
            automationIdPrefix={'oneTimeAction.block.action.note.confirmation'}
            cancelAction={this.props.actions.closeBlockSmsActionDialog}
            cancelButtonKey="general.button.cancel"
            contentMessageKey="oneTimeAction.block.sms.action.note.message"
            confirmAction={this.saveActivityAsDraft}
            confirmButtonKey="activity.header.save-as-draft"
            open={this.props.blockSendSmsActionDialogOpen}
            titleKey="oneTimeAction.block.sms.action.note.header"
          />
          <ConfirmationDialog
            automationIdPrefix={'oneTimeAction.reFilter-activate.confirmation'}
            cancelAction={this.props.actions.closeReFilterDialog}
            cancelButtonKey="general.button.cancel"
            contentMessageKey="oneTimeAction.reFilter-activate-message"
            confirmAction={this.openOneTimeActionDialogForReFilteringCommunicationActions}
            confirmButtonKey="activity.header.reFilter-activate"
            open={this.props.reFilterDialogOpen}
            titleKey="activity.header.reFilter-activate.header"
          />
          { this.props.landingPageModalData
            && this.props.landingPageModalData.get(activityConstants.LANDING_PAGE_MODAL_IS_OPEN)
            && <LandingPageModal
              onClose={() => this.closeLandingPageModal()}
              open={this.props.landingPageModalData
              && this.props.landingPageModalData.get(activityConstants.LANDING_PAGE_MODAL_IS_OPEN)}
              url={this.props.landingPageModalData.get(activityConstants.LANDING_PAGE_SHORTENED_URL)}
              activityName={this.props.activity.get(activityConstants.NAME)}
              locationId={this.props.locationId}
            />}

          <Form className="activity-body">
            {
              (this.props.activityValidationErrors &&
                (
                  this.props.activityValidationErrors.get(activityConstants.ERRORS_FROM_SERVER)
                    ?
                      <div className={`toolbar-error-panel ${hasTopNotification ? 'has-top-notification' : ''} ${hasTopTabs ? 'has-top-tabs' : ''}`}>
                        <span
                          className="toolbar-error-text"
                        >{formatMessage({ id: 'activity.validation.error.rule.server.errors' })}</span>
                      </div>
                    :
                      <div className={`toolbar-error-panel ${hasTopNotification ? 'has-top-notification' : ''} ${hasTopTabs ? 'has-top-tabs' : ''}`}>
                        <span
                          className="toolbar-error-text"
                        >{formatMessage({ id: 'activity.validation.error.rule.general' })}</span>
                      </div>
                )
              )
            }
            {
              activityType !== activityConstants.ACTIVITY_TYPE_PUNCH_CARD &&
              activityType !== activityConstants.ACTIVITY_TYPE_PROMO_CODE &&
              <TemplatesMenu
                permissionLevel={permissionLevel}
                prefix={activityPrefix}
                template={this.props.template}
                showMenu={this.showTemplatesMenu}
                entityType={activityType}
                shouldActivityTagBeShown={shouldActivityTagBeShown}
                activity={this.props.activity}
                updateGiftSimpleMode={this.props.actions.updateGiftSimpleMode}
                showSimpleModeToggle={(activityType === activityConstants.ACTIVITY_TYPE_GIFT && this.props.hasFullPermission(activityConstants.GIFT_SIMPLE_MODE_FEATURE)) || simpleModeIsOn}
                disableSimpleModeToggle={hasCases}
                simpleModeTooltipMessage={hasCases ? formatMessage({ id: 'activity.action.simpleModeOnCases.tooltip' }) : undefined}
              />
            }
            <ActivityTags
              activityTags={this.props.activityTags}
              autoCompleteOptions={allTags}
              handleActivityTagChange={this.handleActivityTagChange}
              shouldActivityTagBeShown={shouldActivityTagBeShown}
              onOpen={() => this.setState({ showSplitCases: false })}
              onClose={() => this.setState({ showSplitCases: true })}
            />
            {this.getMainContent(activityType, filters)}
            <Grid>
              <Grid.Row>
                <input
                  data-automation-id={this.props.activity.get(activityConstants.TYPE) === activityConstants.ACTIVITY_TYPE_ONE_TIME
                    ? `serverId.${activityConstants.ACTIVITY_TYPE_ONE_TIME}.${this.props.activity.get(activityConstants.SERVER_ID)}`
                    : this.props.activity.get(activityConstants.SERVER_ID)}
                  hidden
                />
              </Grid.Row>
            </Grid>
          </Form>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  activity: selectors.getActivityDataModel(state),
  activityTabs: selectors.getActivityTabs(state),
  activityDataLoaded: selectors.getActivityDataLoadedFlag(state),
  campaigns: businessCenterSelectors.getSortedReducedCampaignsData(state),
  campaignsLoaded: businessCenterSelectors.getCampaignsLoadedFlag(state),
  draftOldFilteredPopulation: selectors.getDraftOldFilteredPopulationFlag(state),
  schema: selectors.getSchemaWithFeatures(state),
  disabledGlobalConditions: selectors.getDisabledGlobalConditionsForSelectedTrigger(state),
  mentions: mentionsSelectors.getTransformedMentions(state),
  filteredPopulation: selectors.getOneTimeActionFilteredPopulation(state),
  schemaMembershipConditions: schemaSelectors.getTransformedGlobalMembershipConditions(state),
  conditionsPerTrigger: selectors.getConditionsForSelectedTrigger(state),
  activityValidationErrors: selectors.getActivityValidationErrors(state),
  displayTabValidationErrors: selectors.getDisplayTabValidationErrors(state),
  registrationFormFields: formsSelectors.getRegistrationFormFields(state),
  locationId: appSelectors.getLocationId(state),
  businessName: appSelectors.getBusinessDisplayName(state),
  template: selectors.getTemplate(state),
  actionsPerTrigger: selectors.getActionsForSelectedTrigger(state),
  schemaDateTimeGlobalConditions: selectors.getDateTimeGlobalConditionSchema(state),
  tempOriginActivity: selectors.getTempOriginActivity(state),
  pendingTrigger: selectors.getPendingTrigger(state),
  backToActivityInState: selectors.getBackToActivityInStateFlag(state),
  landingPageModalData: selectors.getLandingPageModalData(state),
  getPublishOrSaveInProgressFlag: selectors.getPublishOrSaveInProgressFlag(state),
  hasActionValidationErrorsForTest: selectors.getActionValidationErrorsForTest(state),
  confirmDeleteActionDialogOpen: selectors.getConfirmDeleteActionDialogOpen(state),
  blockSendSmsActionDialogOpen: selectors.getBlockSmsActionDialogOpen(state),
  adjustFilterByMemberProperties: selectors.getAdjustByMemberPropertyFlag(state),
  reFilterDialogOpen: selectors.getReFilterDialogOpen(state),
  activityTags: selectors.getActivityTags(state),
  lookerQueryFetched: selectors.getLookerFetchedFlag(state),
  abTestMode: selectors.getABTestMode(state),
  activitiesTags: businessCenterSelectors.getAllActivityTags(state),
  giftsTags: giftsSelectors.getGiftsTags(state),
  gifts: giftsSelectors.getGifts(state),
});

function mapDispatchToProps(dispatch) {
  const creators = Object.assign({},
    activityFeatureActions,
    formsActions,
    {
      ...activityTemplateActions,
      goToGiftsList: giftsActions.goToGiftsList,
      goToPromoCodesList: promoCodesActions.goToPromoCodesList,
      getGifts: giftsActions.getGifts,
      setCampaignsLoaded: businessActions.setCampaignsLoaded,
      goToBusinessCenter: businessActions.goToBusinessCenter,
      createCampaign: businessActions.createCampaign,
      getCampaigns: businessActions.getCampaigns,
      openFilterModalAndApplyFilter: globalConditionsActions.openFilterModalAndApplyFilter,
      openFilterModalAndApplyCommunicationActionFilter: globalConditionsActions.openFilterModalAndApplyCommunicationActionFilter,
      goToFilterMembers: filterMemberActions.goToFilterMembers
    });

  return {
    actions: bindActionCreators(creators, dispatch),
    casesActions: bindActionCreators(casesActions, dispatch),
    appActions: bindActionCreators(appActions, dispatch),
    membersActions: bindActionCreators(membersActions, dispatch)
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);
const withSaga = injectSaga({ key: reducerConstants.ACTIVITY_BRANCH, saga });

const withTemplatesSaga = injectSaga({ key: reducerConstants.TEMPLATES_BRANCH, saga: templatesSaga });
const withFormsSaga = injectSaga({ key: reducerConstants.FORMS_BRANCH, saga: formsSaga });
const withBusinessSaga = injectSaga({ key: reducerConstants.BUSINESS_CENTER_BRANCH, saga: businessSaga });
const withGiftsSaga = injectSaga({ key: reducerConstants.GIFTS_BRANCH, saga: giftsSaga });
const withEmailsSaga = injectSaga({ key: reducerConstants.EMAILS_BRANCH, saga: emailsSaga });
const withPromoCodesSaga = injectSaga({ key: reducerConstants.PROMO_CODES_BRANCH, saga: promoCodesSaga });
const withFilterMembersSaga = injectSaga({ key: reducerConstants.FILTER_MEMBERS, saga: filterMembersSaga });
const withMembersSaga = injectSaga({ key: reducerConstants.CUSTOMER_BRANCH, saga: membersSaga });

export default compose(
  withSaga,
  withTemplatesSaga,
  withFormsSaga,
  withBusinessSaga,
  withGiftsSaga,
  withEmailsSaga,
  withPromoCodesSaga,
  withFilterMembersSaga,
  withMembersSaga,
  withConnect,
  withAuthorization
)(injectIntl(Activity));
