import moment from 'moment';
import { fromJS, List, Map } from 'immutable';
import _ from 'lodash';
import { validateAction } from './action.validation.utils';
import * as constants from '../../../activity.constants';
import * as itemPopulationConstants from '../../../views/itemsPopulation/itemsPopulation.constants';
import {
  validateConditionsList,
  validateItemPopulationConditions,
  validateRegistrationFormFieldsInConditions,
  validateUpdateMembershipCondition
} from './conditions.validation.utils';
import * as updateMembershipConstants
  from '../../../views/conditions/updateMembershipCondition/updateMembership.constants';
import * as schemaSelectors from '../../../activitySchema/activitySchema.selectors';
import { validateFiltersInTempModel, validateGlobalConditions } from './global.conditions.validations.utils';
import * as datesConstants from '../../../../../common/utils/dateTime.constants';
import * as appSelectors from '../../../../App/selectors';
import * as schemaDefaults from '../../../activitySchema/activitySchema';

const ACTIONS_ERRORS_PATH = [constants.VALIDATION_ERRORS, constants.ACTIVITY_ACTIONS];

export function validateActivityMetadata(state, isDraft) {
  let newState = state;
  if (newState.getIn([constants.DATA, constants.NAME]) === '') {
    newState = newState.setIn([constants.VALIDATION_ERRORS, constants.ACTIVITY_HEADER, constants.NAME],
      fromJS('activity.validation.error.activity.name.required'));
  } else if (newState.getIn([constants.DATA, constants.VALIDATION_NAME_TAKEN_FLAG]) === true) {
    newState = handleActivityNameAlreadyInUseError(newState);
  } else if (newState.getIn([constants.DATA, constants.VALIDATION_PROMO_CODE_TAKEN_FLAG]) === true) {
    if (newState.getIn([constants.DATA, constants.PROMO_CODE_BULKS, constants.PROMO_CODE_MODE]) === constants.PROMO_CODE_MODE_SINGLE_CODE) {
      newState = handleSinglePromoCodeAlreadyInUseError(newState);
    } else if (newState.getIn([constants.DATA, constants.PROMO_CODE_BULKS, constants.PROMO_CODE_MODE]) === constants.PROMO_CODE_MODE_FILE_UPLOADED) {
      newState = handleUplaodedInFilePromoCodesAlreadyInUseError(newState);
    } else if (!newState.getIn([constants.DATA, constants.VALIDATION_PROMO_CODE_TAKEN_FLAG])) {
      newState = handleUplaodedInFilePromoCodesAlreadyInUseError(newState);
    }
  } else if (newState.getIn([constants.DATA, constants.VALIDATION_PROMO_CODE_ERROR])) {
    newState = newState.setIn([constants.VALIDATION_ERRORS, constants.PROMO_CODE_BULKS, constants.PROMO_CODE_MODE_FILE_UPLOADED, '0'], newState.getIn([constants.DATA, constants.VALIDATION_PROMO_CODE_ERROR]));
  }
  if (!isDraft && [constants.ACTIVITY_TYPE_PUNCH_CARD, constants.ACTIVITY_TYPE_GIFT, constants.ACTIVITY_TYPE_PROMO_CODE].includes(newState.getIn([constants.DATA, constants.TYPE]))
    && !newState.getIn([constants.DATA, constants.DESCRIPTION])) {
    newState = newState.setIn([constants.VALIDATION_ERRORS, constants.ACTIVITY_HEADER, constants.DESCRIPTION],
      fromJS('activity.validation.error.activity.description.required'));
  }
    if (!isDraft && newState.getIn([constants.DATA, constants.TYPE]) === constants.ACTIVITY_TYPE_GIFT && newState.getIn([constants.DATA, constants.DISPLAY_TAB_DESCRIPTION]) && newState.getIn([constants.DATA, constants.DISPLAY_TAB_DESCRIPTION]).length > constants.ACTIVITY_DESCRIPTION_LENGTH_LIMIT) {
      newState = newState.setIn([constants.VALIDATION_ERRORS, constants.DISPLAY_TAB, constants.DISPLAY_TAB_DESCRIPTION],
        fromJS('activity.validation.error.activity.description.length'));
    }
  return newState;
}

export function handleActivityNameAlreadyInUseError(state) {
  return state.setIn([constants.VALIDATION_ERRORS, constants.ACTIVITY_HEADER],
    fromJS({ [constants.NAME]: 'activity.validation.error.activity.name.already.used' }));
}

export function handleSinglePromoCodeAlreadyInUseError(state) {
  return state.setIn([constants.VALIDATION_ERRORS, constants.PROMO_CODE_BULKS, constants.PROMO_CODE_MODE_SINGLE_CODE, '0'],
    'activity.validation.error.promo.code.bulk.single.code.in.use');
}

export function handleUplaodedInFilePromoCodesAlreadyInUseError(state) {
  return state.setIn([constants.VALIDATION_ERRORS, constants.PROMO_CODE_BULKS, constants.PROMO_CODE_MODE_FILE_UPLOADED, '0'],
    'activity.validation.error.promo.code.bulk.single.code.in.use');
}


function validateTrigger(state) {
  let newState = state;
  if (!newState.getIn([constants.DATA, constants.TRIGGER])) {
    return newState.setIn([constants.VALIDATION_ERRORS, constants.TRIGGER], 'activity.validation.error.rule.trigger.required');
  }
  // check if trigger is deprecated
  const deprecatedTriggers = schemaSelectors.getDeprecatedTriggers(state);
  if (deprecatedTriggers.find((trigger) => trigger.get(constants.NAME) === newState.getIn([constants.DATA, constants.TRIGGER]))) {
    newState = newState.setIn([constants.VALIDATION_ERRORS, constants.TRIGGER], 'activity.validation.error.rule.trigger.deprecated');
  }
  return newState;
}

function validateTopConditions(state) {
  const newState = state;
  const topConditionErrors = validateConditionsList(newState.getIn([constants.DATA, constants.CONDITIONS, constants.CONDITIONS_LIST]));
  if (Object.keys(topConditionErrors).length > 0) {
    return newState.setIn([constants.VALIDATION_ERRORS, constants.CONDITIONS], fromJS(topConditionErrors));
  }
  return newState;
}

function validateCasesConditions(state, rootState) {
  let newState = state;
  const cases = state.getIn([constants.DATA, constants.CASES]);
  if (cases) {
    cases.forEach((caseData, index) => {
      const conditionsList = caseData.getIn([constants.CONDITIONS, constants.CONDITIONS_LIST]);
      const conditionsListErrors = validateConditionsList(conditionsList);
      const registrationFormFieldsErrors = validateRegistrationFormFieldsInConditions(
        conditionsList, rootState, schemaSelectors.getSchema(state), newState.getIn([constants.DATA, constants.TRIGGER]));
      const caseErrors = Object.assign({}, conditionsListErrors, registrationFormFieldsErrors);
      if (Object.keys(caseErrors).length > 0) {
        newState = newState.setIn(
          [...ACTIONS_ERRORS_PATH, constants.CASES, index.toString(), constants.CONDITIONS], fromJS(caseErrors));
      }
    });
  }
  return newState;
}

function validateActionList(actionsList, rootState, isLastActionsWrapperInRedeemableActivity) {
  const actionsListErrors = {};
  // If we have less than 2 actions, it means that we don't have an actual action (only the empty, placeholder one)
  if (!actionsList || (actionsList.size === 1 && actionsList.getIn(['0', constants.ACTION_TYPE]) === '')) {
    // in benefit default empty action is in a new actionsWrapper so it should not fail in validation
    // in rule we have only one actinosWrapper so this means no action chosen at all, this should fail in validation
    if (!isLastActionsWrapperInRedeemableActivity) {
      return { '0': { [constants.VALIDATION_ERRORS_NO_ACTION]: 'activity.validation.error.rule.action.required' } };
    }
  }
  // If we have at least 1 real action, continue with validations within each action
  actionsList.forEach((action, index) => {
    const actionErrors = validateAction(action, rootState);
    if (Object.keys(actionErrors).length > 0) {
      actionsListErrors[index.toString()] = actionErrors;
    }
  });
  return actionsListErrors;
}

function validateActionBundle(actionsBundle) {
  if (!actionsBundle) {
    return {};
  }
  return validateConditionsList(actionsBundle.getIn([constants.VALUE, constants.CONDITIONS_LIST]));
}

function validateActions(state, rootState) {
  let newState = state;
  if (newState.hasIn([constants.DATA, constants.ACTIONS])) {
    const actionsWrappers = newState.getIn([constants.DATA, constants.ACTIONS]);
    let actionsWrappersErrorsList = List();
    // when we have cases, actions list is null
    if (!actionsWrappers) {
      return newState;
    }
    actionsWrappers.forEach((actionsWrapper, index) => {
      // in benefit we have one action in the list under actionsWrapper, if there is more than 1 actionsWrapper than the last one is empty for the default empty action
      // in rule there is only 1 actionsWrapper and the default actionsWrapper is last in the list under the same actionsWrapper
      const isLastActionsWrapperInRedeemableActivity = index > 0 && index + 1 === actionsWrappers.size && schemaDefaults.redeemableActivities.includes(newState.getIn([constants.DATA, constants.TRIGGER]));
      const actionsListErrors = validateActionList(
        actionsWrapper.get(constants.ACTIONS_LIST),
        rootState, isLastActionsWrapperInRedeemableActivity);
      let actionsWrapperErrors = Map();
      if (Object.keys(actionsListErrors).length > 0) {
        actionsWrapperErrors = actionsWrapperErrors.set(constants.ACTIONS_LIST, fromJS(actionsListErrors));
      }
      const actionsBundleErrors = validateActionBundle(actionsWrapper.get(constants.BUNDLE));
      if (Object.keys(actionsBundleErrors).length > 0) {
        actionsWrapperErrors = actionsWrapperErrors.set(constants.BUNDLE, fromJS(actionsBundleErrors));
      }

      if (Object.keys(actionsListErrors).length > 0 || Object.keys(actionsBundleErrors).length > 0) {
        actionsWrappersErrorsList = actionsWrappersErrorsList.set(index, actionsWrapperErrors);
      }
    });
    if (actionsWrappersErrorsList.size > 0) {
      newState = newState.setIn([...ACTIONS_ERRORS_PATH, constants.ACTIVITY_ACTIONS], actionsWrappersErrorsList);
    }
  }
  return newState;
}

function validateCasesActions(state, rootState) {
  let newState = state;
  const cases = state.getIn([constants.DATA, constants.CASES]);
  if (cases) {
    cases.forEach((value, index) => {
      if (value.has(constants.ACTIONS)) {
        const actionsWrappers = value.get(constants.ACTIONS);
        actionsWrappers.forEach((actionsWrapper, actionsWrapperIndex) => {
          const isLastActionsWrapperInRedeemableActivity = actionsWrapperIndex > 0 && actionsWrapperIndex + 1 === actionsWrappers.size && schemaDefaults.redeemableActivities.includes(newState.getIn([constants.DATA, constants.TRIGGER]));
          const actionsListErrors = validateActionList(
            actionsWrapper.get(constants.ACTIONS_LIST), rootState, isLastActionsWrapperInRedeemableActivity);
          if (Object.keys(actionsListErrors).length > 0) {
            newState = newState.setIn(
              [...ACTIONS_ERRORS_PATH, constants.CASES, index.toString(), constants.ACTIONS, actionsWrapperIndex, constants.ACTIONS_LIST],
              fromJS(actionsListErrors));
          }
          const actionsBundleErrors = validateActionBundle(actionsWrapper.get(constants.BUNDLE));
          if (Object.keys(actionsBundleErrors).length > 0) {
            newState = newState.setIn(
              [...ACTIONS_ERRORS_PATH, constants.CASES, index.toString(), constants.ACTIONS, actionsWrapperIndex, constants.BUNDLE],
              fromJS(actionsBundleErrors));
          }
        });
      }
    });
  }
  return newState;
}

function validateTempItemSelectionData(state) {
  const populationType = state.getIn([itemPopulationConstants.TEMP_ITEMS_POPULATION, itemPopulationConstants.ITEMS_POPULATION_TYPE]);
  let validationErrors = {};
  let newState = state;
  if (!_.isNil(populationType)) {
    const itemPopulationConditions = state.getIn([itemPopulationConstants.TEMP_ITEMS_POPULATION, itemPopulationConstants.ITEMS_POPULATION, populationType, constants.CONDITIONS, constants.CONDITIONS_LIST]);
    validationErrors = validateItemPopulationConditions(itemPopulationConditions, populationType);
    if (!_.isEmpty(validationErrors)) {
      newState = newState.setIn([constants.TEMP_VALIDATION_ERRORS, itemPopulationConstants.TEMP_ITEMS_POPULATION, populationType], fromJS(validationErrors));
    }
  }
  return newState;
}

function validateTempUpdateMembershipCondition(state) {
  let newState = state;
  const updateMembershipCondition = state.getIn([updateMembershipConstants.UPDATE_MEMBERSHIP_TEMP_DATA]);
  if (!updateMembershipCondition) {
    return state;
  }
  const validationErrors = validateUpdateMembershipCondition(updateMembershipCondition);
  if (!_.isEmpty(validationErrors)) {
    newState = newState.setIn([constants.TEMP_VALIDATION_ERRORS, updateMembershipConstants.UPDATE_MEMBERSHIP_TEMP_DATA], fromJS(validationErrors));
  }
  return newState;
}

function validateTempApplyOnModel(state) {
  if (!state.get(constants.TEMP_APPLY_ON_BUNDLE_MODEL)) {
    return state;
  }
  const bundleItemsMode = state.getIn([constants.TEMP_APPLY_ON_BUNDLE_MODEL, constants.BUNDLE_ITEMS_MODE]);
  let newState = state;
  if (!bundleItemsMode) {
    newState = newState.setIn([constants.TEMP_VALIDATION_ERRORS, constants.TEMP_APPLY_ON_BUNDLE_MODEL, constants.BUNDLE_ITEMS_MODE], fromJS('activity.validation.error.deal.action.advanced.bundleItemsMode.required'));
  }
  if (bundleItemsMode === constants.BUNDLE_ITEMS_MODE_SPECIFIC && state.getIn([constants.TEMP_APPLY_ON_BUNDLE_MODEL, constants.APPLY_ON])) {
    let isAtLeastOneChecked = false;
    state.getIn([constants.TEMP_APPLY_ON_BUNDLE_MODEL, constants.APPLY_ON]).forEach((condition) => {
      if (condition.get(constants.CONDITION_SELECTED)) {
        isAtLeastOneChecked = true;
      }
    });
    if (!isAtLeastOneChecked) {
      newState = newState.setIn([constants.TEMP_VALIDATION_ERRORS, constants.TEMP_APPLY_ON_BUNDLE_MODEL, constants.APPLY_ON], fromJS('activity.validation.error.deal.action.advanced.actionsModeSpecific.applyOn.required'));
    }
  }
  return newState;
}

export function validateActivity(state, rootState) {
  let newState = resetValidations(state);
  newState = validateActivityMetadata(newState, false);
  newState = validateTrigger(newState);
  newState = validateGlobalConditions(newState, rootState);
  newState = validateTopConditions(newState);
  newState = validateCasesConditions(newState, rootState);
  newState = validateActions(newState, rootState);
  newState = validateCasesActions(newState, rootState);
  newState = validateActivitySchedule(newState, rootState);
  newState = validateActivityScheduleAction(newState);
  newState = validatePromoCodeBulks(newState);
  newState = validateTempItemSelectionData(newState);
  newState = validateTempApplyOnModel(newState);
  newState = validateTempUpdateMembershipCondition(newState);
  if (newState.getIn([constants.DATA, constants.TYPE]) === constants.ACTIVITY_TYPE_PUNCH_CARD) {
    newState = validatePunchCardChildActivities(newState, rootState);
  }
  if (newState.getIn([constants.DATA, constants.TYPE]) === constants.ACTIVITY_TYPE_GIFT) {
    newState = validateGiftDisplayConditions(newState, rootState);
  }
  return newState;
}

export function validateLimitOccurrencesPerMemberGlobalConditions(state) {
  let newState = state;
  newState = validateLimitOccurrencesPerMemberConditions(newState);
  newState = validateTempLimitOccurrencesPerMemberConditions(newState);
  return newState;
}

export function validateTempFilters(state, rootState) {
  let newState = validateFiltersInTempModel(state, rootState);
  newState = validateTempItemSelectionData(newState);
  return newState.get(constants.TEMP_VALIDATION_ERRORS);
}

export function validateFilteredPopulationCount(memberCount) {
  if (memberCount === 0) {
    return fromJS({ [constants.NO_MEMBERS_FILTERED_ERROR]: 'activity.validation.error.zero.members.filtered' });
  }
  return null;
}

function validateLimitOccurrencesPerMemberConditions(newState) {
  return validateLimitOccurrencesPerMemberGlobalConditionsModel(newState,
    constants.LIMIT_OCCURRENCES_PER_MEMBER_GLOBAL_CONDITIONS,
    constants.VALIDATION_ERRORS);
}

function validateTempLimitOccurrencesPerMemberConditions(newState) {
  return validateLimitOccurrencesPerMemberGlobalConditionsModel(
    newState,
    constants.LIMIT_OCCURRENCES_PER_MEMBER_GLOBAL_CONDITIONS_TEMP_MODEL,
    constants.TEMP_VALIDATION_ERRORS);
}

function validateLimitOccurrencesPerMemberGlobalConditionsModel(state, modelPathKey, errorsModelKey) {
  const basePath = [constants.DATA, modelPathKey];
  let newState = state;
  if (!state.getIn(basePath)) {
    return newState;
  }
  const validationErrorsPath = [
    errorsModelKey, constants.GLOBAL_CONDITIONS, constants.LIMIT_OCCURRENCES_PER_MEMBER_GLOBAL_CONDITIONS
  ];
  if (state.getIn(basePath.concat(constants.TYPE)) === constants.INFINITE) {
    return newState;
  } else if (state.getIn(basePath.concat(constants.TYPE)) === constants.LIMITED_PER_MEMBER) {
    if (!state.getIn(basePath.concat(constants.TOTAL_OCCURRENCES_CONDITION_ENABLED)) &&
      !state.getIn(basePath.concat(constants.BY_PERIOD_CONDITION_ENABLED))) {
      newState = newState.setIn(validationErrorsPath.concat(
          constants.LIMITED_PER_MEMBER_NONE_SELECTED_ERROR),
        'activity.validation.error.occurrences.limit.select.at.least.one.limit');
    }
    if (state.getIn(basePath.concat(constants.TOTAL_OCCURRENCES_CONDITION_ENABLED))) {
      const path = basePath.concat([constants.TOTAL_OCCURRENCES_CONDITION, constants.CONDITION_VALUE]);
      const totalOccurrencesLimitValue = state.getIn(path);
      if (isNaN(parseFloat(totalOccurrencesLimitValue)) || parseFloat(totalOccurrencesLimitValue) < 1) {
        newState = newState.setIn(validationErrorsPath.concat(
            [constants.TOTAL_OCCURRENCES_CONDITION, constants.CONDITION_VALUE]),
          'activity.validation.error.activity.action.limit.must.be.positive');
      }
    }
    if (state.getIn(basePath.concat(constants.BY_PERIOD_CONDITION_ENABLED))) {
      const conditionValuePath = basePath.concat([constants.BY_PERIOD_CONDITION, constants.CONDITION_VALUE]);
      const conditionCount = state.getIn(conditionValuePath.concat(constants.BY_PERIOD_CONDITION_COUNT));
      if (isNaN(parseFloat(conditionCount)) || parseFloat(conditionCount) < 1) {
        newState = newState.setIn(validationErrorsPath.concat(
            [constants.BY_PERIOD_CONDITION, constants.CONDITION_VALUE, constants.BY_PERIOD_CONDITION_COUNT]),
          'activity.validation.error.activity.action.limit.must.be.positive');
      }
      const isRollingPeriod = state.getIn(conditionValuePath.concat(constants.IS_ROLLING_PERIOD));
      if (isRollingPeriod) {
        const periodMultiplier = state.getIn(conditionValuePath.concat(constants.BY_PERIOD_PERIOD_MULTIPLIER));
        if (isNaN(parseFloat(periodMultiplier)) || parseFloat(periodMultiplier) < 1) {
          newState = newState.setIn(validationErrorsPath.concat(
              [constants.BY_PERIOD_CONDITION, constants.CONDITION_VALUE, constants.BY_PERIOD_PERIOD_MULTIPLIER]),
            'activity.validation.error.activity.action.limit.must.be.positive');
        }
      }
    }
  }
  return newState;
}

function validatePunchCardChildActivities(state, rootState) {
  let newState = state;
  const validationErrorsPath = [constants.VALIDATION_ERRORS, constants.PUNCH_CARD_CHILD_ACTIVITIES];
  const childActivitiesPath = [constants.DATA, constants.PUNCH_CARD_CHILD_ACTIVITIES];
  const punchRulePath = [...childActivitiesPath, constants.PUNCH_CARD_RULE_PUNCH];
  if (newState.getIn(punchRulePath)) {
    const actionsListErrors = validateActionList(
      newState.getIn([...punchRulePath, constants.ACTIONS, 0, constants.ACTIONS_LIST]), rootState);
    const bundleErrors = validateActionBundle(
      newState.getIn([...punchRulePath, constants.ACTIONS, 0, constants.BUNDLE]), rootState);
    if (Object.keys(actionsListErrors).length > 0) {
      newState = newState.setIn([...validationErrorsPath, constants.PUNCH_CARD_RULE_PUNCH], fromJS(actionsListErrors['0']));
    }
    if (Object.keys(bundleErrors).length > 0) {
      newState = newState.setIn([...validationErrorsPath, constants.PUNCH_CARD_RULE_PUNCH], fromJS(bundleErrors['0']));
    }
  }
  const communicationPath = [...childActivitiesPath, constants.PUNCH_CARD_COMMUNICATION_RULES];
  if (newState.getIn(communicationPath)) {
    newState.getIn(communicationPath).forEach((rule, index) => {
      if (!rule.get(constants.TRIGGER) && !rule.getIn([constants.ACTIONS, 0, constants.ACTIONS_LIST, 0, constants.ACTION_TYPE])) return;
      if (!rule.get(constants.TRIGGER)) {
        newState = newState
          .setIn(
            [...validationErrorsPath, constants.PUNCH_CARD_COMMUNICATION_RULES, index, constants.TRIGGER],
            'activity.validation.error.rule.trigger.required'
          );
      }
      const actionsListErrors = validateActionList(rule.getIn([constants.ACTIONS, 0, constants.ACTIONS_LIST]), rootState);
      if (Object.keys(actionsListErrors).length > 0) {
        newState = newState.setIn([...validationErrorsPath, constants.PUNCH_CARD_COMMUNICATION_RULES, index, constants.ACTIONS], fromJS(actionsListErrors['0']));
      }
    });
  }
  return newState;
}

function validateGiftDisplayConditions(state) {
  if (!state.getIn([constants.DATA, constants.SHOW_REDEEM_CODE_IN_APP]) && state.getIn([constants.DATA, constants.GIFT_INTERACTIVE_TYPE]) === constants.GIFT_INTERACTIVE_TYPE_SCRATCH) {
    // todo: temp remove to check if will work with react and non-react apps
    // return state.setIn([constants.VALIDATION_ERRORS, constants.DISPLAY_TAB, constants.SCRATCH_ERROR], 'activity.validation.error.gift.no.scratch.without.redeem.button');
  }

  return state;
}

export function validateActivitySchedule(state, rootState) {
  const scheduleParams = state.getIn([constants.DATA, constants.BIRTHDAY_OR_ANNIVERSARY_PARAMETERS]);
  if (!scheduleParams) {
    return state;
  }
  const businessTimeZone = appSelectors.getBusinessTimeZone(rootState);
  const scheduleUtcString = scheduleParams.get(constants.TRIGGER_SCHEDULE_VALUE);
  const scheduleUtc = moment.utc(scheduleUtcString, datesConstants.HH_MM_SS_FORMAT);
  const schedule = moment(scheduleUtc).tz(businessTimeZone);
  const hours = schedule.hours();
  if (hours < 1 || hours >= 23) {
    return state.setIn([constants.VALIDATION_ERRORS, constants.BIRTHDAY_OR_ANNIVERSARY_PARAMETERS, constants.TRIGGER_SCHEDULE_VALUE],
      fromJS('activity.validation.error.scheduled.time.not.supported'));
  }
  return state;
}

export function validateActivityScheduleAction(state) {
  const tempScheduleData = state.getIn([constants.TEMP_SCHEDULING_DATA]);
  const scheduleData = state.getIn([constants.DATA, constants.ONE_TIME_ACTION_SCHEDULING]);
  if (tempScheduleData && tempScheduleData.get(constants.TYPE) !== constants.SCHEDULE_IMMEDIATE && moment(tempScheduleData.get(constants.SCHEDULE_SCHEDULED)).isBefore(moment.utc())) {
    return state.setIn([constants.TEMP_VALIDATION_ERRORS, constants.TEMP_SCHEDULING_DATA, constants.SCHEDULE_SCHEDULED], fromJS('activity.validation.error.scheduled.time.passed'))
      .setIn([constants.VALIDATION_ERRORS, constants.ONE_TIME_ACTION_SCHEDULING], fromJS('activity.validation.error.scheduled.time.passed'));
  } else if (!tempScheduleData && scheduleData && scheduleData.get(constants.TYPE) !== constants.SCHEDULE_IMMEDIATE && moment(scheduleData.get(constants.SCHEDULE_SCHEDULED)).isBefore(moment.utc())) {
    return state.setIn([constants.TEMP_VALIDATION_ERRORS, constants.TEMP_SCHEDULING_DATA, constants.SCHEDULE_SCHEDULED], fromJS('activity.validation.error.scheduled.time.passed'))
      .setIn([constants.VALIDATION_ERRORS, constants.ONE_TIME_ACTION_SCHEDULING], fromJS('activity.validation.error.scheduled.time.passed'));
  }
  return state;
}

export function validateDraft(state) {
  const newState = resetValidations(state);
  return validateActivityMetadata(newState, true);
}

export function resetValidations(state) {
  const newState = state.delete(constants.VALIDATION_ERRORS);
  return newState.delete(constants.TEMP_VALIDATION_ERRORS);
}

function validatePromoCodeBulks(state) {
  if (state.getIn([constants.DATA, constants.TYPE]) !== constants.ACTIVITY_TYPE_PROMO_CODE) {
    return state;
  }
  const singleCodeBulks = state.getIn([constants.DATA, constants.PROMO_CODE_BULKS, constants.PROMO_CODE_MODE_SINGLE_CODE]);
  const generatedBulks = state.getIn([constants.DATA, constants.PROMO_CODE_BULKS]);

  const singleCodeBulksIsTaken = state.getIn([constants.DATA, constants.VALIDATION_PROMO_CODE_TAKEN_FLAG]);
  const fileUploadedcodeBulks = state.getIn([constants.DATA, constants.PROMO_CODE_BULKS, constants.PROMO_CODE_MODE_FILE_UPLOADED]);
  const singleCodeBulkErrors = {};
  const fileUploadedCodeBulkErrors = {};
  const generatedCodeBulkErrors = {};

  const bulksMode = state.getIn([constants.DATA, constants.PROMO_CODE_BULKS, constants.PROMO_CODE_MODE]);
  const bulksType = state.getIn([constants.DATA, constants.PROMO_CODE_BULKS_TYPE]);

  if (bulksMode === constants.PROMO_CODE_MODE_SINGLE_CODE) {
    singleCodeBulks.forEach((bulk, index) => {
      const codeValue = bulk.get(constants.PROMO_CODE_SINGLE_CODE_BULK_VALUE);
      if (singleCodeBulksIsTaken) {
        singleCodeBulkErrors[index.toString()] = 'activity.validation.error.promo.code.bulk.single.code.in.use';
      }
      const isNumeric = /^[0-9]+$/.test(codeValue.toString());
      if (!codeValue || (!isNumeric && codeValue.toString().length < 3)) {
        singleCodeBulkErrors[index.toString()] = 'activity.validation.error.promo.code.bulk.single.code.length.is.too.short';
      } else if (isNumeric && codeValue.toString().length < 5) {
        singleCodeBulkErrors[index.toString()] = 'activity.validation.error.promo.code.bulk.single.code.length.is.too.short_6';
      } else if (codeValue.toString().startsWith('0')) {
        singleCodeBulkErrors[index.toString()] = 'activity.validation.error.promo.code.bulk.single.code.should.not.start.with.zero';
      } else if (/^\d+$/.test(codeValue) && codeValue.toString().length > 9) {
        singleCodeBulkErrors[index.toString()] = 'activity.validation.error.promo.code.bulk.single.numeric.code.is.too.long';
      } else if (codeValue.toString().length > 100) {
        singleCodeBulkErrors[index.toString()] = 'activity.validation.error.promo.code.bulk.single.alphanumeric.code.is.too.long';
      }

    });
  } else if (bulksMode === constants.PROMO_CODE_MODE_FILE_UPLOADED) {
    fileUploadedcodeBulks.forEach((bulk, index) => {
      if (!bulk.get(constants.UPLOADED_FILE_NAME) || !bulk.get(constants.UPLOADED_BUCKET_NAME) || !bulk.get(constants.MEDIA_LINK)) {
        fileUploadedCodeBulkErrors[index.toString()] = 'activity.validation.error.promo.code.bulk.file.not.valid';
      }
    });
  }

  if (bulksType === constants.PROMO_CODE_MODE_GENERATED) {
    const availableCodesCount = state.getIn([constants.DATA, constants.FREE_PROMO_CODES_NUMBER]);
    const minTestCodesLength = state.getIn([constants.PROMO_CODE_TEST_BULK_MIN_SIZE]) || 0;
    let requestedCodesCount = 0;
    const isImmutable = generatedBulks.length === undefined;
    const validateGeneratedBulks = isImmutable ? generatedBulks.toJS() : generatedBulks;
    for (let i = 0; i < validateGeneratedBulks.length; i++) {

      if (validateGeneratedBulks[i].new && validateGeneratedBulks[i].status === 'active') {
        requestedCodesCount += parseInt(validateGeneratedBulks[i.toString()].size, 10);
      }

      generatedCodeBulkErrors[i.toString()] = {};
      if (validateGeneratedBulks[i].isTest) {

        if (!validateGeneratedBulks[i].new && validateGeneratedBulks[i].size < minTestCodesLength) {
          generatedCodeBulkErrors[i.toString()].size = 'activity.validation.error.promo.code.bulk.generated.less.test.codes';
        }

        if (validateGeneratedBulks[i].size > 50) {
          generatedCodeBulkErrors[i.toString()].size = 'activity.validation.error.promo.code.bulk.generated.more50.test.codes';
        }

        if ((validateGeneratedBulks[i].size <= 0 || !validateGeneratedBulks[i].size)) {
          generatedCodeBulkErrors[i.toString()].size = 'activity.validation.error.activity.action.value.required';
        }
      } else {
        if (!validateGeneratedBulks[i].name.length) {
          generatedCodeBulkErrors[i.toString()].name = 'activity.validation.error.activity.action.value.required';
        }

        if (validateGeneratedBulks[i].size <= 0 || !validateGeneratedBulks[i].size) {
          generatedCodeBulkErrors[i.toString()].size = 'activity.validation.error.activity.action.value.required';
        }
        if (validateGeneratedBulks[i].size > 10000) {
          generatedCodeBulkErrors[i.toString()].size = 'activity.validation.error.promo.code.bulk.generated.more10000.codes.in.bulk';
        }
      }

      if (Object.keys(generatedCodeBulkErrors[i.toString()]).length === 0) {
        delete generatedCodeBulkErrors[i.toString()];
      }
    }

    if (requestedCodesCount > availableCodesCount) {
      generatedCodeBulkErrors.availableCodesCount = 'activity.validation.error.promo.code.bulk.generated.requested.codes.count.not.available';
    }
  }

  if (Object.keys(singleCodeBulkErrors).length > 0) {
    return state.setIn([constants.VALIDATION_ERRORS, constants.PROMO_CODE_BULKS, constants.PROMO_CODE_MODE_SINGLE_CODE], fromJS(singleCodeBulkErrors));
  }
  if (Object.keys(fileUploadedCodeBulkErrors).length > 0) {
    return state.setIn([constants.VALIDATION_ERRORS, constants.PROMO_CODE_BULKS, constants.PROMO_CODE_MODE_FILE_UPLOADED], fromJS(fileUploadedCodeBulkErrors));
  }
  if (Object.keys(generatedCodeBulkErrors).length > 0) {
    return state.setIn([constants.VALIDATION_ERRORS, constants.PROMO_CODE_BULKS], fromJS(generatedCodeBulkErrors));
  }

  return state;
}

/**
 *
 * @param val
 * @returns {boolean}
 */
export function validatePhoneNumber(val) {
  return /^\d+$/.test(val);
}

/**
 *
 * @param val
 * @returns {boolean}
 */
export function validateEmailAddress(val) {
  return /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(val);
}
